.fade-enter {
  opacity: 0;
}
.fade-exit {
  opacity: 1;
}
.fade-enter-active {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
}
.fade-enter-active,
.fade-exit-active {
  transition: opacity 500ms;
}

.pkc-recommend {
  h1,
  h2,
  h3 {
    font-family: $font-fam-inter;
    @include media-breakpoint-down(sm) {
      font-size: sizeToRem(20) !important;
    }
  }

  p {
    @include media-breakpoint-down(sm) {
      font-size: sizeToRem(14) !important;
    }
  }

  // FIRST SECTION

  .oseTopSection-banner-content-img {
    justify-content: flex-end;
  }
  .oseTopSection-banner-content-desc {
    padding-top: 0;
    padding-bottom: sizeToRem(94);

    @include media-breakpoint-down(lg) {
      padding-top: 0;
      padding-bottom: sizeToRem(28);
    }
    @include media-breakpoint-down(md) {
      padding-bottom: sizeToRem(0);
    }
    @include media-breakpoint-down(sm) {
      padding-bottom: sizeToRem(35);
    }
  }

  .oseTopSection-banner-title {
    margin: sizeToRem(23.87) 0 sizeToRem(30);

    @include media-breakpoint-down(md) {
      margin: 0 0 sizeToRem(12);
    }
  }
}
.home-banner-pkc-eu {
  height: sizeToRem(94);
  width: 100%;
  position: absolute;
  right: 4%;
  z-index: 3;
  margin: 0 0 0 auto;
  padding: 0;
  @include media-breakpoint-up(sm) {
    height: sizeToRem(90);
  }
  @include media-breakpoint-down(sm) {
    position: relative;
  }
  @include media-breakpoint-down(md) {
    right: 0;
  }
}

.home-banner-logos-pkc {
  position: relative;
  background-color: white;
  width: 100%;
  height: 100%;
  border-bottom-left-radius: sizeToRem(70);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  margin: 0 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: sizeToRem(36);
  @include media-breakpoint-up(sm) {
    width: sizeToRem(480);
    &:before {
      content: '';
      position: absolute;
      right: sizeToRem(-749);
      background-color: white;
      height: 100%;
    }
  }
  @include media-breakpoint-down(sm) {
    border-bottom-left-radius: unset;
    box-shadow: unset;
    padding-left: 0;
  }
  @include media-breakpoint-up(md) {
    padding-left: 0;
    width: sizeToRem(500);
  }
  @include media-breakpoint-up(lg) {
    &:before {
      content: '';
      position: absolute;
      right: sizeToRem(-749);
      background-color: white;
      height: 100%;
      width: sizeToRem(750);
    }
  }
  @include media-breakpoint-up(xl) {
    width: sizeToRem(704);
  }
}

.pkc-home-logo {
  width: sizeToRem(100);
  height: sizeToRem(30);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  @include media-breakpoint-up(md) {
    width: sizeToRem(120);
    height: sizeToRem(40);
  }
  &.eu {
    background-image: url('../images/icons/ue-logo.svg');
    @include media-breakpoint-up(md) {
      width: sizeToRem(140);
    }
  }
  &.eu-funds {
    background-image: url('../images/icons/european_funds.svg');
    @include media-breakpoint-up(md) {
      width: sizeToRem(140);
      margin-left: sizeToRem(20);
    }
  }
  &.nask {
    background-image: url('../images/icons/nask-logo.svg');
    @include media-breakpoint-down(md) {
      margin-right: 1rem;
    }
    @include media-breakpoint-up(md) {
      width: sizeToRem(140);
    }
  }
  &.pl {
    background-image: url('../images/icons/RP-logo.svg');
    margin-right: sizeToRem(20);
    @include media-breakpoint-up(sm) {
    }
    @include media-breakpoint-down(md) {
      margin-right: 1rem;
    }
    @include media-breakpoint-up(xl) {
      margin-right: 0;
    }
  }
}

// SECOND SECTION

.pkcrecommend-section-second {
  overflow: hidden;
  padding: sizeToRem(100) 0;
  background-color: $pkc-secondary;
  font-family: $font-fam-inter;
  color: $pkc-primary;
  position: relative;

  @include media-breakpoint-up(lg) {
    min-height: sizeToRem(1445);
    padding-bottom: 0;
  }

  @include media-breakpoint-down(md) {
    padding: sizeToRem(100) 0 sizeToRem(30);
  }

  @include media-breakpoint-down(sm) {
    padding: sizeToRem(30) 0;
  }

  .heart-symbol,
  .m-symbol,
  .art-symbol,
  .at-symbol {
    position: absolute;
  }
  .art-symbol {
    right: 0;
    top: sizeToRem(229);
  }

  .at-symbol {
    left: 0;
    bottom: sizeToRem(92);
  }

  .heart-symbol {
    top: sizeToRem(46);
  }

  .m-symbol {
    bottom: sizeToRem(-13);
    right: 0;
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .pkcrecommend-section-two-content {
    text-align: center;
    color: $pkc-primary;
    position: relative;
    z-index: 1;

    h2 {
      margin-bottom: sizeToRem(12);
      font-size: sizeToRem(36);
      text-transform: uppercase;
      @include media-breakpoint-down(md) {
        font-size: sizeToRem(32);
        text-align: left;
      }
    }
    p {
      margin: 0;
      @include media-breakpoint-down(md) {
        text-align: left;
      }
      @include media-breakpoint-down(sm) {
        line-height: 150%;
      }
    }
  }

  .pkcrecommend-section-two-cards-row {
    display: flex;
    justify-content: center;
    padding-top: sizeToRem(50);

    @include media-breakpoint-down(md) {
      flex-direction: row;
      align-items: center;
      padding-top: sizeToRem(42);
    }
    @include media-breakpoint-down(sm) {
      padding: sizeToRem(30) 0;
      display: flex;
      flex-direction: column;
    }

    .single-card {
      width: sizeToRem(325);
      position: relative;
      display: flex;
      flex-direction: column;
      border-radius: sizeToRem(20);
      transition: opacity 0.2s ease-out, transform 3.8s cubic-bezier(0.19, 1, 0.22, 1), box-shadow 0.3s ease-in-out;

      @include media-breakpoint-down(sm) {
        margin-bottom: sizeToRem(10);
        margin-right: 0 !important;
      }

      &:hover {
        transform: scale(1.1);
        box-shadow: 0 sizeToRem(17.6) sizeToRem(20) rgba(0, 0, 0, 0.03), 0 6px 6px rgba(0, 0, 0, 0.23);
        @include media-breakpoint-down(sm) {
          box-shadow: unset;
        }
      }

      &:not(:last-child) {
        margin-right: sizeToRem(98);
        @include media-breakpoint-down(lg) {
          margin-right: sizeToRem(88);
        }
        @include media-breakpoint-down(md) {
          margin-right: sizeToRem(20);
        }
      }

      .image-section {
        height: 153.95px;
      }

      .content-section {
        padding: sizeToRem(24) sizeToRem(12);
        color: $pkc-primary;
        text-align: left;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: rgba(255, 255, 255, 0.8);
        border-bottom-left-radius: sizeToRem(20);
        border-bottom-right-radius: sizeToRem(20);
        min-height: sizeToRem(98);
        @include media-breakpoint-down(md) {
          min-height: sizeToRem(153);
          padding: sizeToRem(18) sizeToRem(12);
          flex-direction: column;
        }

        button {
          background: transparent;
          color: $pkc-primary;
          padding: sizeToRem(10) sizeToRem(16);
          border-radius: sizeToRem(5);
          border: sizeToRem(0.5) solid $pkc-primary;
          font-weight: 400;

          @include media-breakpoint-down(md) {
            margin-top: sizeToRem(17);
          }

          &:hover {
            background: $pkc-primary;
            color: white;
          }
        }

        .selected {
          span {
            padding-right: sizeToRem(12);
            color: $pkc-primary;
          }
        }

        h3 {
          font-size: sizeToRem(20);
          font-weight: 400;
          font-family: $font-fam-anton;
          margin: 0;
          max-width: sizeToRem(162);
        }

        p {
          margin: 0;
          font-size: sizeToRem(16);
          line-height: sizeToRem(24);
        }
      }

      .report {
        position: absolute;
        top: sizeToRem(12);
        left: sizeToRem(12);
      }
    }

    .single-card-selected {
      max-height: sizeToRem(398.95);
    }

    .single-card-content {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
    .single-card-content-mobile {
      button {
        width: 95%;
        color: $pkc-primary;
        font-size: sizeToRem(16);
        font-family: $font-fam-anton;
        font-weight: 400;
        background: transparent;
        border: sizeToRem(1) solid $pkc-primary;
        border-radius: sizeToRem(5);
        padding: sizeToRem(8) 0;

        &:hover {
          background: $pkc-primary;
          color: white;
        }
        .selected {
          background: $pkc-primary;
          color: white;
        }
      }
      @include media-breakpoint-up(md) {
        display: none;
      }
    }

    .selected-mobile {
      button {
        background: $pkc-primary;
        color: white;
      }
    }
  }
  .pkc-section-row-participation {
    padding-top: sizeToRem(90);
    @include media-breakpoint-down(md) {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      padding-top: sizeToRem(40);
    }
    @include media-breakpoint-down(sm) {
      padding-top: 0;
      flex-direction: column-reverse;
    }

    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;

      @include media-breakpoint-down(lg) {
        margin-left: 0;
      }
      a {
        @include media-breakpoint-down(sm) {
          max-width: sizeToRem(200);
        }
      }

      p {
        font-weight: 700;
        font-size: sizeToRem(20);
        max-width: sizeToRem(373);
        text-align: center;
      }

      img {
        cursor: pointer;
      }

      .course-btn {
        clip-path: polygon(16% 6%, 92% 18%, 90% 85%, 85% 91%, 6% 84%, 3% 16%);
        font-family: $font-fam-anton;
        text-transform: uppercase;
        color: $pkc-secondary;
        background-color: $pkc-primary;
        font-size: sizeToRem(16);
        padding: 0.5rem sizeToRem(32.5);

        &:hover {
          background: $pkc-secondary;
          color: $pkc-primary;
        }

        @include media-breakpoint-down(sm) {
          font-size: sizeToRem(20);
        }
      }
    }
  }

  .pkc-section-two-col {
    display: flex;
    flex-direction: column;
    @include media-breakpoint-down(sm) {
      align-items: center;
    }

    .step-container-desc {
      margin-bottom: sizeToRem(32);
      h2,
      h3 {
        font-weight: 700;
        font-size: 32px;
        margin: 0;
        line-height: 150%;
        @include media-breakpoint-down(md) {
          font-size: sizeToRem(20);
        }
      }

      p {
        margin-bottom: 0;
        font-weight: 900;
        font-size: sizeToRem(15);
        line-height: sizeToRem(31);
      }
    }

    .step-container {
      .step-row {
        display: flex;
        padding-left: sizeToRem(15);

        &:last-child {
          margin-bottom: 0;
        }

        .step {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-right: sizeToRem(36);
          @include media-breakpoint-down(sm) {
            padding-right: sizeToRem(20);
          }

          .step-circle {
            width: sizeToRem(35);
            height: sizeToRem(35);
            border-radius: 50%;
            background-color: white;
            border: sizeToRem(0.5) solid $pkc-primary;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: $font-fam-anton;
          }

          .step-line {
            height: 100%;
            width: sizeToRem(2);
            background-color: $pkc-primary;
          }
        }

        .step-description {
          display: flex;
          flex-direction: column;
          margin-top: sizeToRem(7);

          .last-item {
            text-decoration: underline;
          }

          h3 {
            margin-bottom: sizeToRem(16);
            font-size: sizeToRem(20);
            @include media-breakpoint-down(sm) {
              font-size: sizeToRem(16);
              margin-bottom: sizeToRem(8);
            }
          }

          p {
            margin-bottom: sizeToRem(32);
            font-size: sizeToRem(14);
            @include media-breakpoint-down(sm) {
              margin-bottom: sizeToRem(20);
            }
          }
        }
      }

      .step-row:last-child .step .step-line {
        display: none;
      }
    }
    .hidden {
      opacity: 0;
      transform: translateY(20px);
      visibility: hidden;
    }

    .visible {
      opacity: 1;
      transform: translateY(0);
      visibility: visible;
      transition: opacity 2s ease, transform 0.5s ease;
    }

    .pkcrecommend-section-two-content {
      h2 {
        margin-bottom: sizeToRem(30);
        @include media-breakpoint-down(md) {
          font-size: sizeToRem(36);
        }
      }
    }
  }
}

.pkc-modifier-filter-wrapper {
  padding: 24px 26px 27px 26px;
  border: 1px solid $pkc-primary;
}

.pkc-modifier-input-helper-text {
  min-height: 0;
}

// SECTION SECOND FORM STYLES

// SECTION THIRD STYLES
.pkc-section-third {
  font-family: $font-fam-inter;
  margin: sizeToRem(100) 0;
  @include media-breakpoint-down(md) {
    margin: sizeToRem(50) 0;
  }
  @include media-breakpoint-down(sm) {
    margin: sizeToRem(30) 0;
  }

  h1 {
    font-size: sizeToRem(36);
    text-align: center;
    @include media-breakpoint-down(md) {
      font-size: sizeToRem(20);
    }
  }

  .steps {
    font-size: 16px;
    margin: 0 auto;
    padding: 1rem;
    text-align: center;
    height: 6rem;
    margin: sizeToRem(60) 0;
    @include media-breakpoint-down(md) {
      margin: sizeToRem(30) 0;
    }

    &-list {
      font-size: 0.75rem;
      padding-left: 0;
      margin: 30px auto 0;
      display: flex;

      &-item {
        display: inline-block;
        margin: 0;
        color: #a1aeb7;
        position: relative;
        margin-top: 10px;
        text-align: center;
        flex: 0 1 100%;
        justify-content: center;

        span {
          position: absolute;
          top: -56px;
          width: 93%;
          height: 59px;
          left: 0;
          right: 0;

          &:before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 46%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: sizeToRem(21);
            font-family: $font-fam-ubuntu;
            font-weight: 700;
            color: white;
            width: 50px;
            height: 50px;
            line-height: 1px;
            border: 10px solid;
            border-radius: 50%;
            border-color: #c2d1d9;
            background-color: #c2d1d9;
            z-index: 1;

            @include media-breakpoint-down(lg) {
              left: 42%;
            }

            @include media-breakpoint-down(sm) {
              left: 33%;
            }
          }
          &:after {
            border-top: 4px solid #c2d1d9;
            content: '';
            right: 50%;
            position: absolute;
            top: 44%;
            transform: translateY(-50%);
            display: inline-block;
            width: 100%;
            font-size: sizeToRem(16);
          }
        }

        &:first-child {
          span:before {
            content: '1';
          }
        }

        &:nth-child(2) {
          span:before {
            content: '2';
          }
        }

        &:nth-child(3) {
          span:before {
            content: '3';
          }
        }
        .steps-list-item-number {
          font-size: sizeToRem(16);
          font-family: $font-fam-ubuntu;
          @include media-breakpoint-down(sm) {
            font-size: sizeToRem(14);
          }
        }

        &:first-child span:after {
          display: none;
        }

        &.completed {
          color: #505d68;
          span {
            &:after,
            &:before {
              border-color: #424b5a;
              background-color: #424b5a;
              font-family: $font-fam-ubuntu;
              font-size: sizeToRem(21);
            }
          }
        }
      }
    }
  }

  .input-helper-text {
    font-family: $font-fam-inter;
    min-height: 0;
    @include media-breakpoint-down(lg) {
      min-height: 1.75rem;
    }
  }

  .content-wrapper {
    .school-table-headers-text {
      font-family: $font-fam-inter;
    }

    .school-list-status-col {
      button {
        background-color: transparent;
        color: $pkc-primary;
        border-color: $pkc-primary;

        &:hover {
          background-color: $pkc-primary;
          color: white;
        }
      }
    }

    .input-field:focus,
    .js-focus-visible .input-field.focus-visible {
      @include outline($pkc-primary, 0.25);
      border-color: $pkc-primary;
      & ~ .input-group-text {
        border-right-color: $pkc-primary;
      }
      & ~ .input-label {
        color: $pkc-primary;
      }
    }
    .menergyrecommend-section-five-form-step-two-wrapper {
      border: sizeToRem(1) solid $pkc-primary;

      @include media-breakpoint-down(md) {
        padding: sizeToRem(15) 0;
      }

      button {
        background-color: transparent;
        color: $pkc-primary;
        border-color: $pkc-primary;
        font-size: sizeToRem(16);

        &:hover {
          background-color: $pkc-primary;
          color: white;
        }
      }

      .register-pkc-dropzone-wrapper {
        margin: 0 auto;
      }

      .register-pkc-dropzone-remove-file {
        &:hover {
          background-color: transparent;
        }
      }
      .form-control {
        height: 4rem;
      }

      .form-group:nth-child(4) {
        min-width: 97.5%;
        margin-top: sizeToRem(11);
      }
    }
  }

  .menergyrecommend-section-five-form-step-three-wrapper {
    background: rgba(194, 209, 217, 0.2);

    button {
      background-color: transparent;
      color: $pkc-primary;
      border-color: $pkc-primary;
      font-size: sizeToRem(16);

      &:hover {
        background-color: $pkc-primary;
        color: white;
      }
    }
  }
  .pkc-section-three-form-step-three-wrapper-list-row-first {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .form-group {
      min-width: sizeToRem(350);
    }
  }

  .school-list-filter-header-pkc {
    font-weight: 400;
    margin-bottom: sizeToRem(12);
    text-transform: none;
    a {
      text-decoration: underline;
    }

    @include media-breakpoint-down(md) {
      font-size: sizeToRem(14);
    }
  }

  .school-list-filter {
    padding: sizeToRem(12) 0 0 0;
  }

  .page-content-bottom-padding {
    padding-bottom: 0;
  }

  .menergyrecommend-section-five-form-step-two-wrapper {
    background: rgba(194, 209, 217, 0.2);
  }
}
.register-pkc-dropzone-file-title {
  margin-right: 1rem;
}

.register-pkc-dropzone {
  position: relative;
  height: sizeToRem(87);
  background: rgb(243, 246, 247);
  border: 1px dashed rgba(0, 0, 0, 0.25);
  border-radius: sizeToRem(10);

  cursor: pointer;
  margin-bottom: sizeToRem(32);

  @include media-breakpoint-up(md) {
    margin-bottom: 0;
  }

  &:hover {
    background-color: rgba(71, 118, 177, 0.1);
  }

  &.disabled {
    opacity: 0.5;
    cursor: unset;

    &:hover {
      background-color: #fff;
    }
  }

  &.error {
    border: 2px solid $form-error;
  }

  .register-pkc-dropzone-area {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .register-pkc-dropzone-icon {
    background-image: url('../images/icons/upload2.svg');
    background-repeat: no-repeat;
    width: 50px;
    height: 50px;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
  .register-pkc-dropzone-instruction {
    display: flex;
    flex-direction: column;
    padding-left: sizeToRem(26);

    @include media-breakpoint-down(sm) {
      padding-left: 0;
    }

    span:first-child {
      font-size: sizeToRem(13);
    }
    span:last-child {
      font-size: sizeToRem(12);
      color: rgba(0, 0, 0, 0.4);
    }
  }
}
.register-pkc-dropzone-btn {
  background: #fbfdfe;
  border: 1px solid rgba(15, 145, 210, 0.7) !important;
  border-radius: 5px;
  color: #0f91d2 !important;
  padding: sizeToRem(12) sizeToRem(16);
  margin-left: sizeToRem(56);

  @include media-breakpoint-down(md) {
    display: none;
  }

  &:hover {
    background: #0f91d2 !important;
    color: white !important;
  }
}

.register-pkc-dropzone-remove-file {
  margin-left: auto;
  button {
    background: transparent;
  }
}

.register-pkc-dropzone-error {
  color: $form-error;
  font-weight: bold;
  display: flex;
  align-items: center;

  img {
    width: sizeToRem(16);
    height: sizeToRem(16);
    margin-right: sizeToRem(16);
  }
}
.content-wrapper-pkc {
  @include media-breakpoint-up(xl) {
    padding: 0 !important;
  }
}
.menergyrecommend-section-five-form-step-two-wrapper-list .pkc-section {
  @include media-breakpoint-up(md) {
    justify-content: flex-start;
  }
  @include media-breakpoint-up(xl) {
    justify-content: space-around;
  }
  .input-label-modifier {
    font-size: sizeToRem(14);
  }
  .input-field:focus ~ .input-label,
  .input-label.has-value,
  textarea.input-field ~ .input-label.has-value {
    top: 0;
    left: sizeToRem(7);
    font-size: sizeToRem(12);
    padding: 0 sizeToRem(6);
    @include media-breakpoint-down(xs) {
      font-size: sizeToRem(10);
    }
  }

  .text-danger {
    font-size: sizeToRem(12);
    @include media-breakpoint-down(xs) {
      font-size: sizeToRem(10);
    }
  }
}

// SECTION FOURTH STYLES

.pkcrecommend-section-fourth {
  overflow: hidden;
  padding: sizeToRem(102) 0;
  background-color: $pkc-primary-complementary;
  font-family: $font-fam-inter;
  color: $pkc-primary;
  position: relative;

  @include media-breakpoint-down(md) {
    padding: sizeToRem(70) 0;
  }

  @include media-breakpoint-down(sm) {
    padding: sizeToRem(48) 0 sizeToRem(75);
  }
  .at-symbol,
  .hash-symbol,
  .small-piece-symbol {
    position: absolute;
  }

  .at-symbol {
    top: sizeToRem(35);
  }

  .hash-symbol {
    right: 0;
    top: sizeToRem(17);
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .small-piece-symbol {
    right: 0;
    bottom: 0;
  }

  .pkcrecommend-section-fourh-content {
    position: relative;
    z-index: 1;
    h2 {
      color: white;
      font-size: sizeToRem(36);
      text-transform: uppercase;
      text-align: center;
      margin: 0;
    }

    .info {
      text-align: center;
      color: white;
      line-height: 150%;
      margin-top: sizeToRem(16);
      margin-bottom: 0;
      @include media-breakpoint-up(lg) {
        width: 69%;
      }
    }

    .faq-wrapper {
      background-color: white;
      border-radius: sizeToRem(2);
      margin-top: sizeToRem(78);
      position: relative;
      z-index: 1;
      @include media-breakpoint-down(sm) {
        margin-top: sizeToRem(30);
      }

      div:nth-child(3) {
        .faq__singleElement {
          border-bottom: none;
        }
      }
    }
  }
  .faq {
    &__singleElement {
      display: flex;
      align-items: center;
      padding: sizeToRem(27);
      border-bottom: sizeToRem(1) solid $pkc-primary-complementary;
      border-radius: sizeToRem(2);
      cursor: pointer;

      &--content {
        width: 100%;
        display: flex;
        justify-content: space-between;

        span {
          @include media-breakpoint-down(sm) {
            max-width: sizeToRem(167);
          }
        }
      }
      &--extended {
        position: relative;
      }

      &--subtitleContainer {
        padding: sizeToRem(23) sizeToRem(33.9);
        background: $pkc-primary-accent;
        border-bottom: sizeToRem(1) solid $pkc-primary-complementary;
        border-radius: sizeToRem(2);

        p {
          max-width: sizeToRem(969);
          font-size: sizeToRem(14);
          margin: 0;
        }
      }

      &--empty {
        position: absolute;
        top: -2px;
        height: sizeToRem(2);
        width: 100%;
        background: #c2d1d9;
      }
    }
  }
}

// SECTION FIFTH STYLES

.pkcrecommend-section-fifth {
  background: $pkc-primary-accent;

  .oseTopSection-banner-content-img {
    @include media-breakpoint-down(sm) {
      display: none;
    }

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .pkcrecommend-section-fifth-content {
    padding-top: sizeToRem(130.5);
    padding-bottom: sizeToRem(129.5);
    color: $pkc-primary;
    font-family: $font-fam-inter;

    @include media-breakpoint-down(md) {
      padding-top: sizeToRem(100);
      padding-bottom: sizeToRem(100);
    }

    @include media-breakpoint-down(sm) {
      padding-top: sizeToRem(30);
      padding-bottom: sizeToRem(49);
    }

    h1 {
      font-size: sizeToRem(36);
      margin-bottom: sizeToRem(39);
    }

    .description {
      max-width: sizeToRem(492);
      line-height: sizeToRem(21);
    }
  }

  .pkcrecommend-section-fifth-contact-container {
    .contact-method {
      display: flex;
      align-items: center;

      &:first-child {
        padding-bottom: sizeToRem(26);
      }

      p {
        margin: 0;
        max-width: sizeToRem(500);
        font-weight: 700;
        font-size: sizeToRem(20);
        line-height: sizeToRem(31);
      }

      span {
        background: $pkc-secondary;
        width: sizeToRem(40);
        height: sizeToRem(40);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: sizeToRem(11);
          height: sizeToRem(11);
        }
      }

      a {
        padding-left: sizeToRem(16);
        color: $pkc-primary;
        font-weight: 700;
      }
    }
  }

  .contact-img-col {
    display: flex;
    align-items: flex-end;
  }
  .contact-img-wrapper {
    max-height: 25.1rem;
    max-width: 24.12rem;
    position: relative;
    @include media-breakpoint-down(sm) {
      display: none;
    }

    img {
      position: relative;
      z-index: 1;
    }
  }
}

.pkc-lottie {
  position: absolute;
  width: 100%;
  top: -28%;
  z-index: -1;

  @include media-breakpoint-up(lg) {
    top: -50%;
  }

  @include media-breakpoint-up(xl) {
    width: sizeToRem(1200);
  }
}

// SECTION SIX STYLES
.pkc-section-six {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.pkcrecommend-section-six {
  display: flex;
  flex-direction: column;

  margin: sizeToRem(100) 0;
  @include media-breakpoint-down(md) {
    margin: sizeToRem(50) 0;
  }
  @include media-breakpoint-down(sm) {
    margin: sizeToRem(30) 0;
  }

  &-content {
    color: $dark;
    box-shadow: 0px 1px 16px 1px rgb(0 0 0 / 22%);
    background-color: #efefef;
  }

  &-header {
    color: $dark;
    background-color: $pkc-secondary;
    display: flex;
    justify-content: center;

    &-title {
      font-size: sizeToRem(26);
      color: $white;
      padding: sizeToRem(24);
      margin: 0;
      font-family: $font-fam-inter;
      font-weight: 600;
    }
  }

  &-body {
    padding: sizeToRem(12);

    &-item {
      padding: sizeToRem(6);
      border-top: sizeToRem(1) solid #cccccc6b;
      color: #6b6767;

      button {
        color: #6b6767;
        margin: 0;
        padding: 0;
        border: none;
        outline: none;
      }

      button:hover {
        text-decoration: underline;
      }
    }

    &-item:last-child {
      border-bottom: sizeToRem(1) solid #cccccc6b;
    }

    &-head {
      font-weight: bold;
      border-bottom: 0;
    }
  }
}
