$phone-prefix-witdh: sizeToRem(45);
$input-padding: sizeToRem(12);

// REGULAR INPUT

.input-wrapper {
  position: relative;
  color: $dark;
  transition: all 0.015s linear;
}

.input-field,
.input-label {
  font-size: sizeToRem(18);
}

.input-field {
  box-shadow: none !important;
  border-radius: $border-radius;
  border-color: $dark;
}

.input-label {
  position: absolute;
  top: 50%;
  left: sizeToRem(14);
  transform: translateY(-50%);
  padding: 0;
  transition: 0.2s ease all;
  pointer-events: none;
}

// input prepend

.input-prepend {
  border-width: 0px;
  border-style: solid;
  border-color: $dark;
  border-radius: $border-radius;

  .input-field {
    border: 0;
    width: calc(100% - #{$phone-prefix-witdh});
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    order: 2;
  }
  .input-group-text {
    order: 1; // order of prepend is reversed to be able to style border-color on focus with sibling selector
    padding: sizeToRem(6) 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: $phone-prefix-witdh;
  }
  .input-label {
    left: calc(#{$phone-prefix-witdh} + #{$input-padding});
  }

  .input-field:focus ~ .input-label,
  .input-label.has-value {
    left: calc(#{$phone-prefix-witdh} + #{sizeToRem(5)});
  }
  &:focus-within {
    border-color: $form-active;
  }
}

// input append
.input-append {
  border-width: 0px;
  border-style: solid;
  border-color: $dark;
  border-radius: $border-radius;

  .form-control {
    padding: sizeToRem(8) sizeToRem(32) sizeToRem(8) sizeToRem(8);
  }
  .input-field {
    border: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    order: 1;
    @include media-breakpoint-down(sm) {
      max-width: 70%;
    }
  }
  &-img {
    order: 2;
    z-index: 10;
    margin-top: sizeToRem(8);
    margin-left: sizeToRem(-30);
  }
  .input-group-text {
    order: 2;
    padding: sizeToRem(6) 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: $phone-prefix-witdh;
  }
  .input-label {
    left: $input-padding;
  }

  .input-field:focus ~ .input-label,
  .input-label.has-value {
    left: sizeToRem(5);
  }
  &:focus-within {
    border-color: $form-active;
  }
}

// input invalid state

.is-invalid {
  &.input-field {
    background-image: none;
    padding-right: $input-padding !important;
    border-color: $form-error !important;
  }
  &.input-label {
    color: $form-error !important;
  }
  &.input-prepend {
    border-color: $form-error;
  }
  &.input-append {
    border-color: $form-error;
  }
}

// active state

.input-field:focus ~ .input-label,
.input-label.has-value,
textarea.input-field ~ .input-label.has-value {
  top: 0;
  left: sizeToRem(7);
  font-size: sizeToRem(12);
  padding: 0 sizeToRem(6);

  &:before {
    content: '';
    position: absolute;
    top: calc(50% - 3px);
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-color: #fff;
  }
}

// TEXTAREA

textarea.input-field {
  & ~ .input-label {
    top: sizeToRem(30);
  }
  &:focus {
    padding-bottom: sizeToRem(4);
  }
}

// SELECT

//.input-field,
//.select__control {
//  border-width: 1px;
//  border-style: solid;
//  border-color: $dark;
//  height: sizeToRem(51);
//}
//
//.input-field,
//.select__single-value {
//  color: $primary;
//}

.select__control:hover {
  border-color: $form-active;
  box-shadow: 0 0 0 1px lighten($form-active, 50%);
}

.select__value-container {
  padding: sizeToRem(2) $input-padding;
}

.select__menu {
  margin-top: sizeToRem(1);
  border-radius: sizeToRem(2);
  background: #fff;
  box-shadow: 0 sizeToRem(3) sizeToRem(6) rgba(0, 0, 0, 0.26);
}

.select__indicator-separator {
  display: none;
}

// CHECKBOX

.form-checkbox-wrapper {
  display: flex;
  position: relative;
}

.form-checkbox-label-wrapper,
.form-checkbox-helper-text {
  margin-left: sizeToRem(24);
}

.form-checkbox-label {
  border-width: sizeToRem(2);
  border-style: solid;
  border-color: transparent;
  margin-bottom: 0;
  display: inline;
  &.disabled {
    opacity: 0.5;
  }
  p {
    display: inline;
  }
}

.form-checkbox-input {
  position: absolute;
  top: 1rem;
  transform: translateY(-50%);
  width: 1rem;
  height: 1rem;
  margin: 0;
  &.is-invalid {
    box-shadow: 0 0 0 1px rgba($form-error, 1);
  }
}

// FOCUS

.input-field:focus,
.js-focus-visible .input-field.focus-visible {
  @include outline($form-active, 0.25);
  border-color: $form-active;
  & ~ .input-group-text {
    border-right-color: $form-active;
  }
  & ~ .input-label {
    color: $form-active;
  }
  &.is-invalid {
    @include outline($form-error, 0.25);
  }
  &:not([data-focus-visible-added]) {
    border-color: $dark !important;
    & ~ .input-label {
      color: inherit;
    }
  }
}
.input-prepend {
  .input-field:focus,
  .input-field.focus-visible {
    @include outline($form-active, 0.25, 3px);
  }
  &.is-invalid {
    @include outline($form-error, 0.25, 3px);
  }
}
.input-append {
  .input-field:focus,
  .input-field.focus-visible {
    @include outline($form-active, 0.25, 3px);
  }
  &.is-invalid {
    @include outline($form-error, 0.25, 3px);
  }
}

.form-checkbox-input:focus,
.js-focus-visible .form-checkbox-input.focus-visible {
  @include outline($form-active, 0.25);

  &.is-invalid {
    @include outline($form-error, 0.25);
  }
}

// FIELD ERRORS AND COUNTER

.formfield-helper-msg {
  font-size: 1rem;
  margin-bottom: 0;
}

.input-helper-text {
  position: relative;
  min-height: sizeToRem(28);
  .input-counter {
    font-size: sizeToRem(11);
    position: absolute;
    right: 0;
  }

  .formfield-helper-msg {
    &.withCounter {
      padding-right: 2.7rem;
    }
  }
}

// CAPTCHA

.form-captcha-container {
  margin-top: sizeToRem(30);
  margin-bottom: sizeToRem(36);

  @include media-breakpoint-down(sm) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

// CONSENT ACCORDION

.consent-checkbox-accordion {
  .card {
    border: none;
  }
  .card-header {
    border-bottom: none;
    padding: sizeToRem(32) sizeToRem(34) sizeToRem(4) sizeToRem(20);
    position: relative;
    margin-bottom: 0 !important;

    &:after {
      content: url('../images/icons/dropdown.svg');
      position: absolute;
      top: sizeToRem(38);
      right: sizeToRem(10);
      height: 20px;
      width: 20px;
    }
  }
  .card-body {
    font-size: sizeToRem(18);
    padding-top: sizeToRem(20);
  }
}

.consent-checkbox {
  label {
    font-size: sizeToRem(18);
    p {
      margin-bottom: 0;
    }
  }
}

// TOOLTIP

.form-tooltip-button {
  background-color: transparent;
  border: none;
  padding: 0;
  margin-left: sizeToRem(6);
  display: inline;
}

.form-tooltip-icon {
  width: sizeToRem(16);
  height: sizeToRem(16);
  margin-bottom: sizeToRem(2);
}

// .readings-checkbox {
//   transition: all 0.015s linear;
//   .custom-control-label::before {
//     border: 1px solid $color_form_ok;
//   }
//   .custom-control-input:checked ~ .custom-control-label::before {
//     border-color: $green;
//     background-color: $green;
//   }
//   .custom-control-input:focus ~ .custom-control-label::before {
//     border-color: $green;
//     box-shadow: 0 0 0 0.15rem rgba(0, 209, 181, 0.25);
//   }
//   .custom-control-input:checked ~ .custom-control-label::after {
//     background-image: url('../images/icons/tick.svg');
//     transform: scale(1.3, 1.3);
//   }
// }

// .reading-form-group {
//   position: relative;
// }

// .reading-phone-prepend {
//   position: absolute;
//   top: 0;
//   left: 0;
//   z-index: 1;
//   height: 100%;
//   .input-group-text {
//     color: #000;
//     background-color: transparent;
//     border: 0;
//     width: 51px;
//   }
// }

// .reading-phone-input {
//   width: 100%;
//   .readings-input-field {
//     padding-left: 51px;
//   }
// }

// .readings-radio-checkbox {
//   .custom-control-label::before {
//     height: 16px;
//     width: 16px;
//     border: 1px solid $color_form_ok;
//     border-radius: 0.25rem;
//   }

//   .custom-control-input:checked ~ .custom-control-label::before {
//     color: #fff;
//     border-color: $green;
//     background-color: $green;
//   }

//   .custom-control-input:checked ~ .custom-control-label::after {
//     background-image: url('../images/icons/tick.svg');
//     transform: scale(1.3, 1.3);
//   }

//   .custom-control-input:focus ~ .custom-control-label::before {
//     box-shadow: 0 0 0 0.15rem rgba(0, 209, 181, 0.25);
//   }
// }

// .readings-form-greyed {
//   color: $color_form_ok;
// }

// .form-captcha-error iframe {
//   border: 1px solid $form-error;
//   box-shadow: 0 0 0 1px lighten($form-error, 50%);
//   border-radius: 4px;
// }

// .readings-alert-success {
//   color: $primary;
//   background-color: rgba(3, 78, 151, 0.08);
//   border-color: $primary;
// }
// .readings-alert-error {
//   color: $form-error;
//   background-color: rgba(176, 0, 32, 0.08);
//   border-color: $form-error;
// }
