.home-banner-wrapper {
  overflow: hidden;
  position: relative;
  height: 100%; // fallback
  height: calc(calc(var(--vh, 1) * 100) - #{$nav-height-sm});
  min-height: sizeToRem(450);
  display: flex;
  flex-direction: column;
  @media (orientation: landscape) {
    min-height: sizeToRem(600);
  }
  @include media-breakpoint-up(md) {
    height: calc(calc(var(--vh, 1) * 100) - #{$social-bar-height} - #{$nav-height-sm});
  }
  @include media-breakpoint-up(lg) {
    height: calc(calc(var(--vh, 1) * 100) - #{$social-bar-height} - #{$nav-height-lg} - #{$arrow-indicator-height});
  }
  @include media-breakpoint-up(xl) {
    max-height: calc(#{sizeToRem(1080)} - #{$social-bar-height} - #{$nav-height-lg} - #{$arrow-indicator-height});
  }
}

@keyframes indicator-jump {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(0, sizeToRem(40), 0);
  }
}

.home-arrow-indicator {
  height: sizeToRem(82);
  width: 100%;
  display: none;
  @include media-breakpoint-up(lg) {
    display: flex;
  }
  svg {
    margin-top: sizeToRem(16);
    width: 100%;
    max-height: sizeToRem(14);
    animation: 1.2s ease-out indicator-jump infinite alternate;
  }
}

.home-banner-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: $secondary;
  background-image: url('../images/home-banner.jpg');
  background-position: 80% top;
  background-repeat: no-repeat;
  background-size: cover;
}

.home-banner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(67deg, $secondary 0%, $primary 100%);
  opacity: 0.8;
  z-index: 2;
  pointer-events: none;
}

@keyframes multiple-circles-pulse {
  0% {
    transform: scale(1);
  }
  66% {
    transform: scale(1.8);
  }
  100% {
    transform: scale(1);
  }
}

.home-pulsing-circles-wrapper {
  z-index: 1;
  position: absolute;
  right: sizeToRem(-45);
  bottom: sizeToRem(-45);
  animation: 3s ease-out multiple-circles-pulse infinite;
  .inner-circle {
    border: 1px solid white;
    border-radius: 100%;
    width: sizeToRem(451);
    height: sizeToRem(451);
    position: relative;
    &::before,
    &::after {
      @extend .inner-circle;
      content: '';
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      position: absolute;
    }
    &::before {
      bottom: sizeToRem(120);
      width: sizeToRem(171);
      height: sizeToRem(171);
    }
    &::after {
      width: sizeToRem(423);
      height: sizeToRem(423);
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
    }
  }
}

@keyframes single-circle-pulse {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.25);
  }
}

.home-pulsing-circle {
  z-index: 1;
  position: absolute;
  border: 4px solid white;
  border-radius: 100%;
  width: sizeToRem(258);
  height: sizeToRem(258);
  top: sizeToRem(90);
  left: sizeToRem(-100);
  animation: 0.8s ease-out single-circle-pulse infinite alternate;
}

.home-pulsing-circle,
.home-pulsing-circles-wrapper {
  display: none;
  @include media-breakpoint-up(lg) {
    display: block;
  }
}

.home-banner-heading {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  color: #fff;
  @include media-breakpoint-up(md) {
    padding: 0 sizeToRem(100);
  }
  @include media-breakpoint-up(xl) {
    padding: 0 sizeToRem(50);
  }
  h1 {
    z-index: 3;
    font-size: sizeToRem(32);
    font-weight: 700;
    margin-bottom: sizeToRem(16);
    max-width: sizeToRem(496);
    @media screen and (min-width: 375px) {
      font-size: sizeToRem(40);
    }
    @include media-breakpoint-up(md) {
      font-size: sizeToRem(56);
      margin-bottom: sizeToRem(40);
    }
    @include media-breakpoint-up(xl) {
      font-size: sizeToRem(96);
      max-width: sizeToRem(878);
    }
  }
  p {
    z-index: 3;
    font-size: sizeToRem(18);
    line-height: sizeToRem(28);
    margin-bottom: sizeToRem(44);
    max-width: sizeToRem(565);
    color: white;
    @include media-breakpoint-up(xl) {
      max-width: sizeToRem(680);
    }
  }
}

.home-banner-cta-wrapper {
  z-index: 3;
  display: flex;
  justify-content: center;
  @include media-breakpoint-up(sm) {
    justify-content: flex-start;
  }
}

.home-cta-primary {
  background-color: white !important;
  color: $secondary !important;
  border: 0;
  padding: sizeToRem(10) sizeToRem(44) !important;
  width: 100%;
  @include media-breakpoint-up(sm) {
    margin-right: sizeToRem(40);
    width: initial;
  }
  &:hover,
  &:focus {
    color: $primary !important;
    background-color: $light !important;
  }
  @include outline($muted_lighter, 1);
}

.home-cta-link {
  color: white !important;
  border-radius: $border-radius !important;
  @include outline($muted-lighter, 1);
}

.home-banner-eu {
  height: sizeToRem(64);
  width: 100%;
  z-index: 3;
  margin: 0 0 0 auto;
  padding: 0;
  @include media-breakpoint-up(sm) {
    height: sizeToRem(90);
  }
}

.home-banner-logos {
  position: relative;
  background-color: white;
  width: 100%;
  height: 100%;
  border-top-left-radius: sizeToRem(45);
  margin: 0 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: sizeToRem(36);
  @include media-breakpoint-up(sm) {
    width: sizeToRem(480);
    &:before {
      content: '';
      position: absolute;
      right: sizeToRem(-749);
      background-color: white;
      height: 100%;
      width: sizeToRem(750);
    }
  }
  @include media-breakpoint-up(md) {
    padding-left: sizeToRem(48);
    width: sizeToRem(550);
  }
  @include media-breakpoint-up(xl) {
    width: sizeToRem(480);
    margin: 0 auto;
  }
}

.home-logo {
  width: sizeToRem(100);
  height: sizeToRem(30);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
  @include media-breakpoint-up(md) {
    width: sizeToRem(120);
    height: sizeToRem(40);
  }
  &.eu {
    background-image: url('../images/icons/logo_eu2.svg');
    @include media-breakpoint-up(md) {
      width: sizeToRem(140);
    }
  }
  &.pl {
    background-image: url('../images/icons/logo_rp.svg');
    margin-right: sizeToRem(20);
    @include media-breakpoint-up(sm) {
      margin-right: sizeToRem(50);
    }
    @include media-breakpoint-up(xl) {
      margin-right: 0;
    }
  }
}

.home-section-header {
  span {
    text-transform: uppercase;
    color: $primary;
    font-family: $font-fam-headers;
    display: block;
    line-height: sizeToRem(22);
    margin-bottom: sizeToRem(20);
    @include media-breakpoint-up(md) {
      margin-bottom: sizeToRem(10);
    }
  }
  h2 {
    font-size: sizeToRem(36);
    line-height: sizeToRem(48);
    margin-bottom: sizeToRem(16);
    @include media-breakpoint-up(lg) {
      font-size: sizeToRem(56);
      line-height: sizeToRem(68);
      margin-bottom: sizeToRem(30);
    }
  }
  p {
    font-family: $font-fam-txt;
    max-width: sizeToRem(470);
    font-size: sizeToRem(18);
    margin-bottom: sizeToRem(20);
    line-height: sizeToRem(28);
    color: $dark;
    @include media-breakpoint-up(lg) {
      margin: 0 0 sizeToRem(30);
    }
  }
}

.home-section-btn-wrapper {
  display: flex;
  justify-content: flex-start;
}

.home-section-link {
  border-radius: $border-radius !important;
}

.home-internet-delivery-wrapper {
  overflow: hidden;
  margin-top: sizeToRem(68);
  @include media-breakpoint-up(lg) {
    margin-top: sizeToRem(126);
  }
}

.home-internet-delivery-row {
  justify-content: space-between;

  h2 {
    font-size: sizeToRem(36);
    line-height: sizeToRem(44);
    max-width: sizeToRem(384);
    @include media-breakpoint-up(xl) {
      font-size: sizeToRem(56);
      line-height: sizeToRem(68);
      max-width: initial;
    }
  }
}

.home-internet-delivery-img {
  width: 100%;
  height: auto;
  border-radius: 50%;
  display: block;
  margin: 0 auto sizeToRem(56);
  max-width: sizeToRem(240);
  @include media-breakpoint-up(sm) {
    margin: 0 0 sizeToRem(56);
  }
  @include media-breakpoint-up(lg) {
    max-width: sizeToRem(470);
    margin-bottom: 0;
  }
}

.home-internet-delivery-features-row {
  position: relative;
  margin-top: sizeToRem(80);
  @include media-breakpoint-up(lg) {
    margin-top: sizeToRem(180);
  }
}

.home-internet-delivery-feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: sizeToRem(370);
  margin: 0 auto sizeToRem(36);
  text-align: center;
  h3 {
    margin-top: sizeToRem(24);
    font-size: sizeToRem(36);
    line-height: sizeToRem(46);
    font-weight: 700;
  }
  p {
    margin-top: sizeToRem(24);
    font-size: sizeToRem(16);
    line-height: sizeToRem(24);
    max-width: sizeToRem(326);
  }
  svg {
    height: sizeToRem(100);
    &.hid-icon-0 {
      width: sizeToRem(122);
    }
    &.hid-icon-1 {
      width: sizeToRem(88);
    }
    &.hid-icon-2 {
      width: sizeToRem(103);
    }
  }
  @include media-breakpoint-up(lg) {
    margin: 0 auto;
    text-align: left;
    align-items: flex-start;
    h3 {
      font-size: sizeToRem(36);
    }
  }
  @include media-breakpoint-up(xl) {
    h3 {
      font-size: sizeToRem(56);
    }
  }
}

.home-internet-delivery-floating-text {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  text-align: center;
  pointer-events: none;

  span {
    font-family: $font-fam-headers;
    width: 100%;
    font-weight: 700;
    color: rgba($primary, 0.03);
    font-size: 23vw;
    @include media-breakpoint-up(xl) {
      font-size: sizeToRem(273);
    }
  }
}

.home-it-szkola-wrapper {
  background-color: $light;
  padding: sizeToRem(80) 0;
  position: relative;
  overflow: hidden;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    height: sizeToRem(30);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='408' height='30' viewBox='0 0 408 30' preserveAspectRatio='none meet' %3E%3Cpath data-name='Exclusion 1' d='M204.032 29.565h-1.755A719.921 719.921 0 010 .368V0h408v.368a719.9 719.9 0 01-203.968 29.197z' fill='%23fff' /%3E%3C/svg%3E");
    background-size: 100% 100%;
    width: 100%;
  }
  h2 {
    max-width: sizeToRem(532);
  }
  @include media-breakpoint-up(md) {
    margin-top: sizeToRem(20);
    padding: sizeToRem(120) 0 0;
    &::before {
      height: sizeToRem(108);
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1920' height='108' viewBox='0 0 1920 108' preserveAspectRatio='none meet' %3E%3Cpath d='M960 108.001c-40.966 0-82.458-.586-123.323-1.742-40.708-1.149-81.91-2.9-122.464-5.192a4336.882 4336.882 0 01-121.561-8.6 4318.283 4318.283 0 01-120.611-11.96 4305.973 4305.973 0 01-119.616-15.271A4300.617 4300.617 0 01233.85 46.697c-39.1-6.678-78.633-14-117.49-21.761A4272.646 4272.646 0 01.001.001h1920a4277.444 4277.444 0 01-116.358 24.935 4284.147 4284.147 0 01-117.49 21.761 4282.691 4282.691 0 01-118.576 18.539 4303.705 4303.705 0 01-119.617 15.271 4319.41 4319.41 0 01-120.611 11.96 4336.614 4336.614 0 01-121.561 8.6 4353.233 4353.233 0 01-122.464 5.192A4385.647 4385.647 0 01960 108.001z' fill='%23fff' /%3E%3C/svg%3E");
    }
  }
  @include media-breakpoint-up(xl) {
    padding: sizeToRem(240) 0 sizeToRem(160);
  }
}

.home-mleg-wrapper {
  background-color: $light;
  padding-bottom: sizeToRem(80);
  z-index: 2;
  position: relative;
}

.home-it-szkola-logo-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: sizeToRem(120);
}

.home-it-szkola-logo {
  max-width: sizeToRem(280);
  @include media-breakpoint-up(lg) {
    transform: translate3d(0, sizeToRem(400), 0);
  }
}

.home-it-szkola-presentation {
  display: flex;
  align-items: center;
  margin-top: sizeToRem(68);
  margin-bottom: sizeToRem(80);
  @include media-breakpoint-up(lg) {
    margin-top: sizeToRem(240);
  }
}

.home-mleg-advantages {
  display: flex;
  align-items: center;
  margin-top: sizeToRem(104);
  @include media-breakpoint-up(md) {
    margin-top: 0;
  }
  @include media-breakpoint-up(lg) {
    margin-top: sizeToRem(84);
  }
  @media (orientation: landscape) and (max-height: 767.98px) {
    margin-top: sizeToRem(104);
  }
}

.home-it-szkola-device,
.home-mleg-image {
  position: relative;
  display: flex;
  img {
    z-index: 2;
    position: relative;
    width: 100%;
    height: 100%;
    max-width: sizeToRem(300);
    @include media-breakpoint-up(lg) {
      max-width: initial;
    }
  }
}

.home-it-szkola-device {
  justify-content: flex-end;
  min-height: sizeToRem(189);

  img {
    object-fit: contain;
  }

  @include media-breakpoint-up(lg) {
    justify-content: flex-start;
    min-height: sizeToRem(411);
  }
}

.home-mleg-image {
  justify-content: center;
  margin-bottom: sizeToRem(68);
  img {
    border-radius: 100%;
  }
  @include media-breakpoint-up(md) {
    margin: 0;
    position: sticky;
    top: sizeToRem(72);
  }
  @include media-breakpoint-up(xl) {
    top: 0;
  }
}

.home-it-szkola-device-circle {
  position: absolute;
  top: sizeToRem(-110);
  right: sizeToRem(-80);
  width: sizeToRem(308);
  height: sizeToRem(308);
  border-radius: 100%;
  z-index: 1;
  background-color: $its-primary;
  background-image: linear-gradient(90deg, $its-primary 0%, $its-secondary 100%);
  @include media-breakpoint-up(lg) {
    top: sizeToRem(-160);
    right: sizeToRem(-160);
    width: sizeToRem(570);
    height: sizeToRem(570);
  }
}

.home-it-szkola-device-top-pointer,
.home-it-szkola-device-bottom-pointer,
.home-mleg-top-pointer,
.home-mleg-bottom-pointer {
  position: absolute;
  svg {
    width: 100%;
    max-width: sizeToRem(300);
    path {
      opacity: 0;
    }
  }
}

.home-mleg-top-pointer svg {
  max-width: initial;
}

.home-it-szkola-device-top-pointer {
  top: sizeToRem(-160);
  left: sizeToRem(-48);
}

.home-it-szkola-device-bottom-pointer {
  top: sizeToRem(-80);
  right: sizeToRem(-80);
  z-index: -1;
  transform: rotate(242deg);
}

.home-mleg-top-pointer {
  top: sizeToRem(-80);
  left: 40%;
  width: 55%;
  transform: rotate(15deg);
}

.home-mleg-bottom-pointer {
  bottom: sizeToRem(56);
  right: sizeToRem(160);
  transform: rotate(270deg);
}

.home-it-szkola-learn-container {
  position: relative;
}

.home-mleg-presentation-container {
  position: relative;
}

.home-it-szkola-learn-background-container,
.home-mleg-presentation-background-container {
  height: 100vh;
  width: 100%;
  clip-path: circle(100% at center center);
  z-index: 2;
  position: relative;
  @include media-breakpoint-up(md) {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.home-it-szkola-learn-background {
  background-image: url('../images/home-itszkola.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 15% 100%;
  height: 100%;
}

.home-mleg-presentation-background {
  background-image: url('../images/home-mleg.png');

  background-repeat: no-repeat;
  background-size: cover;
  background-position: 90% 100%;
  height: 100%;
}

.home-it-szkola-content,
.home-mleg-content {
  position: relative;
  display: flex;
  margin-top: sizeToRem(-40);
  z-index: 1;
  @include media-breakpoint-up(md) {
    height: 100vh;
    margin-top: 0;
    align-items: center;
    justify-content: flex-end;
  }
}

.home-mleg-presentation-header {
  max-width: initial;
  width: 100%;
  margin: 0;
  @include media-breakpoint-up(md) {
    max-width: sizeToRem(567);
    margin: 0 auto 0 0;
    p {
      max-width: sizeToRem(282);
    }
    h2 {
      max-width: sizeToRem(270);
    }
  }
  @include media-breakpoint-up(lg) {
    p,
    h2 {
      max-width: sizeToRem(500);
    }
  }
  @include media-breakpoint-up(xl) {
    p,
    h2 {
      max-width: initial;
    }
  }
}

.home-it-szkola-learn-header {
  width: 100%;
  @include media-breakpoint-up(md) {
    max-width: sizeToRem(314);
    width: initial;
  }
  @include media-breakpoint-up(lg) {
    max-width: initial;
  }
  @include media-breakpoint-up(xl) {
    margin-right: sizeToRem(56);
  }
}

.home-it-szkola-learn-header,
.home-mleg-presentation-header {
  p {
    margin-bottom: sizeToRem(30);
  }
  @include media-breakpoint-up(md) {
    transform: translate3d(0, 100vh, 0);
    opacity: 0;
  }
}

.home-it-szkola-learn-header .home-section-btn-wrapper {
  .secondary {
    width: 100%;
    margin: 0 auto;
    @include media-breakpoint-up(md) {
      width: initial;
      margin: 0;
    }
  }
}

.home-mleg-presentation-header .home-section-btn-wrapper {
  flex-direction: column;
  .secondary {
    order: 2;
    width: 100%;
    margin: 0 auto;
  }
  .link {
    order: 1;
    max-width: sizeToRem(192);
    margin-bottom: sizeToRem(32);
  }
  @include media-breakpoint-up(md) {
    flex-direction: row;
    .secondary {
      order: 1;
      width: sizeToRem(240);
      margin: 0 sizeToRem(40) 0 0;
    }
    .link {
      order: 2;
      margin-bottom: 0;
      max-width: initial;
    }
  }
}

.home-mleg-advantages-pros-wrapper {
  margin-top: sizeToRem(40);
  @include media-breakpoint-up(md) {
    margin-top: sizeToRem(64);
  }
}

.home-mleg-advantages-pros {
  display: flex;
  margin-bottom: sizeToRem(60);
  h3 {
    display: flex;
    align-items: center;
    font-size: sizeToRem(18);
    line-height: sizeToRem(30);
    margin: 0;
  }
  @include media-breakpoint-up(md) {
    flex-direction: column;
    margin: 0;
    &:nth-of-type(1),
    &:nth-of-type(2) {
      margin-bottom: sizeToRem(84);
    }
    h3 {
      line-height: size(28);
    }
  }
}

.home-mleg-advantages-pros-icon {
  width: sizeToRem(70);
  height: sizeToRem(70);
  min-width: sizeToRem(70);
  margin: 0 sizeToRem(10) 0 0;
  color: $mleg-primary;
  background-color: rgba($mleg-accent, 0.1);
  @include media-breakpoint-up(md) {
    margin: 0 0 sizeToRem(32);
  }
}

.home-myose-wrapper {
  position: relative;
  @include media-breakpoint-up(md) {
    background-image: url('../images/moje-ose-banner.jpg');
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
  }
}

.home-myose-overlay {
  @include media-breakpoint-up(md) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(67deg, $secondary 0%, $primary 100%);
    opacity: 0.8;
    z-index: 1;
    pointer-events: none;
  }
}

.home-myose-container {
  position: relative;
  z-index: 2;
  padding: sizeToRem(54) sizeToRem(25) sizeToRem(66);
  margin: 0;
  h2 {
    max-width: sizeToRem(365);
    margin-bottom: sizeToRem(30);
  }
  @include media-breakpoint-up(md) {
    margin: 0 auto;
    padding: sizeToRem(40) 0 sizeToRem(64) sizeToRem(64);
    h2,
    p {
      color: white;
    }
  }
  @include media-breakpoint-up(lg) {
    padding: sizeToRem(100) 0 sizeToRem(200) sizeToRem(80);
  }
}

.home-myose-container .primary {
  width: 100%;
  @include media-breakpoint-up(md) {
    width: initial;
    background-color: white;
    border-color: white;
    color: $primary;
    &:hover,
    &:focus {
      color: white;
    }
    @include outline($muted-lighter, 1);
  }
}

.home-myose-logo {
  width: sizeToRem(110);
  margin-bottom: sizeToRem(30);
}
