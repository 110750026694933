$loading-spinner-offset: 85;
$loading-spinner-duration: 1.4s;

.custom-loading-spinner {
  width: sizeToRem(32);
  height: sizeToRem(32);
  animation: custom-loading-spinner-rotation $loading-spinner-duration linear infinite;
}

.custom-loading-spinner-path {
  stroke-dasharray: $loading-spinner-offset;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: custom-loading-spinner-dash $loading-spinner-duration ease-in-out infinite;
}

@keyframes custom-loading-spinner-rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes custom-loading-spinner-dash {
  0% {
    stroke-dashoffset: $loading-spinner-offset;
    transform: rotate(90deg);
  }
  50% {
    stroke-dashoffset: $loading-spinner-offset / 4;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: $loading-spinner-offset;
    transform: rotate(450deg);
  }
}
