//SOCIAL MEDIA BAR

.social-media-bar {
  display: flex;
  position: relative;
  z-index: 1000;
  align-items: center;
  background-color: $light;
  height: $social-bar-height;
  @include media-breakpoint-down(lg) {
    background-color: transparent;
    padding: sizeToRem(15) 0;
  }

  .social-media-bar-container {
    display: flex;
    height: 100%;
    width: 100%;
  }

  .social-media-bar-link {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    color: $dark;
    font-size: sizeToRem(14);
    font-weight: 400;

    &:hover {
      text-decoration: none;
      color: $dark;
    }
  }

  .social-media-bar-vertical-line {
    border-left: 1px solid #dddddd;
    width: 2px;
    display: inline-block;
    height: auto;
    margin: 0 sizeToRem(18);
  }
}

.social-media-icons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: sizeToRem(18);
  a {
    width: sizeToRem(20);
    height: sizeToRem(16);
    cursor: pointer;
    display: flex;
  }
  .social-media-icon {
    width: 100%;
    height: 100%;
  }
  img {
    max-width: 100%;
  }
  @include media-breakpoint-down(lg) {
    justify-content: flex-start;
  }
}

.footer-social-media {
  background-color: transparent;
  z-index: 2 !important;
  .social-media-icons {
    @include media-breakpoint-down(lg) {
      padding: 0;
    }
    a {
      @include outline($muted-lighter, 1);
    }
  }
  .social-media-icon {
    fill: white;
  }
}
