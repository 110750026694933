$largeGalleryWidth: 1140px;
$middleGalleryWidth: 720px;
$largeNavigationWidth: 60px;
$smallNavigationWidth: 40px;

//BLUR ON (transition only on modal open)
body.modal-open {
  #root {
    transition: filter 0.15s linear;
    filter: blur(5px);
  }
}

.news-gallery-backdrop {
  &.show {
    opacity: 0.7;
  }
}

.news-gallery-modal {
  padding-right: 0 !important;

  .news-gallery-modal-container {
    display: flex;
    flex-direction: column;
    padding: 0;
    background: transparent;
  }

  .news-gallery-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: $largeNavigationWidth;
    @include media-breakpoint-down(sm) {
      height: $smallNavigationWidth;
    }
  }

  .news-image-counter,
  .news-gallery-modal-header-close {
    min-width: $largeNavigationWidth;
    text-align: center;
    @include media-breakpoint-down(sm) {
      min-width: $smallNavigationWidth;
    }
  }

  .news-image-counter {
    line-height: 2.125; //34px
  }

  .news-image-close-btn {
    height: 34px;
    width: 34px;
  }

  //BOOTSTRAP STYLES OVERRIDE

  .modal-content {
    background: transparent;
    color: #fff;
    border: 0;
    @include media-breakpoint-down(sm) {
      margin-bottom: $smallNavigationWidth;
    }
  }

  .modal-dialog {
    max-width: $largeGalleryWidth !important;
    @include media-breakpoint-down(lg) {
      width: $middleGalleryWidth;
      max-width: calc(100vw - 1rem) !important;
    }
    @include media-breakpoint-down(sm) {
      min-height: 100% !important;
      margin: 0 auto;
    }
  }

  .slide {
    width: calc(100% - #{$largeNavigationWidth}* 2);
    height: 100%;
    margin: 0 auto;
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  .carousel-image {
    width: 100%;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  //CAROUSEL NAVIGATIONS STYLES

  .carousel-control-next,
  .carousel-control-prev {
    width: $largeNavigationWidth;
    opacity: 1;
    &:hover {
      opacity: 0.5;
    }
    @include media-breakpoint-down(sm) {
      bottom: -32px;
      top: unset;
      width: $smallNavigationWidth;
    }
  }

  .carousel-control-next {
    right: -$largeNavigationWidth;
    @include media-breakpoint-down(sm) {
      right: 0 !important;
    }
  }

  .carousel-control-prev {
    left: -$largeNavigationWidth;
    @include media-breakpoint-down(sm) {
      left: 0 !important;
    }
  }

  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    background-image: url('../images/icons/white-arrow.svg');
    width: 34px;
    height: 34px;
    @include media-breakpoint-down(sm) {
      width: 24px;
      height: 24px;
    }
  }

  .carousel-control-next-icon {
    transform: rotate(180deg);
  }
}
