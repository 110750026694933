//SECOND SECTION

.menergyrecommend-section-two {
  padding: sizeToRem(150) 0;
  @include media-breakpoint-down(sm) {
    padding: sizeToRem(80) 0;
  }

  .menergyrecommend-section-two-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include media-breakpoint-down(sm) {
      align-items: center;
    }

    .menergyrecommend-section-two-image {
      img {
        max-width: 100%;
      }
    }
    .menergyrecommend-section-two-video-responsive {
    }

    .menergyrecommend-section-two-content {
      h2 {
        margin-bottom: sizeToRem(30);
        @include media-breakpoint-down(md) {
          font-size: sizeToRem(36);
        }
      }
    }
  }
}

//SECTION THREE

.menergyrecommend-section-three {
  overflow: hidden;
  padding: sizeToRem(30) 0;
  @include media-breakpoint-down(sm) {
    padding: sizeToRem(10) 0;
  }

  .menergyrecommend-section-three-content {
    h2 {
      margin-bottom: sizeToRem(80);
      @include media-breakpoint-down(md) {
        font-size: sizeToRem(36);
      }
    }
  }

  .menergyrecommend-section-three-content-row {
    display: flex;

    h2 {
      margin-bottom: sizeToRem(80);
      @include media-breakpoint-down(md) {
        font-size: sizeToRem(36);
      }
    }

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: center;
    }
  }
  .menergyrecommend-test {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include media-breakpoint-down(sm) {
      flex-direction: row;
    }
  }

  .menergyrecommend-section-three-element {
    text-align: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;

    &-icon {
      width: sizeToRem(80);
      height: sizeToRem(80);
      object-fit: contain;
    }

    &:last-child {
      .menergyrecommend-section-three-element-line {
        display: none;
      }
    }

    &-line {
      width: sizeToRem(75);
      margin: sizeToRem(40) sizeToRem(16) sizeToRem(0) sizeToRem(16);
      align-self: flex-start;
      @include media-breakpoint-down(lg) {
        width: sizeToRem(50);
      }
    }

    &-text {
      max-width: sizeToRem(200);
      font-size: sizeToRem(16);
      margin-top: sizeToRem(16);
      font-weight: 500;
      text-align: center;
      @include media-breakpoint-down(lg) {
        margin-left: sizeToRem(0);
        max-width: sizeToRem(130);
        font-size: sizeToRem(12);
      }
    }

    @include media-breakpoint-down(sm) {
      .menergyrecommend-section-three-element-line {
        display: none;
      }
    }
  }
}

//SECTION FOUR
.menergyrecommend-section-four {
  padding: sizeToRem(100) 0 sizeToRem(140);
  background-color: $menergy-secondary;
  @include media-breakpoint-down(md) {
    padding-bottom: sizeToRem(120);
  }

  .menergyrecommend-section-four-title {
    max-width: sizeToRem(600);
    margin-bottom: sizeToRem(30);
  }

  .menergyrecommend-section-four-description {
    max-width: sizeToRem(500);
    margin-bottom: sizeToRem(60);
  }
  .menergyrecommend-section-four-faq-description {
    max-width: sizeToRem(500);
    margin-bottom: sizeToRem(60);
    margin-top: sizeToRem(40);
  }

  .menergyrecommend-section-four-image {
    justify-content: center;

    img {
      max-width: sizeToRem(270);
    }

    @include media-breakpoint-down(md) {
      max-width: sizeToRem(220);
    }
  }

  .menergyrecommend-section-four-files-container {
    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
    }

    .menergyrecommend-section-four-file {
      &:not(:last-of-type) {
        margin-bottom: sizeToRem(24);
      }

      button {
        display: flex;
        color: $primary;
        background: none;
        border: none;
        text-align: left;
        font-weight: 700;
        font-size: sizeToRem(18);
      }

      svg {
        max-width: sizeToRem(20);
        margin-right: sizeToRem(18);
      }
    }
  }
}

.menergyrecommend-section-four-not-used {
  padding: sizeToRem(240) 0 sizeToRem(150);
  @include media-breakpoint-down(md) {
    padding: sizeToRem(150) 0 sizeToRem(130);
  }
  @include media-breakpoint-down(sm) {
    padding: sizeToRem(90) 0;
  }
}

.menergyrecommend-section-four-not-used-title {
  max-width: sizeToRem(473);
  margin-bottom: sizeToRem(30);
}

.menergyrecommend-section-four-not-used-description {
  max-width: sizeToRem(496);
  margin-bottom: sizeToRem(112);
}

.menergyrecommend-section-four-not-used-category-tile-wrapper {
  margin-bottom: sizeToRem(80);
}

.menergyrecommend-section-four-not-used-category-tile {
  position: relative;
  height: 100%;
  background: white;
  box-shadow: sizeToRem(150) sizeToRem(50) sizeToRem(99) #faf9fc;
  padding: sizeToRem(60) sizeToRem(20) sizeToRem(30);
  border-radius: sizeToRem(20);

  img {
    position: absolute;
    left: sizeToRem(15);
    top: sizeToRem(-30);
    width: sizeToRem(70);
    height: sizeToRem(70);
    margin-bottom: sizeToRem(16);
  }

  h4 {
    max-width: sizeToRem(226);
  }

  p {
    margin: 0;
    max-width: sizeToRem(380);
  }
}

//SECTION FIFTH
.menergyrecommend-section-five-final {
  margin-top: sizeToRem(20);
}
.menergyrecommend-section-five {
  overflow: hidden;
  padding: sizeToRem(100) 0 sizeToRem(100);

  @include media-breakpoint-down(md) {
    padding: sizeToRem(20) 0 0;
  }
  &-error {
    border: 1px solid;
    color: #d8000c;
    background-color: #f3dada;
    padding: sizeToRem(16);
  }

  &-success {
    display: flex;
    flex-direction: column;
    text-align: center;
    h2 {
      width: 100%;
    }
  }
  .menergyrecommend-section-five-content {
    position: relative;
    z-index: 2;

    h2 {
      margin-bottom: sizeToRem(30);
    }

    p {
      max-width: sizeToRem(470);
    }
  }

  .menergyrecommend-section-five-row {
    padding: sizeToRem(60) 0;
    max-width: sizeToRem(470);
  }

  .menergyrecommend-section-five-element {
    a {
      display: block;
      max-width: sizeToRem(205);
      min-width: sizeToRem(200);
      margin-bottom: sizeToRem(20);
      @include media-breakpoint-down(sm) {
        min-width: unset;
        width: 100%;
      }

      img {
        max-width: 100%;
        width: 100%;
        height: auto;
      }
    }
  }

  .menergyrecommend-section-five-image {
    position: relative;
    width: 100%;
    height: sizeToRem(800);
    @include media-breakpoint-down(md) {
      height: sizeToRem(600);
    }

    img {
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      height: sizeToRem(1300);
      @include media-breakpoint-down(md) {
        height: sizeToRem(800);
      }
    }
  }

  .menergyrecommend-section-five-cta {
    margin-top: sizeToRem(30);
  }
  .input-label {
    font-size: sizeToRem(14);
  }

  &-form-step-two-wrapper {
    padding: sizeToRem(30) sizeToRem(15);
    background: $light;
    border: 1px solid $secondary;
    border-radius: $border-radius;

    &-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      border-radius: sizeToRem(10);
      color: $secondary !important;

      &-button {
        width: 100%;
        display: flex;
        margin-right: sizeToRem(16);
        flex-direction: row-reverse;
      }

      .form-group {
        min-width: sizeToRem(350);
      }
      .form-control {
        border-radius: $border-radius;
        border: 1px solid $secondary;
        color: $secondary !important;
      }
      .input-helper-text {
        font-weight: 400;
        font-family: $font-fam-txt;
        text-transform: none;
      }
    }
  }
  &-form-step-three-wrapper {
    padding: sizeToRem(30) sizeToRem(15);
    background: $light;
    border: 1px solid $secondary;
    border-radius: $border-radius;
    .form-checkbox-label {
      font-size: sizeToRem(14);
    }
    &-present {
      .input-field {
        font-size: sizeToRem(14);
      }
    }
    &-list {
      border-radius: sizeToRem(10);
      color: $secondary !important;
      &-row-first {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .form-group {
          min-width: sizeToRem(250);
        }
      }
      &-row-second {
        display: flex;
        flex-wrap: wrap;
        .form-group {
          width: 100%;
        }
      }
      &-row-third {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .form-group {
          min-width: sizeToRem(500);
        }
      }

      &-button {
        width: 100%;
        display: flex;
        margin-right: sizeToRem(16);
        flex-direction: row-reverse;
      }

      .form-control {
        border-radius: $border-radius;
        border: 1px solid $secondary;
        color: $secondary !important;
      }
      .input-helper-text {
        font-weight: 400;
        font-family: $font-fam-txt;
        text-transform: none;
      }
    }
  }
}

//SECTION SIXTH

.menergyrecommend-section-six {
  padding: sizeToRem(40) 0 sizeToRem(140);
  @include media-breakpoint-down(md) {
    padding-bottom: sizeToRem(120);
  }

  &-title {
    max-width: sizeToRem(600);
    margin-bottom: sizeToRem(30);
  }

  &-description {
    max-width: sizeToRem(500);
    margin-bottom: sizeToRem(60);
  }

  &-image {
    justify-content: center;

    img {
      max-width: sizeToRem(270);
    }

    @include media-breakpoint-down(md) {
      max-width: sizeToRem(220);
    }
  }

  &-files-container {
    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
    }

    .menergyrecommend-section-four-file {
      &:not(:last-of-type) {
        margin-bottom: sizeToRem(24);
      }

      button {
        display: flex;
        color: $primary;
        background: none;
        border: none;
        text-align: left;
        font-weight: 700;
        font-size: sizeToRem(18);
      }

      svg {
        max-width: sizeToRem(20);
        margin-right: sizeToRem(18);
      }
    }
  }
}

//SECTION SEVEN

.menergyrecommend-section-seventh {
  padding: sizeToRem(100) 0 sizeToRem(140);
  background-color: $menergy-secondary;
  @include media-breakpoint-down(md) {
    padding-bottom: sizeToRem(120);
  }
  &-col {
    display: inline-block;
  }

  &-title {
    max-width: sizeToRem(600);
    margin-bottom: sizeToRem(30);
  }

  &-description {
    max-width: sizeToRem(500);
    margin-bottom: sizeToRem(60);
  }
  &-video-responsive {
    position: relative;
    top: 20%;
    z-index: 10;
  }

  .home-pulsing-circle {
    top: unset;
    bottom: 0;
    width: sizeToRem(100);
    height: sizeToRem(100);
    border-width: sizeToRem(2);
    @include media-breakpoint-down(md) {
      display: block !important;
      animation: none;
    }
    @include media-breakpoint-down(sm) {
      top: 50%;
      left: sizeToRem(-80);
      right: unset;
      transform: translateY(-50%);
      width: sizeToRem(160);
      height: sizeToRem(160);
    }
  }
  .home-pulsing-circles-wrapper {
    top: 0;
    bottom: unset;
    .inner-circle {
      border-width: sizeToRem(2);
      width: sizeToRem(160);
      height: sizeToRem(160);
      &:after {
        border-width: sizeToRem(2);
        width: sizeToRem(144);
        height: sizeToRem(144);
      }
      &:before {
        border-width: sizeToRem(2);
        top: sizeToRem(55);
        width: sizeToRem(50);
        height: sizeToRem(50);
      }
    }
  }
}
