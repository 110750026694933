.notFound-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  padding-bottom: 3.25rem;
  padding-top: 3.25rem;
  .notFound-title {
    font-size: 2.25rem;
    max-width: sizeToRem(640);
  }
  .notFound-img-wrapper {
    margin-bottom: 6.25rem;
    @include media-breakpoint-down(xs) {
      margin-bottom: 0;
    }
  }
  .notFound-img {
    display: flex;
    align-items: center;
    @include media-breakpoint-down(xs) {
      margin-bottom: 3.125rem;
      &.notFound-eye {
        order: 1;
      }
      &.notFound-404 {
        order: 2;
        img {
          max-width: 300px;
        }
      }
    }
  }
  .notFound-eye {
    justify-content: flex-start;
    img {
      width: 100%;
      max-width: 220px;
    }
  }
  .notFound-404 {
    img {
      width: 100%;
      max-width: 400px;
    }
  }
  .notFound-404,
  .notFound-eye {
    @include media-breakpoint-down(xs) {
      justify-content: center;
    }
  }
}
