.eufunding-patrons {
  margin-bottom: sizeToRem(50);
  .eufunding-col {
    display: flex;
    &:not(:last-child) {
      @include media-breakpoint-down(xs) {
        margin-bottom: sizeToRem(30);
      }
    }
    @include media-breakpoint-down(xs) {
      display: block;
    }
    img {
      width: sizeToRem(160);
      @include media-breakpoint-down(sm) {
        width: sizeToRem(120);
      }
      @include media-breakpoint-down(xs) {
        margin: 0 auto !important;
        width: sizeToRem(140);
      }
    }
    &.eufunfing-logo img {
      width: sizeToRem(120);
      @include media-breakpoint-down(sm) {
        width: sizeToRem(108);
      }
    }
  }
}

//ACCORDION

.eufunding-item {
  padding: sizeToRem(2);
  border: none;
  border-bottom: 1px solid $muted-lighter !important;
}

.eufunding-item-header {
  position: relative;
  border: none;
  text-align: left;
  font-weight: 500;
  background-color: white;
  padding: sizeToRem(24) sizeToRem(56) sizeToRem(24) sizeToRem(24);
  h2 {
    font-size: sizeToRem(36);
    margin: 0;
    @include media-breakpoint-down(md) {
      font-size: sizeToRem(18);
    }
    @include media-breakpoint-down(xs) {
      font-size: sizeToRem(16);
    }
  }
  .eufunding-item-arrow {
    position: absolute;
    width: sizeToRem(50);
    height: sizeToRem(50);
    top: 50%;
    right: sizeToRem(14);
    transform: translateY(-50%);
    transition: 0.3s ease;
    @include media-breakpoint-down(md) {
      width: sizeToRem(30);
      height: sizeToRem(30);
    }
    &.open {
      transform: translateY(-50%) rotate(180deg);
    }
  }
}
