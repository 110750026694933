.services-ose {
  background-color: $light;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-bottom: sizeToRem(100);
  min-width: sizeToRem(264);

  &-page-header-wrapper {
    margin: 0;
    h1 {
      padding: sizeToRem(80) 0 sizeToRem(127);
      font-size: sizeToRem(64);

      @include media-breakpoint-down(sm) {
        padding: sizeToRem(38) 0;
      }
      @include media-breakpoint-down(xs) {
        font-size: sizeToRem(32);
      }
    }
  }
}
.services-ose-tile {
  margin-bottom: sizeToRem(30);
}

.services-ose-tile-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: white;
  padding: sizeToRem(30);
  box-shadow: 0 0 sizeToRem(9) $primary-opacity;
  .services-ose-title {
    word-break: break-word;
    font-size: sizeToRem(26);
    margin-bottom: sizeToRem(60);
  }
  .services-ose-icon {
    width: sizeToRem(70);
    margin-bottom: sizeToRem(60);
  }
  .services-ose-desc {
    ul {
      margin: 0;
      padding-left: sizeToRem(18);
      li {
        margin-bottom: sizeToRem(20);
      }
      li:last-child {
        margin-bottom: 0;
      }
    }
  }
  .services-ose-btn-wrapper {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: sizeToRem(60);
    .services-ose-btn {
      text-align: left;
      padding-top: 0;
      padding-bottom: 0;
      &.with-icon {
        &.left {
          padding-left: sizeToRem(32) !important;
        }
      }
      .ose-button-icon {
        width: sizeToRem(22);
        height: sizeToRem(22);
      }
    }
  }
}

.services-ose-tab-container {
  align-items: stretch;
  display: flex;
  justify-content: center;

  &-row {
    background-color: $services-ose-background;
    border-radius: sizeToRem(8);
    padding: sizeToRem(2);
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 1fr 1fr;

    @include media-breakpoint-down(sm) {
      grid-auto-flow: row;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      width: 100%;
    }
  }
}

.services-ose-tab-button {
  background-color: transparent;
  border: 0;
  outline: 0;
  color: $dark;
  text-align: center;
  font-size: sizeToRem(18);
  font-style: normal;
  font-weight: 500;
  line-height: sizeToRem(20);
  letter-spacing: sizeToRem(-0.24);
  border-radius: sizeToRem(8);
  padding: sizeToRem(9) sizeToRem(20);
  box-shadow: none;
  transition: background-color 200ms;
  white-space: nowrap;
  font-family: $font-fam-headers;
  width: 100%;

  @include media-breakpoint-down(sm) {
    white-space: pre-wrap;
  }

  &--checked {
    background-color: $white;
    box-shadow: 0 sizeToRem(3) sizeToRem(1) 0 $services-ose-shadow-checked,
      0 sizeToRem(3) sizeToRem(8) 0 $services-ose-shadow-checked-secondary !important;

    &:focus {
      box-shadow: 0 sizeToRem(3) sizeToRem(1) 0 $services-ose-shadow-checked,
        0 sizeToRem(3) sizeToRem(8) 0 $services-ose-shadow-checked-secondary !important;
    }
  }
}

.services-ose-general-style {
  p,
  ul,
  ol,
  li,
  a {
    font-size: sizeToRem(18);
  }

  a {
    color: $services-ose-primary;
    line-height: 150%;
  }
}

.services-ose__safe-internet {
  padding-top: sizeToRem(62);
  font-family: $font-fam-inter;

  @extend .services-ose-general-style;
}

.services-ose__plus {
  padding-top: sizeToRem(36);
  &-description {
    font-family: $font-fam-inter;
    text-align: center;
    font-size: sizeToRem(16);
  }

  &-tab-icon {
    width: sizeToRem(36);
    margin-bottom: sizeToRem(24);
  }

  &-tab-title {
    font-family: $font-fam-inter;
    font-size: sizeToRem(20);
    color: #1d2636;
    font-weight: 500;
  }

  &-tabs {
    padding-top: sizeToRem(36);
  }

  &-tab-container {
    display: flex;
    gap: sizeToRem(24);
    align-items: stretch;
    flex-wrap: wrap;
    justify-content: center;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }

  &-tab-button {
    padding: sizeToRem(32) sizeToRem(24);
    border-radius: sizeToRem(10);
    background-color: $white;
    flex: 1;
    box-shadow: 0 sizeToRem(1) sizeToRem(4) 0 $services-ose-shadow-secondary !important;
    border: sizeToRem(1) solid transparent;
    transition: border 200ms;
    text-align: left;
    display: flex;
    flex-direction: column;
  }

  &-tab-button--checked {
    border: sizeToRem(1) solid $services-ose-tab-checked;
    box-shadow: 0 sizeToRem(1) sizeToRem(4) 0 $services-ose-shadow-secondary !important;
    &:focus {
      box-shadow: 0 sizeToRem(1) sizeToRem(4) 0 $services-ose-shadow-secondary !important;
    }
  }

  &-tab-content {
    padding-top: sizeToRem(36);
    font-family: $font-fam-inter;
    @extend .services-ose-general-style;
  }

  &-adnotation {
    font-family: $font-fam-inter;
    @extend .services-ose-general-style;
  }
}
