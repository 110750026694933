$modal-form-error-width: sizeToRem(1170);
$modal-form-error-text-width: sizeToRem(880);
$modal-form-error-icon-width: sizeToRem(450);
$modal-form-error-icon-height: sizeToRem(120);

.form-error-modal-backdrop {
  &.show {
    opacity: 0.7;
  }
}

.form-error-modal {
  padding-right: 0 !important;
  .form-error-modal-header {
    position: relative;
    padding: sizeToRem(20);
    @include media-breakpoint-up(md) {
      padding: sizeToRem(40) sizeToRem(70) sizeToRem(20);
    }

    .form-error-close-btn {
      position: absolute;
      top: 0;
      right: 0;
      margin: sizeToRem(12);
    }
    .form-error-modal-img {
      max-width: 400px;
      width: 100%;
    }
  }

  h4,
  p {
    font-family: $font-fam-headers;
    line-height: $line-height-headers;
    font-weight: bold;
  }

  h4 {
    font-size: sizeToRem(24);
    @include media-breakpoint-up(md) {
      font-size: sizeToRem(36);
    }
  }

  p {
    font-size: sizeToRem(16);
    @include media-breakpoint-up(md) {
      font-size: sizeToRem(18);
      max-width: $modal-form-error-text-width;
    }
  }

  //BOOTSTRAP STYLES OVERRIDE

  .modal-dialog {
    width: $modal-form-error-width;
    max-width: calc(100vw - #{sizeToRem(16)});
  }

  .modal-content {
    border: 0;
  }

  .modal-body {
    padding: 0 sizeToRem(20) sizeToRem(20);
    @include media-breakpoint-up(md) {
      padding: 0 sizeToRem(70) sizeToRem(40);
    }
  }
}
