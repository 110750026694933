/* colors */
$muted: #a0a0a0;
$muted-lighter: #d0d0d0;
$primary: #2737f5;
$primary-opacity: rgba($primary, 0.16);
$primary-darker: #0000cb;
$secondary: #11053b;
$gray: #c3c1c1;
$dark: #1d2636;
$dark-opacity: rgba($dark, 0.43);
$light: #f8f8ff;
$light-secondary: #f7f7f7;
$light-hover: #b7b7e5;
$danger: #ff0000;

$mleg-light: #f3f9f9;
$mleg-primary: #105f4f;
$mleg-secondary: #328272;
$mleg-dark: #053e34;
$mleg-accent: #00d1b5;
$mleg-accent-opacity: rgba(#00d1b5, 0.1);

$mleg-student-light: #ecf9ff;
$mleg-student-primary: #0a16b1;
$mleg-student-secondary: #49bff7;

$its-primary: #f9ad41;
$its-secondary: #ff4f5f;

$mochrona-primary: #2737f5;
$mochrona-secondary: #ac23be;

$menergy-primary: #41c6ce;
$menergy-header: #035e63;
$menergy-secondary: #fdcf7c;

$pkc-secondary: #ffd854;
$pkc-primary: #20354f;
$pkc-primary-accent: #e7efff;
$pkc-primary-complementary: #365a87;
$pkc-accent-opacity: rgba(#20354f, 0.1);

$services-ose-primary: #2737f5;
$services-ose-tab-checked: #4700a8;
$services-ose-background: #7676801e;
$services-ose-shadow-secondary: #19213d14;
$services-ose-shadow-checked: #0000000a;
$services-ose-shadow-checked-secondary: #0000001e;

$border-primary: $muted-lighter;
$border-secondary: $primary-opacity;

$form-active: $primary-darker;
$form-error: $danger;

$text-muted: $muted; //overwrite custom bootstrap class

/* spacing */
$page-max-width: 1920px;
$content-max-width: 1200px;

$page-padding-sm: 15px;
$page-padding-md: 30px;
$page-padding-lg: 15px;

$border-radius: 0.3125rem; //5px
$border-radius-img: 1rem; //16px

/* fonts */
$font-fam-headers: 'Montserrat', sans-serif;
$font-fam-txt: 'Roboto', sans-serif;
$font-fam-anton: 'Anton', sans-serif;
$font-fam-inter: 'Inter', sans-serif;
$font-fam-ubuntu: 'Ubuntu', sans-serif;

$line-height: 1.75;
$line-height-headers: 1.25;

/* nav */
$nav-height-lg: sizeToRem(102);
$nav-height-sm: sizeToRem(72);
$navigation-dropdown-height: sizeToRem(160);
$navigation-dropdown-arrows-width: sizeToRem(50);
$navigation-dropdown-transition-time: 0.4s;

/* social media bar */
$social-bar-height: sizeToRem(36);
$mobile-social-bar-height: sizeToRem(51);

/* home arrow indicator */
$arrow-indicator-height: sizeToRem(82);

/* animation */
$transition-timing: 0.3s; // todo - zobaczyć jaki timing jest ok

/* select input */
$select-button-color: rgba($primary, 0.4);
$select-button-text-color: rgba(#000, 0.8);
$select-button-width: 300px;

/* WCAG - outline */
$default-outline-size: 2px;
