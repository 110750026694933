$newsitem-content-width: 970;

.newsitem-headline {
  background-color: rgba($secondary, 0.56);
  color: #fff;
  padding: 3rem 0;
  margin-bottom: sizeToRem(100);
  position: relative;
  min-height: sizeToRem(830);
  display: flex;
  justify-content: center;
  align-items: center;
  @include media-breakpoint-down(lg) {
    min-height: sizeToRem(576);
  }
  @include media-breakpoint-down(sm) {
    min-height: sizeToRem(355);
    margin-bottom: sizeToRem(30);
  }
  .newsitem-headline-bg {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .newsitem-headline-title {
    max-width: sizeToRem($newsitem-content-width);
    h1 {
      @include media-breakpoint-down(sm) {
        font-size: sizeToRem(36);
      }
    }
  }
}

.newsitem-excerpt {
  color: $primary;
  font-size: 1.125rem;
  font-family: $font-fam-headers;
  margin-bottom: sizeToRem(60);
  @include media-breakpoint-down(sm) {
    margin-bottom: sizeToRem(30);
  }
}

.newsitem-content-wrapper {
  max-width: sizeToRem($newsitem-content-width);
  margin: 0 auto;
}

.newsitem-content {
  margin-bottom: sizeToRem(80);
  @include media-breakpoint-down(sm) {
    margin-bottom: sizeToRem(30);
  }
  a {
    color: $primary;
  }
  img {
    border-radius: $border-radius-img;
    display: block;
    max-width: 100%;
    height: auto;
  }
  h2 {
    font-size: sizeToRem(16);
  }
}

.newsitem-gallery {
  margin-bottom: sizeToRem(40);
}

.newsitem-gallery-frame {
  padding: 0.25rem;
  border: 1px solid #cbcbcb;
  border-radius: 0.25rem;
}
.newsitem-gallery-photo {
  background: transparent;
  border: 0;
  display: block;
  @include lazyImg(66.43%);
  @include media-breakpoint-up(sm) {
    @include lazyImg(100%);
  }
}

.newsitem-gallery-thumbnail {
  margin-bottom: 1rem;
  @include media-breakpoint-up(sm) {
    margin-bottom: 2rem;
  }
  @include media-breakpoint-up(lg) {
    flex: 0 0 20%;
    max-width: 20%;
  }
}

.newsitem-meta {
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  @include media-breakpoint-up(sm) {
    margin-bottom: sizeToRem(80);
  }
  .newsitem-share {
    border: 0;
    background-color: #fff;
    margin-right: 2rem;
    line-height: 1;
    padding: 0;
    img {
      height: 18px;
    }
  }
}

.newsitem-pagination {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid $border-secondary;
  padding-top: sizeToRem(40);
  padding-bottom: sizeToRem(120);
  @include media-breakpoint-down(sm) {
    padding-top: 1rem;
    padding-bottom: sizeToRem(80);
  }
}

.newsitem-pagination-link {
  a {
    color: $primary;
    text-decoration: none;
    display: flex;
    align-items: center;
    @media (max-width: 359.98px) {
      flex-direction: column;
    }
  }
  &.prev {
    img {
      transform: rotate(-90deg);
      margin-right: 0.75rem;
    }
  }
  &.next {
    img {
      margin-left: 0.75rem;
      transform: rotate(90deg);
    }
    @media (max-width: 359.98px) {
      span {
        order: 2;
      }
      img {
        order: 1;
      }
    }
  }
}

.newstitem-latest-header {
  margin-bottom: sizeToRem(80);
  @include media-breakpoint-down(sm) {
    margin-bottom: sizeToRem(60);
  }
}

.newstitem-latest-article {
  @extend .tile-link-hover;
  height: 100%;
  width: 100%;
}
.newsitem-latest-img {
  @include lazyImg(47.37%);
  img {
    border-radius: $border-radius-img;
  }
}

.newstitem-latest-date,
.newstitem-latest-desc {
  padding-left: 1rem;
  padding-right: 1rem;
  @include media-breakpoint-down(sm) {
    padding-left: 0;
    padding-right: 0;
  }
}

.newstitem-latest-date {
  margin: 2rem 0;
  @include media-breakpoint-down(sm) {
    margin: 1rem 0;
  }
}

.newstitem-latest-desc {
  padding-bottom: 1rem;
  @include media-breakpoint-down(sm) {
    padding-bottom: 3rem;
  }
  h4 {
    font-family: $font-fam-headers;
    font-weight: 700;
    min-height: 3rem;
    margin-bottom: 1rem;
    @include media-breakpoint-down(sm) {
      min-height: auto;
    }
  }
}
