.ose-button {
  font-size: sizeToRem(18);
  line-height: 1.375; //22px
  font-weight: 500;
  border-width: 1px;
  border-style: solid;
  border-radius: $border-radius;
  letter-spacing: sizeToRem(0.55);
  padding: sizeToRem(10) sizeToRem(24);
  text-align: center;

  &.primary {
    background-color: $primary;
    border-color: $primary;
    color: #fff;
    &:hover,
    &:focus {
      background-color: $primary-darker;
      border-color: $primary-darker;
    }
  }

  &.secondary {
    background-color: $secondary;
    border-color: $secondary;
    color: #fff;
    &:hover,
    &:focus {
      background-color: $primary;
      border-color: $primary;
    }
  }

  &.outlined {
    background-color: #fff;
    color: $secondary;
    border-color: $secondary;
    // no special styles on hover
  }

  &.link {
    color: $primary;
    background-color: transparent;
    border-color: transparent;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;

    &:hover,
    &:focus {
      text-decoration: underline;
    }

    &.with-icon {
      &.right {
        padding-right: 1.125rem;
      }
      &.left {
        padding-left: 1.125rem;
      }
      position: relative;
    }
  }
  &.disabled {
    cursor: not-allowed;
    background-color: $muted !important;
    border-color: $muted !important;
    &:focus,
    &:hover {
      background-color: $muted !important;
      border-color: $muted !important;
    }
  }

  .ose-button-icon {
    position: absolute;
    width: sizeToRem(8);
    top: 50%;
    transform: translateY(-50%);
    &.left {
      left: 0;
    }
    &.right {
      right: 0;
    }
  }
}

.custom-select-button {
  position: relative;
  width: 100%;
  height: sizeToRem(44);
  margin-bottom: 0;
  border-radius: $border-radius;
  border: none;
  background: none;
  padding: 0;
  @include outline($primary, 0.25);
  @include media-breakpoint-up(sm) {
    width: $select-button-width;
  }
  &:hover {
    label {
      color: $primary;
    }
    .select-button__control {
      transition: 0.2s;
      border-color: $primary !important;
      color: $primary !important;
    }
    .custom-select-button__dropdown-indicator {
      color: $primary !important;
    }
  }
  &.open {
    label {
      color: $primary !important;
    }
    .select-button__control {
      transition: 0.2s;
      border-color: $primary !important;
      color: $primary !important;
    }
    .custom-select-button__dropdown-indicator {
      transform: rotate(180deg);
    }
  }
  label {
    transition: color 0.2s;
    position: absolute;
    z-index: 1;
    padding: 0 sizeToRem(4);
    margin: 0;
    top: sizeToRem(-10);
    left: sizeToRem(13);
    color: $select-button-color;
    font-size: sizeToRem(12);
    font-family: $font-fam-headers;
    font-weight: 500;

    &:before {
      content: '';
      position: absolute;
      top: 49%;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      background-color: #fff;
    }
  }
}

.custom-select-container {
  border: none;
  border-radius: $border-radius;
  height: 100%;
  padding: 0;
  margin: 0;
  &:focus {
    border-color: $primary !important;
    color: $primary !important;
  }
}

.custom-select-button__control {
  border-radius: $border-radius !important;
  border: 1px solid $select-button-color !important;
  height: 100%;
  padding-left: sizeToRem(12);
  &:hover {
    cursor: pointer;
  }
  &--is-focused {
    box-shadow: none !important;
  }
}

.custom-select-button__value,
.custom-select-button__value-container,
.custom-select-button__single-value {
  padding: 0 !important;
  margin: 0 !important;
}

.custom-select-button__placeholder {
  color: $select-button-text-color !important;
  margin: 0 !important;
}

.custom-select-button__dropdown-indicator {
  color: $dark !important;
  transition: transform 0.2s, color 0.15s !important;
}

.custom-select-button__indicator-separator {
  display: none;
}

.custom-select-button__menu {
  border-radius: $border-radius !important;
  box-shadow: 0 3px 6px rgba($primary, 0.15) !important;
  margin: 0 0 0 0 !important;
}

.custom-select-button__menu-list {
  padding: 0 !important;
}

.custom-select-button__option {
  transition: background-color 0.2s;
  border-radius: $border-radius !important;
  background: none !important;
  color: #000 !important;
  text-align: left;
  &:hover {
    cursor: pointer;
    background-color: $light !important;
  }
  &--is-focused {
    border-radius: sizeToRem(5) !important;
    background-color: $light !important;
  }
  &--is-selected {
    display: none !important;
    border-color: $primary !important;
  }
}

//CUSTOM CLOSE BTN

.custom-close-btn {
  background: transparent;
  border: 0;
  &.disabled {
    cursor: not-allowed;
  }
}

.ose-close {
  &.secondary {
    stroke: $secondary;
  }
  &.light {
    stroke: #fff;
  }
  &.error {
    stroke: $form-error;
  }
  &.disabled {
    stroke: $muted;
  }
}
