body {
  min-height: 100vh;
  font-size: 1rem; //16px
  line-height: $line-height;
  color: $dark;
  //just to disable retarded bootstrap padding
  padding-right: 0 !important;
  font-family: $font-fam-txt;
}

div#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: $page-max-width;
  margin: 0 auto;
  position: relative;
}

.page-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-top: $nav-height-sm;
  min-height: calc(100vh - #{$nav-height-sm});
  @include media-breakpoint-up(xl) {
    margin-top: 0;
    min-height: initial;
  }
}

/* fonts & text styling */

h1 {
  font-size: 2.25rem; //36px
  @include media-breakpoint-up(md) {
    font-size: 5rem; //80px
  }
}
h2 {
  font-size: 2.25rem; //36px
  @include media-breakpoint-up(md) {
    font-size: 3.5rem; //56px
  }
}
h3 {
  font-size: 2.25rem; //36px
}
h4 {
  font-size: 1.125rem; //18px Roboto
}
h5 {
  font-size: 1rem;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}
h1,
h2,
h3 {
  font-family: $font-fam-headers;
  line-height: $line-height-headers; // do potwierdzenia z Karolem i Olą
}

h4,
h5,
h6 {
  line-height: 1.5; // do potwierdzenia z Karolem i Olą
}

strong {
  font-weight: 700;
}

.text-bigger {
  font-size: 1.125rem; //18px Roboto
}

.text-opaque {
  font-size: 1rem;
  font-weight: 700;
  color: $dark-opacity;
}

/* links & focus */

a,
button,
.focusable {
  @include outline($primary, 0.6);
  &.light-focus {
    @include outline(#fff, 0.5);
  }
}

a {
  color: $dark;
  &:hover,
  &:focus {
    color: $primary;
  }
}

.ose-link {
  font-weight: bold;
  text-decoration: none;
  color: $primary;
  &:focus,
  &:hover {
    color: $primary;
    text-decoration: underline;
  }
}

/* wrappers */

.content-wrapper-sm0 {
  @include content-wrapper-base(0);
}

.content-wrapper {
  @include content-wrapper-base($page-padding-sm);
}

/* margin classes */

.mb-20 {
  margin-bottom: sizeToRem(20) !important;
}

.mt-30 {
  margin-top: sizeToRem(30) !important;
}

.mb-30 {
  margin-bottom: sizeToRem(30) !important;
}

.mb-40 {
  margin-bottom: 2.5rem !important;
}

.mb-50 {
  margin-bottom: 3.25rem !important;
}

.mb-60 {
  margin-bottom: 4rem !important;
}

.mb-80 {
  margin-bottom: 5rem !important;
}

.mb-100 {
  margin-bottom: 6.25rem !important;
}

.mb-130 {
  margin-bottom: sizeToRem(130) !important;
}

.mb-150 {
  margin-bottom: 9.5rem !important;
}

.page-content-bottom-padding {
  padding-bottom: sizeToRem(100);
}

/* header with blue - grey underline */

.header-underline {
  border-bottom: 1px solid $border-secondary;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    height: 4px;
    width: 117px;
    background-color: $primary;
    border-radius: 2px;
  }
}

/* images lazy loading */

.lazyload,
.lazyloading {
  opacity: 0;
}
.lazyloaded {
  opacity: 1;
  transition: opacity $transition-timing;
}

.lazyImg169 {
  @include lazyImg(56.25%);
}

.lazyImgOseMain {
  @include lazyImg(43.25%);
}

img[data-sizes='auto'] {
  display: block;
  width: 100%;
}

//LOADER WRAPPER STYLES

.ose-loader-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  min-height: sizeToRem(200);
}

.api-loader-error {
  margin: sizeToRem(50) 0;
}

/* other */

.text-no-overflow {
  overflow-wrap: break-word;
}

.tile-link-hover {
  display: block;
  &:hover,
  &:focus {
    color: initial;
    text-decoration: none;
  }
  @include media-breakpoint-up(md) {
    transition: box-shadow $transition-timing;
    border-radius: $border-radius-img;
    &:hover {
      box-shadow: 0 0 15px rgba($primary, 0.15);
    }
  }
}

.page-header-wrapper {
  background-color: $light;
  margin-bottom: sizeToRem(100);
  h1 {
    padding: sizeToRem(80) 0;
    margin: 0;
    @include media-breakpoint-down(sm) {
      padding: sizeToRem(38) 0;
    }
    @include media-breakpoint-down(xs) {
      font-size: sizeToRem(32);
    }
  }
  @include media-breakpoint-down(sm) {
    margin-bottom: sizeToRem(36);
  }
}

//  FAB SCROLL TO TOP

.fab-inview-tracker {
  width: 100%;
  position: absolute;
  height: 50vh;
  top: 0;
  left: 0;
  pointer-events: none;
}

.fab-scroll-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  bottom: sizeToRem(98);
  z-index: 996;
  pointer-events: none;

  .content-wrapper {
    max-width: $page-max-width;
  }

  .fab-scroll-btn {
    background-color: transparent;
    border: none;
    padding: 0;
    height: sizeToRem(90);
    width: sizeToRem(90);
    pointer-events: auto;
    margin-right: sizeToRem(82);
    display: none;
    opacity: 0;
    img {
      height: 100%;
      width: 100%;
    }
  }

  @include media-breakpoint-down(md) {
    bottom: sizeToRem(36);
    .fab-scroll-btn {
      height: sizeToRem(60);
      width: sizeToRem(60);
      margin-right: sizeToRem(20);
    }
  }
}
