.mlegstudentrecommend-section-three {
  padding-top: sizeToRem(40);
  .mlegstudentrecommend-section-three-title {
    max-width: sizeToRem(600);
    margin-bottom: sizeToRem(40);
  }
  .mlegstudentrecommend-section-three-description {
    max-width: sizeToRem(500);
    margin-bottom: sizeToRem(100);
    @include media-breakpoint-down(sm) {
      margin-bottom: sizeToRem(60);
    }
  }
  .mlegstudentrecommend-section-three-error {
    border-top: 1px solid $dark;
    border-bottom: 1px solid $dark;
    padding: sizeToRem(12) 0;
    margin-bottom: sizeToRem(130);
    font-size: sizeToRem(18);
    @include media-breakpoint-down(sm) {
      margin-bottom: sizeToRem(90);
    }
  }
}
.mlegstudentrecommend-section-seven {
  background-color: $mleg-student-primary;
  color: white;
}

.mleg-student-recommend {
  //OVERRIDE TABLE
  .school-list-filter-wrapper {
    background-color: $mleg-student-light;
    padding-bottom: 0;
  }
  //OVERRIDE SECTION THREE FROM MLEG
  .mlegrecommend-section-three {
    background-color: $mleg-student-light;
    .mlegrecommend-section-three-tile-desc,
    .mlegrecommend-section-four-tile-desc {
      h3 {
        color: $mleg-student-primary;
      }
    }
    .mlegrecommend-section-four-tile-desc {
      p {
        font-weight: normal;
      }
    }
  }

  //OVERRIDE SECTION FOUR FROM MLEG
  .mlegrecommend-section-four .mlegrecommend-section-four-files-container .mlegrecommend-section-four-file button {
    color: $mleg-student-primary;
  }
  //OVERRIDE PAGE SECTION BANNER
  .page-section-banner-wrapper {
    .page-section-banner-overlay {
      background: linear-gradient(67deg, $mleg-student-primary 0%, $mleg-student-secondary 100%);
      opacity: 0.8;
    }
  }
}
