.certificates {
  .certificates-section {
    margin-bottom: sizeToRem(80);
    overflow: hidden;
    .certificates-title {
      font-size: sizeToRem(36);
      margin-bottom: sizeToRem(40);
    }
  }
  .certificates-desc {
    max-width: sizeToRem(860);
    a {
      color: $primary;
      word-wrap: break-word;
      font-weight: 600;
    }
  }
  .certificates-row:not(:last-child) {
    border-bottom: 1px solid $gray;
  }
  .certificates-element-title {
    background-color: $light;
    line-height: 1.2;
    padding: sizeToRem(14);
    width: sizeToRem(320);
    @include media-breakpoint-down(md) {
      width: 100%;
    }
    & > p {
      font-size: sizeToRem(20);
      margin-bottom: 0;
    }
  }
  .certificates-list {
    display: flex;
    align-items: center;
    height: 100%;
    &.wrapped {
      flex-direction: column;
      align-items: start;
      .certificates-btn:not(:first-child) {
        margin-top: 0;
      }
    }
  }
  .certificates-btn {
    font-size: sizeToRem(16);
    font-family: $font-fam-headers;
    font-weight: bold;
    text-align: left;
    margin: 0 sizeToRem(30);
    @include media-breakpoint-down(md) {
      margin: sizeToRem(30) sizeToRem(30) sizeToRem(30) sizeToRem(14);
    }
    &.with-icon {
      &.left {
        padding-left: sizeToRem(41) !important;
      }
    }
    .ose-button-icon {
      width: sizeToRem(29);
    }
  }
}
