.mlegrecommend-section-two {
  padding: sizeToRem(150) 0 sizeToRem(100);
  @include media-breakpoint-down(md) {
    padding-bottom: sizeToRem(60);
  }
  .mlegrecommend-section-two-title {
    margin-bottom: sizeToRem(40);
    max-width: sizeToRem(600);
  }
  .mlegrecommend-section-two-description {
    max-width: sizeToRem(500);
    margin-bottom: sizeToRem(100);
    @include media-breakpoint-down(sm) {
      margin-bottom: sizeToRem(60);
    }
  }
  .mlegrecommend-section-two-tile-wrapper {
    @include media-breakpoint-down(md) {
      margin-bottom: sizeToRem(80);
    }
    @include media-breakpoint-down(xs) {
      margin-bottom: sizeToRem(40);
    }
  }
  .mlegrecommend-section-two-tile {
    font-size: sizeToRem(18);
    font-weight: 600;
    height: 100%;
    .mlegrecommend-section-two-icon {
      width: sizeToRem(80);
      height: sizeToRem(80);
      margin-bottom: sizeToRem(30);
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
}

//SECTION THREE
.mlegrecommend-section-three {
  background-color: $mleg-light;
  position: relative;
  overflow: hidden;
  margin-top: sizeToRem(20);
  padding: sizeToRem(240) 0 sizeToRem(160);
  &::before {
    content: '';
    position: absolute;
    top: 0;
    height: sizeToRem(108);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1920' height='108' viewBox='0 0 1920 108' preserveAspectRatio='none meet' %3E%3Cpath d='M960 108.001c-40.966 0-82.458-.586-123.323-1.742-40.708-1.149-81.91-2.9-122.464-5.192a4336.882 4336.882 0 01-121.561-8.6 4318.283 4318.283 0 01-120.611-11.96 4305.973 4305.973 0 01-119.616-15.271A4300.617 4300.617 0 01233.85 46.697c-39.1-6.678-78.633-14-117.49-21.761A4272.646 4272.646 0 01.001.001h1920a4277.444 4277.444 0 01-116.358 24.935 4284.147 4284.147 0 01-117.49 21.761 4282.691 4282.691 0 01-118.576 18.539 4303.705 4303.705 0 01-119.617 15.271 4319.41 4319.41 0 01-120.611 11.96 4336.614 4336.614 0 01-121.561 8.6 4353.233 4353.233 0 01-122.464 5.192A4385.647 4385.647 0 01960 108.001z' fill='%23fff' /%3E%3C/svg%3E");
    background-size: 100% 100%;
    width: 100%;
    @include media-breakpoint-down(md) {
      height: sizeToRem(30);
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='408' height='30' viewBox='0 0 408 30' preserveAspectRatio='none meet' %3E%3Cpath data-name='Exclusion 1' d='M204.032 29.565h-1.755A719.921 719.921 0 010 .368V0h408v.368a719.9 719.9 0 01-203.968 29.197z' fill='%23fff' /%3E%3C/svg%3E");
    }
  }
  @include media-breakpoint-down(lg) {
    margin-top: 0;
    padding: sizeToRem(190) 0 sizeToRem(120);
  }
  @include media-breakpoint-down(sm) {
    padding: sizeToRem(120) 0 sizeToRem(120);
  }
  .mlegrecommend-section-three-title {
    max-width: sizeToRem(600);
    margin-bottom: sizeToRem(40);
  }
  .mlegrecommend-section-three-description {
    max-width: sizeToRem(500);
    margin-bottom: sizeToRem(100);
    @include media-breakpoint-down(sm) {
      margin-bottom: sizeToRem(60);
    }
  }
  .mlegrecommend-section-three-tile-wrapper {
    &:not(:last-child) {
      margin-bottom: sizeToRem(80);
      @include media-breakpoint-down(xs) {
        margin-bottom: sizeToRem(40);
      }
    }
  }
  .mlegrecommend-section-three-tile {
    display: flex;
    font-size: sizeToRem(18);
    font-weight: 600;
    height: 100%;
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      .mlegrecommend-section-three-tile-desc {
        margin-top: sizeToRem(30);
      }
    }
    .mlegrecommend-section-three-tile-desc {
      display: flex;
      flex-direction: column;
      justify-content: center;
      h3 {
        font-size: sizeToRem(36);
        color: $mleg-primary;
        margin-bottom: sizeToRem(30);
        @include media-breakpoint-down(md) {
          font-size: sizeToRem(26);
        }
      }
    }
    .mlegrecommend-section-three-icon {
      min-width: sizeToRem(170);
      min-height: sizeToRem(170);
      width: sizeToRem(170);
      height: sizeToRem(170);
      margin-right: sizeToRem(30);
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
}

//SECTION FOUR
.mlegrecommend-section-four {
  padding: sizeToRem(120) 0 sizeToRem(180);
  @include media-breakpoint-down(md) {
    padding-bottom: sizeToRem(120);
  }
  .mlegrecommend-section-four-title {
    max-width: sizeToRem(600);
    margin-bottom: sizeToRem(30);
  }
  .mlegrecommend-section-four-description {
    max-width: sizeToRem(500);
    margin-bottom: sizeToRem(60);
  }
  .mlegrecommend-section-four-image {
    justify-content: center;
    margin-bottom: sizeToRem(68);
    img {
      max-width: sizeToRem(300);
      border-radius: 100%;
      margin: 0 auto;
    }
    @include media-breakpoint-up(md) {
      margin: 0;
      position: sticky;
      top: sizeToRem(80);
      img {
        max-width: initial;
      }
    }
    @include media-breakpoint-up(lg) {
      top: 0;
    }
  }
  .mlegrecommend-section-four-files-container {
    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
    }
    .mlegrecommend-section-four-file {
      &:not(:last-of-type) {
        margin-bottom: sizeToRem(24);
      }
      button {
        font-weight: 700;
        font-size: sizeToRem(18);
        display: flex;
        color: $primary;
        background: none;
        border: none;
        text-align: left;
      }
      svg {
        max-width: sizeToRem(20);
        margin-right: sizeToRem(18);
      }
    }
  }
}
