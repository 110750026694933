.cookies-modal {
  left: 50%;
  transform: translateX(-50%) !important;
  max-width: sizeToRem(800);
  width: 100%;
  padding: 0 sizeToRem(5) !important;
  margin: 0 !important;
  .modal-dialog {
    max-width: unset;
    margin: unset;
  }

  @include media-breakpoint-down(sm) {
    max-height: 100%;
    padding: 0;
  }

  p {
    @include media-breakpoint-down(sm) {
      font-size: sizeToRem(14);
      margin-bottom: sizeToRem(8);
    }
  }
  .cookies-scrollbar {
    width: 100%;
    height: sizeToRem(230) !important;
    overflow-x: hidden;
    @include media-breakpoint-down(sm) {
      height: sizeToRem(200);
    }
    @include media-breakpoint-down(xs) {
      height: sizeToRem(120);
    }
    & > div:first-child {
      padding-right: sizeToRem(24);
    }
  }
  .cookies-policy {
    word-break: break-word;
    ul {
      padding-left: sizeToRem(20);
    }
  }
  .modal-content {
    width: 100%;
    height: 100%;
    margin: auto;
    border-radius: $border-radius;
    padding: sizeToRem(30);
    border: none;
    @include media-breakpoint-down(sm) {
      font-size: sizeToRem(14);
      margin: 0.4rem 0 !important;
      padding: sizeToRem(20);
    }
  }
  .modal-header {
    border: none;
    padding: 0;
    margin-bottom: 1rem;
    .modal-title {
      font-size: 1.5rem;
      font-weight: 500;
    }
  }
  .modal-body {
    padding: sizeToRem(10) sizeToRem(5) sizeToRem(10) sizeToRem(10);
    background: $light;
    overflow-y: hidden;
    margin-bottom: 1rem;
  }
  .modal-footer {
    margin: 0.5rem 0 0 0;
    padding: 0;
    border: none;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  .cookies-button {
    width: sizeToRem(200);
    @include media-breakpoint-down(sm) {
      max-width: sizeToRem(150);
      font-size: sizeToRem(14);
    }
    @include media-breakpoint-down(xs) {
      max-width: sizeToRem(120);
    }
  }
}
