.ose-services-item {
  padding-bottom: sizeToRem(80);
  h2 {
    font-size: sizeToRem(26);
    margin-bottom: sizeToRem(20);
    @include media-breakpoint-up(md) {
      font-size: sizeToRem(30);
    }
    @include media-breakpoint-up(lg) {
      font-size: sizeToRem(40);
    }
  }
  h3 {
    font-size: sizeToRem(22);
    @include media-breakpoint-up(md) {
      font-size: sizeToRem(24);
    }
    @include media-breakpoint-up(lg) {
      font-size: sizeToRem(32);
    }
  }
  .ose-services-item-btn {
    font-family: $font-fam-headers;
    font-weight: bold;
    text-align: left;
    &.with-icon {
      &.left {
        padding-left: sizeToRem(32) !important;
      }
    }
    .ose-button-icon {
      width: sizeToRem(22);
      height: sizeToRem(22);
    }
  }
  .ose-services-item-section {
    margin-top: sizeToRem(80);
    a {
      color: $primary;
    }
  }
  .ose-services-item-banner-title {
    position: relative;
    min-height: sizeToRem(270);
    display: flex;
    align-items: center;
    color: white;
    margin-bottom: sizeToRem(20);
    h1 {
      position: relative;
      flex-grow: 1;
      margin: 0 auto;
      text-align: center;
      font-size: sizeToRem(28);
      word-break: break-word;
      @include media-breakpoint-up(md) {
        font-size: sizeToRem(42);
        word-break: keep-all;
      }
      @include media-breakpoint-up(lg) {
        max-width: sizeToRem(600);
        font-size: sizeToRem(52);
        margin: 0;
        text-align: left;
      }
      @include media-breakpoint-up(xl) {
        max-width: sizeToRem(790);
        font-size: sizeToRem(64);
      }
    }
    @include media-breakpoint-up(lg) {
      min-height: sizeToRem(449);
    }
  }
  .ose-services-item-banner-content-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    @include media-breakpoint-up(lg) {
      justify-content: flex-start;
    }
  }
  .ose-services-item-banner-title-icon {
    display: block;
    margin-left: auto;
    max-height: sizeToRem(132);
  }
  .ose-services-item-banner-title-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 360 177'%3E%3ClinearGradient id='prefix__a' gradientUnits='userSpaceOnUse' x1='-34.979' y1='87.713' x2='352.021' y2='88.713'%3E%3Cstop offset='.028' stop-color='%232737f5'/%3E%3Cstop offset='1' stop-color='%2311053b'/%3E%3C/linearGradient%3E%3Cpath d='M0 146.3V0h360v146.3a543.455 543.455 0 01-360 0z' fill='url(%23prefix__a)'/%3E%3C/svg%3E");
    @include media-breakpoint-up(lg) {
      max-width: sizeToRem(1344);
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='1344' height='448' viewBox='0 0 1344 448'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='-0.039' y1='-0.087' x2='0.823' y2='0.918' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%232737f5'/%3E%3Cstop offset='1' stop-color='%2311053b'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cpath id='Subtraction_2' data-name='Subtraction 2' d='M2354.051 10527H1448v-448H2787.24a358.861 358.861 0 0 1-14.437 174.233 367.109 367.109 0 0 1-14.359 35.8 380.335 380.335 0 0 1-39.369 66.185 402.744 402.744 0 0 1-52.141 57.664 424.153 424.153 0 0 1-63.195 47.577 441.392 441.392 0 0 1-72.532 35.925 455.39 455.39 0 0 1-80.152 22.7c-13.931 2.6-28.2 4.587-42.4 5.9-14.406 1.335-29.095 2.012-43.658 2.012-3.609 0-7.292-.043-10.949-.126v.126Z' transform='translate(-1448 -10078.999)' fill='url(%23linear-gradient)'/%3E%3C/svg%3E");
      background-position: right;
      max-width: 72%;
    }
  }
}
