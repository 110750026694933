.oseTopSection-banner {
  position: relative;
  display: flex;
  flex-direction: column;
  @include media-breakpoint-up(lg) {
    overflow: hidden;
  }
  @include media-breakpoint-up(xl) {
    height: calc(100vh - #{$social-bar-height} - #{$nav-height-lg});
    max-height: calc(#{sizeToRem(1080)} - #{$social-bar-height} - #{$nav-height-lg});
    @media (orientation: landscape) and (max-height: 900px) {
      height: auto;
      max-height: unset;
    }
  }
}

.oseTopSection-banner-header {
  z-index: 1;
  padding: sizeToRem(30) 0;
  @include media-breakpoint-down(sm) {
    padding: sizeToRem(16) 0;
  }

  .oseTopSection-banner-header-content {
    height: 100%;
    display: flex;
    align-items: center;
    color: white;
    justify-content: space-between;
    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
    h2 {
      margin: 0;
      @include media-breakpoint-down(sm) {
        margin-bottom: sizeToRem(20);
      }
    }
  }
}

.oseTopSection-banner-content-desc,
.oseTopSection-banner-content-img {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.oseTopSection-banner-content-img {
  @include media-breakpoint-down(sm) {
    align-items: center;
  }
}

.oseTopSection-banner-title {
  font-size: sizeToRem(70);
  margin-bottom: sizeToRem(30);
  @include media-breakpoint-down(md) {
    font-size: sizeToRem(56);
  }
  @include media-breakpoint-down(sm) {
    font-size: sizeToRem(36);
  }
}

.oseTopSection-banner-img-pkc {
  @include media-breakpoint-up(lg) {
    max-height: sizeToRem(640);
  }
  @include media-breakpoint-down(md) {
    max-width: sizeToRem(585);
  }
  @include media-breakpoint-down(sm) {
    max-width: sizeToRem(270);
  }
}

.oseTopSection-banner-content {
  color: white;
  height: 100%;
  @include media-breakpoint-down(sm) {
    padding-bottom: 0;
  }
  .row {
    height: 100%;
  }
  .oseTopSection-banner-title,
  .oseTopSection-banner-desc {
    max-width: sizeToRem(470);
    @include media-breakpoint-down(sm) {
      max-width: unset;
    }
  }
  .oseTopSection-banner-slogan {
    padding-top: sizeToRem(60);
    color: #fdcf7c;
    font-weight: 900;
    max-width: sizeToRem(470);
    @include media-breakpoint-down(sm) {
      max-width: unset;
    }
  }
  .oseTopSection-banner-content-desc {
    padding-top: sizeToRem(60);
    padding-bottom: sizeToRem(60);
  }
  .oseTopSection-banner-img {
    position: relative;
    img {
      position: relative;
      z-index: 2;
    }
  }
  .home-pulsing-circle,
  .home-pulsing-circles-wrapper {
    opacity: 0.15;
  }
}

.oseTopSection-btn-primary {
  background-color: white !important;
  color: $secondary !important;
  min-width: sizeToRem(200);
  border: 0;
  margin-left: sizeToRem(10);
  padding: sizeToRem(10) sizeToRem(44) !important;
  width: 100%;
  @include media-breakpoint-up(sm) {
    width: initial;
  }
  &:hover,
  &:focus {
    color: $primary !important;
    background-color: $light !important;
  }
  @include outline($muted_lighter, 1);
}

//OSE INTERNET TOP SECTION BANNER STYLES

.oseTopSection-banner.ose-internet {
  background: linear-gradient(69deg, $secondary 0%, $primary 100%);
  .oseTopSection-banner-header {
    background-color: $secondary;
  }
  .oseTopSection-banner-content-desc {
    @include media-breakpoint-down(sm) {
      margin-bottom: sizeToRem(40);
    }
  }
  .oseTopSection-banner-img {
    img {
      width: 100%;
      max-width: sizeToRem(570);
      padding: sizeToRem(60) 0;
      border-radius: 50%;
      @include media-breakpoint-down(lg) {
        padding: 0;
        max-width: sizeToRem(480);
        margin: 0 auto;
      }
      @include media-breakpoint-down(sm) {
        margin: 0;
        width: sizeToRem(360);
        margin-bottom: sizeToRem(-30);
      }
      @include media-breakpoint-down(xs) {
        width: sizeToRem(240);
      }
    }
    .home-pulsing-circle {
      top: sizeToRem(60);
      left: sizeToRem(-50);
      width: sizeToRem(150);
      height: sizeToRem(150);
      border-width: sizeToRem(2);
      @include media-breakpoint-down(lg) {
        top: 0;
      }
      @include media-breakpoint-down(md) {
        display: block !important;
        animation: none;
      }
    }
    .home-pulsing-circles-wrapper {
      .inner-circle {
        border-width: sizeToRem(2);
        width: sizeToRem(215);
        height: sizeToRem(215);
        &:after {
          border-width: sizeToRem(2);
          width: sizeToRem(200);
          height: sizeToRem(200);
        }
        &:before {
          border-width: sizeToRem(2);
          bottom: sizeToRem(57);
          width: sizeToRem(86);
          height: sizeToRem(86);
        }
      }
    }
  }
}

//OSE ITSZKOLA AND MLEG STYLES (AND MOCHRONA)

.oseTopSection-banner {
  &.itszkola,
  &.mochrona,
  &.mleg {
    overflow: hidden;
    .oseTopSection-banner-title {
      position: relative;
      z-index: 3;
    }
    .oseTopSection-banner-content-img {
      justify-content: flex-end;
      img {
        width: auto;
      }
    }
    .oseTopSection-banner-content-desc {
      padding-top: sizeToRem(30);
      padding-bottom: sizeToRem(30);
    }
  }
}

//MOCHRONA TOP SECTION BANNER STYLES

.oseTopSection-banner.mochrona {
  background: linear-gradient(69deg, $mochrona-primary 0%, $mochrona-secondary 100%);
  .oseTopSection-banner-header {
    background-color: $secondary;
  }
  .oseTopSection-banner-title {
    max-width: unset;
  }
  .oseTopSection-banner-img {
    display: flex;
    img {
      margin: 0 auto;
      height: calc(100vh - #{$social-bar-height} - #{$nav-height-lg} - #{sizeToRem(130)}) !important;
      max-height: sizeToRem(720) !important;
      @include media-breakpoint-down(md) {
        height: auto !important;
        max-height: unset;
      }
      @include media-breakpoint-down(sm) {
        width: sizeToRem(360);
        margin-bottom: sizeToRem(-30);
      }
      @include media-breakpoint-down(xs) {
        width: sizeToRem(240);
      }
    }
    .home-pulsing-circle {
      bottom: sizeToRem(50);
      left: 0;
      top: unset;
      width: sizeToRem(100);
      height: sizeToRem(100);
      border-width: sizeToRem(2);
      @include media-breakpoint-down(md) {
        display: block !important;
        animation: none;
      }
      @include media-breakpoint-down(sm) {
        top: 50%;
        left: sizeToRem(-80);
        right: unset;
        transform: translateY(-50%);
        width: sizeToRem(160);
        height: sizeToRem(160);
      }
    }
    .home-pulsing-circles-wrapper {
      right: 0;
      top: sizeToRem(60);
      bottom: unset;
      .inner-circle {
        border-width: sizeToRem(2);
        width: sizeToRem(160);
        height: sizeToRem(160);
        &:after {
          border-width: sizeToRem(2);
          width: sizeToRem(144);
          height: sizeToRem(144);
        }
        &:before {
          border-width: sizeToRem(2);
          top: sizeToRem(55);
          width: sizeToRem(50);
          height: sizeToRem(50);
        }
      }
    }
  }
}

//Mistrzowie Energii TOP SECTION BANNER STYLES

.oseTopSection-banner.menergy {
  background: $menergy-primary;
  .oseTopSection-banner-content.with-waves {
    .home-waves {
      position: absolute;
      top: 0;
      left: sizeToRem(-32);
      width: sizeToRem(692);
      height: 100%;
      background: url('../images/ose-wave.svg') no-repeat;
      background-size: 100% 100%;
    }
    .oseTopSection-banner-img {
      position: static;
    }
    @include media-breakpoint-down(md) {
      & {
        position: relative;
        overflow: hidden;
      }
      .oseTopSection-banner-content-img {
        position: static;
      }
      .oseTopSection-banner-img .home-waves {
        left: 50%;
        background-size: contain;
      }
    }
    @include media-breakpoint-down(sm) {
      & {
        overflow: visible;
      }
      .oseTopSection-banner-img .home-waves {
        background: url('../images/ose-wave-horizontal.svg') no-repeat;
        top: initial;
        bottom: sizeToRem(-48);
        left: 0;
        width: 100%;
        background-size: cover;
        background-position: center;
        height: sizeToRem(380);
      }
    }
    @include media-breakpoint-down(xs) {
      .oseTopSection-banner-img .home-waves {
        height: sizeToRem(304);
      }
    }
  }
  .oseTopSection-banner-header {
    background-color: $menergy-header;
  }
  .oseTopSection-banner-title {
    max-width: unset;
  }
  .oseTopSection-banner-img {
    display: flex;
    img {
      margin: 0 auto;
      height: calc(100vh - #{$social-bar-height} - #{$nav-height-lg} - #{sizeToRem(130)}) !important;
      max-height: sizeToRem(485) !important;
      max-width: sizeToRem(440) !important;
      @include media-breakpoint-down(md) {
        height: auto !important;
        max-height: unset;
      }
      @include media-breakpoint-down(sm) {
        width: sizeToRem(360);
      }
      @include media-breakpoint-down(xs) {
        width: sizeToRem(240);
      }
    }
    .home-pulsing-circle {
      bottom: sizeToRem(50);
      left: 0;
      top: unset;
      width: sizeToRem(100);
      height: sizeToRem(100);
      border-width: sizeToRem(2);
      @include media-breakpoint-down(md) {
        display: block !important;
        animation: none;
      }
      @include media-breakpoint-down(sm) {
        top: 50%;
        left: sizeToRem(-80);
        right: unset;
        transform: translateY(-50%);
        width: sizeToRem(160);
        height: sizeToRem(160);
      }
    }
    .home-pulsing-circles-wrapper {
      right: 0;
      top: sizeToRem(60);
      bottom: unset;
      .inner-circle {
        border-width: sizeToRem(2);
        width: sizeToRem(160);
        height: sizeToRem(160);
        &:after {
          border-width: sizeToRem(2);
          width: sizeToRem(144);
          height: sizeToRem(144);
        }
        &:before {
          border-width: sizeToRem(2);
          top: sizeToRem(55);
          width: sizeToRem(50);
          height: sizeToRem(50);
        }
      }
    }
  }
}

//PKC TOP SECTION BANNER STYLES

.oseTopSection-banner.pkc {
  background: white;
  position: relative;

  .c-symbol,
  .z-symbol,
  .k-symbol {
    position: absolute;
  }

  .c-symbol {
    bottom: sizeToRem(-136);
  }

  .z-symbol {
    top: -11%;
    right: 0;
    display: none;
  }

  .k-symbol {
    bottom: 24%;
    right: 0;

    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  .oseTopSection-banner-digital-competence {
    padding-top: sizeToRem(54);
    padding-left: 0;
    @include media-breakpoint-down(sm) {
      padding-top: sizeToRem(0);
    }

    p {
      max-width: sizeToRem(142);
      margin: 0;
      color: $pkc-primary;
      text-transform: uppercase;
      font-family: 'Sofia Sans Condensed', sans-serif;
      font-size: 27px;
      line-height: 1.5rem;
      @include media-breakpoint-down(sm) {
        max-width: sizeToRem(76);
        line-height: 1rem;
      }
    }

    .logo {
      background: url('../images/pracownia-kompetencji-cyfrowych-logo.svg') no-repeat;
      background-size: 100% 100%;
      width: sizeToRem(76);
      height: sizeToRem(69);
    }
  }

  .oseTopSection-banner-title,
  .oseTopSection-banner-desc {
    max-width: unset;
    @include media-breakpoint-down(sm) {
      max-width: unset;
    }
  }

  .oseTopSection-banner-see-morebtn {
    margin-top: sizeToRem(19);
    button {
      clip-path: polygon(16% 6%, 92% 18%, 90% 85%, 85% 91%, 6% 84%, 3% 16%);
      font-family: $font-fam-anton;
      text-transform: uppercase;
      color: $pkc-primary;
      background-color: $pkc-secondary;
      font-size: sizeToRem(16);
      padding: 0.5rem sizeToRem(32.5);

      &:hover {
        background: $pkc-primary;
        color: $pkc-secondary;
      }
    }
    @include media-breakpoint-down(md) {
      margin-top: sizeToRem(27);
    }
    @include media-breakpoint-down(sm) {
      margin-top: sizeToRem(14);
      display: flex;
      justify-content: center;
    }
  }

  .oseTopSection-banner-content.with-waves {
    .home-waves {
      position: absolute;
      top: 0;
      left: sizeToRem(-32);
      width: sizeToRem(692);
      height: 100%;
      background: url('../images/ose-wave.svg') no-repeat;
      background-size: 100% 100%;
    }
    .oseTopSection-banner-img {
      position: static;
    }
    @include media-breakpoint-down(md) {
      & {
        position: relative;
        overflow: hidden;
      }
      .oseTopSection-banner-content-img {
        position: static;
      }
      .oseTopSection-banner-img .home-waves {
        left: 50%;
        background-size: contain;
      }
    }
    @include media-breakpoint-down(sm) {
      & {
        overflow: visible;
      }
      .oseTopSection-banner-img .home-waves {
        background: url('../images/ose-wave-horizontal.svg') no-repeat;
        top: initial;
        bottom: sizeToRem(-48);
        left: 0;
        width: 100%;
        background-size: cover;
        background-position: center;
        height: sizeToRem(380);
      }
    }
    @include media-breakpoint-down(xs) {
      .oseTopSection-banner-img .home-waves {
        height: sizeToRem(304);
      }
    }
  }
  .oseTopSection-banner-header {
    background-color: $pkc-secondary;
    h2 {
      font-family: $font-fam-headers;
    }
  }
  .oseTopSection-banner-title {
    max-width: sizeToRem(558);
    color: $pkc-primary;
    text-transform: uppercase;
    font-size: 3.4375rem;
    @include media-breakpoint-down(md) {
      max-width: sizeToRem(260);
    }
    .highlight {
      color: $pkc-secondary;
      margin-left: sizeToRem(6);
    }
    @include media-breakpoint-down(lg) {
      font-size: sizeToRem(32);
    }
  }
  .oseTopSection-banner-img {
    display: flex;
    @include media-breakpoint-down(sm) {
      display: none;
    }
    img {
      margin: 0 auto;
      height: calc(100vh - #{$social-bar-height} - #{$nav-height-lg} - #{sizeToRem(130)}) !important;
      max-height: sizeToRem(485) !important;
      max-width: sizeToRem(440) !important;
      @include media-breakpoint-down(md) {
        height: auto !important;
        max-height: unset;
      }
      @include media-breakpoint-down(sm) {
        width: sizeToRem(360);
      }
      @include media-breakpoint-down(xs) {
        width: sizeToRem(240);
      }
    }
    .home-pulsing-circle {
      bottom: sizeToRem(50);
      left: 0;
      top: unset;
      width: sizeToRem(100);
      height: sizeToRem(100);
      border-width: sizeToRem(2);
      @include media-breakpoint-down(md) {
        display: block !important;
        animation: none;
      }
      @include media-breakpoint-down(sm) {
        top: 50%;
        left: sizeToRem(-80);
        right: unset;
        transform: translateY(-50%);
        width: sizeToRem(160);
        height: sizeToRem(160);
      }
    }
    .home-pulsing-circles-wrapper {
      right: 0;
      top: sizeToRem(60);
      bottom: unset;
      .inner-circle {
        border-width: sizeToRem(2);
        width: sizeToRem(160);
        height: sizeToRem(160);
        &:after {
          border-width: sizeToRem(2);
          width: sizeToRem(144);
          height: sizeToRem(144);
        }
        &:before {
          border-width: sizeToRem(2);
          top: sizeToRem(55);
          width: sizeToRem(50);
          height: sizeToRem(50);
        }
      }
    }
  }
  .oseTopSection-banner-title {
    font-size: sizeToRem(36);
    margin-top: sizeToRem(24);
    @include media-breakpoint-down(md) {
      font-size: sizeToRem(38);
      margin-bottom: sizeToRem(30);
      max-width: sizeToRem(540);
    }
    @include media-breakpoint-down(sm) {
      font-size: sizeToRem(20);
    }
  }
  .oseTopSection-banner-title,
  .oseTopSection-banner-desc {
    font-family: $font-fam-inter;
  }
  .oseTopSection-banner-desc {
    color: $pkc-primary;
    @include media-breakpoint-down(md) {
      max-width: unset;
    }
    p {
      @include media-breakpoint-down(sm) {
        font-size: sizeToRem(14);
        line-height: sizeToRem(24);
      }
    }
  }
}

//OSE ITSZKOLA TOP SECTION BANNER STYLES

.oseTopSection-banner.itszkola {
  background: linear-gradient(69deg, $its-primary 0%, $its-secondary 100%);
  .oseTopSection-banner-header {
    background-color: $its-secondary;
  }
  .oseTopSection-banner-title {
    max-width: unset;
  }
  .oseTopSection-banner-img {
    display: flex;
    img {
      margin: 0 auto;
      height: calc(100vh - #{$social-bar-height} - #{$nav-height-lg} - #{sizeToRem(130)}) !important;
      max-height: sizeToRem(720) !important;
      @include media-breakpoint-down(md) {
        height: auto !important;
        max-height: unset;
      }
      @include media-breakpoint-down(sm) {
        width: sizeToRem(360);
        margin-bottom: sizeToRem(-30);
      }
      @include media-breakpoint-down(xs) {
        width: sizeToRem(240);
      }
    }
    .home-pulsing-circle {
      top: sizeToRem(50);
      left: unset;
      right: 10%;
      width: sizeToRem(100);
      height: sizeToRem(100);
      border-width: sizeToRem(2);
      @include media-breakpoint-down(md) {
        display: block !important;
        animation: none;
      }
      @include media-breakpoint-down(sm) {
        top: 50%;
        left: sizeToRem(-80);
        right: unset;
        transform: translateY(-50%);
        width: sizeToRem(160);
        height: sizeToRem(160);
      }
    }
    .home-pulsing-circles-wrapper {
      left: 0;
      bottom: sizeToRem(60);
      right: unset;
      .inner-circle {
        border-width: sizeToRem(2);
        width: sizeToRem(160);
        height: sizeToRem(160);
        &:after {
          border-width: sizeToRem(2);
          width: sizeToRem(144);
          height: sizeToRem(144);
        }
        &:before {
          border-width: sizeToRem(2);
          bottom: sizeToRem(55);
          width: sizeToRem(50);
          height: sizeToRem(50);
        }
      }
    }
  }
}

//OSE MLEG TOP SECTION BANNER STYLES

.oseTopSection-banner.mleg {
  background: linear-gradient(69deg, $mleg-primary 0%, $mleg-secondary 100%);
  .oseTopSection-banner-header {
    background-color: $secondary;
  }
  .oseTopSection-banner-img {
    display: flex;
    img {
      margin: 0 auto;
      height: calc(100vh - #{$social-bar-height} - #{$nav-height-lg} - #{sizeToRem(130)}) !important;
      max-height: sizeToRem(660) !important;
      @include media-breakpoint-down(md) {
        height: auto !important;
        max-height: unset !important;
      }
      @include media-breakpoint-down(sm) {
        width: sizeToRem(360);
        margin-bottom: sizeToRem(-30);
      }
      @include media-breakpoint-down(xs) {
        width: sizeToRem(240);
      }
    }
    .home-pulsing-circle {
      top: sizeToRem(50);
      left: 25%;
      width: sizeToRem(100);
      height: sizeToRem(100);
      border-width: sizeToRem(2);
      @include media-breakpoint-down(md) {
        display: block !important;
        animation: none;
      }
      @include media-breakpoint-down(sm) {
        top: 50%;
        left: sizeToRem(-80);
        right: unset;
        transform: translateY(-50%);
        width: sizeToRem(160);
        height: sizeToRem(160);
      }
    }
    .home-pulsing-circles-wrapper {
      bottom: sizeToRem(60);
      right: sizeToRem(20);
      .inner-circle {
        border-width: sizeToRem(2);
        width: sizeToRem(160);
        height: sizeToRem(160);
        &:after {
          border-width: sizeToRem(2);
          width: sizeToRem(144);
          height: sizeToRem(144);
        }
        &:before {
          border-width: sizeToRem(2);
          bottom: sizeToRem(55);
          width: sizeToRem(50);
          height: sizeToRem(50);
        }
      }
    }
  }
}

//OSE MLEG STUDENT TOP SECTION BANNER STYLES

.oseTopSection-banner.mleg.student {
  background: linear-gradient(69deg, $mleg-student-primary 0%, $mleg-student-secondary 100%);
  .oseTopSection-banner-header {
    background-color: $mleg-student-primary;
  }
}

//OSE CAMPAIGN TOP SECTION BANNER STYLES

.oseTopSection-banner.ose-campaign {
  background: linear-gradient(69deg, rgba($secondary, 0.85) 0%, rgba($primary, 0.85) 100%);
  .oseTopSection-banner-content.with-waves {
    .home-waves {
      position: absolute;
      top: 0;
      left: sizeToRem(-32);
      width: sizeToRem(692);
      height: 100%;
      background: url('../images/ose-wave.svg') no-repeat;
      background-size: 100% 100%;
    }
    .oseTopSection-banner-img {
      position: static;
    }
    @include media-breakpoint-down(md) {
      & {
        position: relative;
        overflow: hidden;
      }
      .oseTopSection-banner-content-img {
        position: static;
      }
      .oseTopSection-banner-img .home-waves {
        left: 50%;
        background-size: contain;
      }
    }
    @include media-breakpoint-down(sm) {
      & {
        overflow: visible;
      }
      .oseTopSection-banner-img .home-waves {
        background: url('../images/ose-wave-horizontal.svg') no-repeat;
        top: initial;
        bottom: sizeToRem(-48);
        left: 0;
        width: 100%;
        background-size: cover;
        background-position: center;
        height: sizeToRem(380);
      }
    }
    @include media-breakpoint-down(xs) {
      .oseTopSection-banner-img .home-waves {
        height: sizeToRem(304);
      }
    }
  }
  .oseTopSection-banner-header {
    background-color: $secondary;
  }
  .oseTopSection-banner-content-desc {
    @include media-breakpoint-down(sm) {
      margin-bottom: sizeToRem(40);
    }
  }
  .oseTopSection-banner-img {
    img {
      width: 100%;
      max-width: sizeToRem(570);
      padding: sizeToRem(60) 0;
      border-radius: 50%;
      @include media-breakpoint-down(lg) {
        padding: 0;
        max-width: sizeToRem(480);
        margin: 0 auto;
      }
      @include media-breakpoint-down(sm) {
        margin: 0;
        width: sizeToRem(360);
        margin-bottom: sizeToRem(-30);
      }
      @include media-breakpoint-down(xs) {
        width: sizeToRem(240);
      }
    }
  }
}
