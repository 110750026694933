.fortrs-row {
  margin-bottom: sizeToRem(80);
}
.fortrs-video {
  border-radius: sizeToRem(25);
  overflow: hidden;
  @include media-breakpoint-down(md) {
    margin-bottom: sizeToRem(30);
  }
}
.fortrs-title {
  margin-bottom: sizeToRem(30);
  @include media-breakpoint-down(sm) {
    font-size: sizeToRem(26);
  }
}

.fortrs-btn {
  &.with-icon {
    &.left {
      padding-left: sizeToRem(32) !important;
    }
  }
  .ose-button-icon {
    width: sizeToRem(22);
    height: sizeToRem(22);
  }
}
