//SECOND SECTION

.mochronarecommend-section-two {
  padding: sizeToRem(150) 0;
  @include media-breakpoint-down(sm) {
    padding: sizeToRem(80) 0;
  }
  .mochronarecommend-section-two-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include media-breakpoint-down(sm) {
      align-items: center;
    }
    .mochronarecommend-section-two-image {
      img {
        max-width: 100%;
      }
    }
    .mochronarecommend-section-two-content {
      h2 {
        margin-bottom: sizeToRem(30);
        @include media-breakpoint-down(md) {
          font-size: sizeToRem(36);
        }
      }
    }
  }
}

//SECTION THREE

.mochronarecommend-section-three {
  background-color: #faf9fc;
  overflow: hidden;
  padding: sizeToRem(150) 0;
  @include media-breakpoint-down(sm) {
    padding: sizeToRem(80) 0;
  }
  .mochronarecommend-section-three-image {
    position: relative;
    .firstCircle,
    .secondCircle,
    .thirdCircle,
    .forthCircle {
      position: absolute;
      border: 1px solid #7f29d0;
      border-radius: 50%;
      content: '';
      background-color: transparent;
      opacity: 0.3;
      z-index: 2;
    }
    .firstCircle {
      top: 0;
      left: 0;
      height: sizeToRem(380);
      width: sizeToRem(380);
      @include media-breakpoint-down(md) {
        height: sizeToRem(280);
        width: sizeToRem(280);
      }
    }
    .secondCircle {
      left: sizeToRem(15);
      top: sizeToRem(15);
      height: sizeToRem(350);
      width: sizeToRem(350);
      @include media-breakpoint-down(md) {
        height: sizeToRem(250);
        width: sizeToRem(250);
      }
    }
    .thirdCircle {
      left: sizeToRem(110);
      top: sizeToRem(110);
      height: sizeToRem(160);
      width: sizeToRem(160);
      @include media-breakpoint-down(md) {
        left: sizeToRem(90);
        top: sizeToRem(90);
        height: sizeToRem(100);
        width: sizeToRem(100);
      }
    }
    .forthCircle {
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      height: sizeToRem(140);
      width: sizeToRem(140);
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
    img {
      position: relative;
      z-index: 3;
      max-width: 100%;
      @include media-breakpoint-down(md) {
        max-height: sizeToRem(700);
      }
    }
  }
  .mochronarecommend-section-three-content {
    h2 {
      margin-bottom: sizeToRem(80);
      max-width: sizeToRem(480);
    }
  }
  .mochronarecommend-section-three-element {
    margin-bottom: sizeToRem(60);
    &:last-of-type {
      margin-bottom: 0;
    }
    h3 {
      color: $primary;
      margin-bottom: sizeToRem(30);
      @include media-breakpoint-down(lg) {
        font-size: sizeToRem(26);
      }
      img {
        width: sizeToRem(44);
        height: sizeToRem(44);
        object-fit: contain;
        margin-right: sizeToRem(30);
        @include media-breakpoint-down(lg) {
          width: sizeToRem(36);
          margin-right: sizeToRem(22);
          height: sizeToRem(36);
        }
      }
    }
  }
}

//SECTION FOUR

.mochronarecommend-section-four {
  padding: sizeToRem(240) 0 sizeToRem(150);
  @include media-breakpoint-down(md) {
    padding: sizeToRem(150) 0 sizeToRem(130);
  }
  @include media-breakpoint-down(sm) {
    padding: sizeToRem(90) 0;
  }
}
.mochronarecommend-section-four-title {
  max-width: sizeToRem(473);
  margin-bottom: sizeToRem(30);
}
.mochronarecommend-section-four-description {
  max-width: sizeToRem(496);
  margin-bottom: sizeToRem(112);
}
.mochronarecommend-section-four-category-tile-wrapper {
  margin-bottom: sizeToRem(80);
}
.mochronarecommend-section-four-category-tile {
  position: relative;
  height: 100%;
  background: white;
  box-shadow: sizeToRem(150) sizeToRem(50) sizeToRem(99) #faf9fc;
  padding: sizeToRem(60) sizeToRem(20) sizeToRem(30);
  border-radius: sizeToRem(20);
  img {
    position: absolute;
    left: sizeToRem(15);
    top: sizeToRem(-30);
    width: sizeToRem(70);
    height: sizeToRem(70);
    margin-bottom: sizeToRem(16);
  }
  h4 {
    max-width: sizeToRem(226);
  }
  p {
    margin: 0;
    max-width: sizeToRem(380);
  }
}

//SECTION FIFTH

.mochronarecommend-section-five {
  overflow: hidden;
  padding: sizeToRem(100) 0 sizeToRem(150);
  @include media-breakpoint-down(md) {
    padding: sizeToRem(20) 0 0;
  }
  .mochronarecommend-section-five-content {
    position: relative;
    z-index: 2;
    h2 {
      margin-bottom: sizeToRem(30);
      max-width: sizeToRem(520);
    }
    p {
      max-width: sizeToRem(470);
    }
  }
  .mochronarecommend-section-five-row {
    padding: sizeToRem(60) 0;
    max-width: sizeToRem(470);
  }
  .mochronarecommend-section-five-element {
    a {
      display: block;
      max-width: sizeToRem(205);
      min-width: sizeToRem(200);
      margin-bottom: sizeToRem(20);
      @include media-breakpoint-down(sm) {
        min-width: unset;
        width: 100%;
      }
      img {
        max-width: 100%;
        width: 100%;
        height: auto;
      }
    }
  }
  .mochronarecommend-section-five-image {
    position: relative;
    width: 100%;
    height: sizeToRem(800);
    @include media-breakpoint-down(md) {
      height: sizeToRem(600);
    }
    img {
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      height: sizeToRem(1300);
      @include media-breakpoint-down(md) {
        height: sizeToRem(800);
      }
    }
  }
  .mochronarecommend-section-five-cta {
    margin-top: sizeToRem(30);
  }
}

//SECTION SIXTH

.mochronarecommend-section-six {
  padding: sizeToRem(100) 0 sizeToRem(140);
  background-color: #edeeff;
  @include media-breakpoint-down(md) {
    padding-bottom: sizeToRem(120);
  }
  .mochronarecommend-section-six-title {
    max-width: sizeToRem(600);
    margin-bottom: sizeToRem(30);
  }
  .mochronarecommend-section-six-description {
    max-width: sizeToRem(500);
    margin-bottom: sizeToRem(60);
  }
  .mochronarecommend-section-six-image {
    justify-content: center;
    img {
      max-width: sizeToRem(270);
    }
    @include media-breakpoint-down(md) {
      max-width: sizeToRem(220);
    }
  }
  .mochronarecommend-section-six-files-container {
    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
    }
    .mochronarecommend-section-six-file {
      &:not(:last-of-type) {
        margin-bottom: sizeToRem(24);
      }
      button {
        display: flex;
        color: $primary;
        background: none;
        border: none;
        text-align: left;
        font-weight: 700;
        font-size: sizeToRem(18);
      }
      svg {
        max-width: sizeToRem(20);
        margin-right: sizeToRem(18);
      }
    }
  }
}

//SECTION SEVEN

.mochronarecommend-section-seventh {
  .page-section-banner-header h2 {
    max-width: sizeToRem(540);
  }
}
