.error-fallback {
  padding: sizeToRem(90) 0;
  max-width: sizeToRem(420);
  .error-fallback-img {
    max-width: sizeToRem(380);
    width: 100%;
    margin-bottom: sizeToRem(60);
  }
  .error-fallback-title {
    font-size: sizeToRem(36);
    margin-bottom: sizeToRem(24);
  }
  .error-fallback-info {
    font-size: sizeToRem(18);
    margin-bottom: sizeToRem(50);
    font-weight: 500;
  }
}
