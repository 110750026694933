.regulations {
  .regulations-section {
    margin-bottom: sizeToRem(80);
  }
  .regulations-title {
    font-size: sizeToRem(36);
    margin-bottom: sizeToRem(30);
    @include media-breakpoint-down(sm) {
      font-size: sizeToRem(24);
    }
  }
  .regulations-desc {
    margin-bottom: sizeToRem(34);
  }
  .regulations-btn-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .regulations-btn {
    text-align: left;
    margin-bottom: sizeToRem(8);
    &.with-icon {
      &.left {
        padding-left: sizeToRem(32) !important;
      }
    }
    .ose-button-icon {
      width: sizeToRem(22);
      height: sizeToRem(22);
    }
  }
}
