.pagination {
  justify-content: center;
  margin: 0;
  .ose-arrow-small {
    fill: $secondary;
  }
  a {
    position: relative;
    color: $secondary;
    border: 1px solid $border-primary;
    @include outline($primary, 0.25);
    &:hover {
      text-decoration: none;
      color: $secondary;
    }
  }
}
.navlink,
.pagination-link,
.pagination-break-link {
  cursor: pointer;
  overflow: hidden;
}
.navlink {
  display: block;
}
.pagination-nav {
  a {
    padding: 0 sizeToRem(10);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.pagination-link,
.pagination-break-link {
  display: block;
  padding: sizeToRem(10) sizeToRem(14);
  margin-left: -1px;
}

.pagination-nav:hover {
  a {
    background: $light;
  }
}

.pagination-nav-right a {
  border-radius: 0 $border-radius $border-radius 0;
  margin-left: -1px;
  svg {
    transform: rotate(180deg);
  }
}

.pagination-nav-left a {
  border-radius: $border-radius 0 0 $border-radius;
}

.pagination-nav.disabled {
  pointer-events: none;
  cursor: initial;
  svg {
    opacity: 0.5;
  }
}

.pagination-item {
  font-size: 1.125rem;
  line-height: 1;
  &.active {
    background: $secondary;
    a {
      color: #fff;
    }
  }
  &:hover {
    background: $light;
    &.active {
      background: $secondary;
    }
  }
}
