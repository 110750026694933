.school-list-table {
  position: relative;
  padding: sizeToRem(16) 0 sizeToRem(5);
  @include media-breakpoint-down(md) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-collapse: separate;
    border-spacing: 0 sizeToRem(20);
  }
  .school-list-filter {
    padding: sizeToRem(12) 0 sizeToRem(40) 0;
    h5 {
      align-self: center;
    }
  }
  thead {
    color: $secondary;
    .school-table-headers {
      border-bottom: 1px solid $secondary;
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
    span.school-table-headers-text {
      position: relative;
      padding-right: sizeToRem(20);
      white-space: nowrap;
      font-family: $font-fam-headers;
      text-transform: none;
    }
    .school-table-sort-icon {
      position: absolute;
      right: 0;
      display: inline-block;
      width: sizeToRem(20);
      height: sizeToRem(20);
      img {
        transition: transform 0.15s ease;
        display: none;
      }
      &.asc {
        bottom: sizeToRem(4);
        img {
          display: inline-block;
        }
      }
      &.desc {
        bottom: sizeToRem(5);
        img {
          display: inline-block;
          transform: rotateX(180deg);
        }
      }
    }
    th {
      border: none;
    }
  }
  tbody {
    border-bottom: 1px solid $secondary;
    @include media-breakpoint-down(md) {
      border-bottom: none;
    }
  }
  tbody tr {
    @include media-breakpoint-down(md) {
      border: 1px solid $muted-lighter;
      display: block;
      border-radius: $border-radius;
      padding: sizeToRem(15) 0 sizeToRem(18);
    }
    &.school-list-row.expanded {
      @include media-breakpoint-down(md) {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: none;
        padding-bottom: 0;
      }
    }
    &.school-list-auth {
      @include media-breakpoint-down(md) {
        border-top: none;
        padding-top: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
    &.stripe {
      background-color: $light-secondary;
    }
  }
  th,
  td.school-list-row {
    text-transform: uppercase;
  }
  td {
    border: none;
    &.school-list-status-col {
      min-width: sizeToRem(140);
    }
  }
  th,
  td {
    padding: sizeToRem(12) sizeToRem(8);
    @include media-breakpoint-down(md) {
      display: flex;
      width: 100%;
      border: none;
      flex-direction: column;
      font-size: sizeToRem(16);
      line-height: sizeToRem(21);
      padding: sizeToRem(10) sizeToRem(23);
    }
  }
}

.school-list-table-row-header {
  font-weight: 400;
  display: none;
  @include media-breakpoint-down(md) {
    display: inline;
    color: $dark;
    font-weight: 700;
  }
}

.school-list-filter-table {
  border-spacing: 0;
  padding: 0;
  margin-bottom: 0;
}

.school-verify-button,
.school-list-button,
.school-list-auth-input input {
  width: 100%;
  @include media-breakpoint-up(sm) {
    width: sizeToRem(500);
    margin-right: auto;
  }
  @include media-breakpoint-up(md) {
    width: auto;
    min-width: sizeToRem(280);
  }
}

.school-verify-button,
.school-list-button {
  display: block;
  padding-left: sizeToRem(38);
  padding-right: sizeToRem(38);
  box-shadow: 0 sizeToRem(3) sizeToRem(6) rgba($secondary, 0.26);
  @include media-breakpoint-up(lg) {
    box-shadow: none;
    min-width: sizeToRem(190);
    padding-left: sizeToRem(10);
    padding-right: sizeToRem(10);
  }
}

.school-list-button {
  font-size: sizeToRem(16);
  letter-spacing: 0;
  min-width: sizeToRem(140);
  @include media-breakpoint-down(md) {
    margin-top: sizeToRem(15);
  }
}

.school-list-mleg {
  .school-list-button {
    background-color: $mleg-secondary;
    border-color: $mleg-secondary;
    color: #fff;
    &:hover,
    &:focus {
      background-color: $mleg-primary;
      border-color: $mleg-primary;
    }
    @include outline($mleg-primary, 0.7);
  }
  .school-verify-button {
    background-color: $mleg-primary;
    border-color: $mleg-primary;
    color: #fff;
    &:hover,
    &:focus {
      background-color: $mleg-dark;
      border-color: $mleg-dark;
    }
    @include outline($mleg-dark, 0.6);
  }
}

.school-verify-button {
  margin-top: sizeToRem(4);
}

.school-list-auth .form-group {
  margin-bottom: 0;
}
.school-list-auth-title {
  font-weight: 700;
}

.school-list-auth-input {
  input {
    border: 1px solid $secondary;
    color: $secondary;
    @include media-breakpoint-up(md) {
      width: 100%;
    }
    &.focus {
      color: $secondary;
    }
  }
}

.school-list-verify-info {
  margin-top: sizeToRem(30);
  margin-bottom: sizeToRem(15);
  font-size: sizeToRem(18);
  line-height: sizeToRem(28);
  text-transform: none !important;
  @include media-breakpoint-down(md) {
    margin-bottom: sizeToRem(5);
  }
}

.school-list-ose-banner-content-wrapper,
.school-list-mleg-banner-content-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  @include media-breakpoint-up(lg) {
    justify-content: flex-start;
  }
}

.school-list-ose-banner-title,
.school-list-mleg-banner-title {
  position: relative;
  min-height: sizeToRem(270);
  display: flex;
  align-items: center;
  color: white;
  padding: 0 sizeToRem(15);
  @include media-breakpoint-up(lg) {
    min-height: sizeToRem(449);
  }
}

.school-list-ose-banner-title h1 {
  position: relative;
  margin: 0 auto;
  text-align: center;
  max-width: sizeToRem(400);
  font-size: sizeToRem(36);
  @include media-breakpoint-up(sm) {
    font-size: sizeToRem(56);
  }
  @include media-breakpoint-up(lg) {
    margin: 0;
    text-align: left;
  }
  @include media-breakpoint-up(xl) {
    max-width: sizeToRem(581);
    font-size: sizeToRem(80);
  }
}

.school-list-mleg-banner-title h1 {
  position: relative;
  margin: 0 auto;
  text-align: center;
  font-size: sizeToRem(36);
  max-width: sizeToRem(775);
  @include media-breakpoint-up(sm) {
    font-size: sizeToRem(56);
  }
  @include media-breakpoint-up(lg) {
    margin: 0;
    text-align: left;
  }
  @include media-breakpoint-up(xl) {
    font-size: sizeToRem(80);
  }
}

.school-list-ose-banner-title-icon {
  height: sizeToRem(92);
  width: sizeToRem(194);
  display: block;
  margin: 0 sizeToRem(100) 0 auto;
}

.school-list-mleg-banner-title-icon {
  height: sizeToRem(126);
  width: sizeToRem(100);
  display: block;
  margin: 0 sizeToRem(60) 0 auto;
}

.school-list-ose-banner-title-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: sizeToRem(1045);

  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 360 177'%3E%3ClinearGradient id='prefix__a' gradientUnits='userSpaceOnUse' x1='-34.979' y1='87.713' x2='352.021' y2='88.713'%3E%3Cstop offset='.028' stop-color='%232737f5'/%3E%3Cstop offset='1' stop-color='%2311053b'/%3E%3C/linearGradient%3E%3Cpath d='M0 146.3V0h360v146.3a543.455 543.455 0 01-360 0z' fill='url(%23prefix__a)'/%3E%3C/svg%3E");
  @include media-breakpoint-up(lg) {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1045.001' height='449.003'%3E%3Cdefs%3E%3ClinearGradient id='prefix__a' x1='-.039' y1='-.087' x2='.823' y2='.918' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%232737f5'/%3E%3Cstop offset='1' stop-color='%2311053b'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cpath d='M2393 10528h-645v-449h1040.652a394.213 394.213 0 01-3.576 137.2 388.638 388.638 0 01-58.682 139.634 391.221 391.221 0 01-171.588 141.481 387.3 387.3 0 01-111.93 28.671A395.783 395.783 0 012403 10528c-3.3 0-6.661-.043-10-.127v.127z' transform='translate(-1747.998 -10078.999)' fill='url(%23prefix__a)'/%3E%3C/svg%3E");
    background-position: right;
    width: 60%;
  }
}

.school-list-mleg-banner-title-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: sizeToRem(1248);
  height: 100%;
  background-size: cover;
  background-position: bottom;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 360 177'%3E%3ClinearGradient id='prefix__a' gradientUnits='userSpaceOnUse' x1='0' y1='88.5' x2='360' y2='88.5'%3E%3Cstop offset='0' stop-color='%23328272'/%3E%3Cstop offset='1' stop-color='%23105f4f'/%3E%3C/linearGradient%3E%3Cpath d='M0 146.3V0h360v146.3a543.455 543.455 0 01-360 0z' fill='url(%23prefix__a)'/%3E%3C/svg%3E");
  @include media-breakpoint-up(lg) {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1248' height='449.003'%3E%3Cdefs%3E%3ClinearGradient id='prefix__a' x1='-.039' y1='-.087' x2='.823' y2='.918' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23328272'/%3E%3Cstop offset='1' stop-color='%23105f4f'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cpath data-name='Subtraction 3' d='M2393 10528h-848v-449h1243.652a394.213 394.213 0 01-3.576 137.2 388.619 388.619 0 01-58.682 139.634 391.2 391.2 0 01-171.588 141.481 387.369 387.369 0 01-111.93 28.672A395.909 395.909 0 012403 10528c-3.292 0-6.656-.043-10-.127v.127z' transform='translate(-1544.999 -10078.999)' fill='url(%23prefix__a)'/%3E%3C/svg%3E");
    background-position: right;
    width: 75%;
  }
}

.school-list-ose-banner-description,
.school-list-mleg-banner-description {
  margin: sizeToRem(92) auto sizeToRem(58);
  p {
    max-width: sizeToRem(670);
    font-size: sizeToRem(18);
    line-height: sizeToRem(28);
  }
}

.banner-description-mleg {

  p {
    max-width: 50rem;
    a {
      color: $mochrona-primary;
    }
  }

}

.school-list-footer {
  margin-bottom: sizeToRem(130);
  @include media-breakpoint-down(lg) {
    margin-bottom: sizeToRem(80);
  }
  button.school-list-footer-btn {
    font-weight: 700;
    font-size: sizeToRem(18);
    height: sizeToRem(40);
    display: flex;
    align-items: center;
    color: $mleg-student-primary;
    background: none;
    border: none;
    text-align: left;
    svg {
      max-width: sizeToRem(20);
      margin-right: sizeToRem(18);
    }
  }
}
