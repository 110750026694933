//SECTION TWO
.oseInternet-section-two {
  padding: sizeToRem(150) 0 sizeToRem(100);
  @include media-breakpoint-down(md) {
    padding-bottom: 0;
  }
  .oseInternet-section-two-title {
    margin-bottom: sizeToRem(80);
    max-width: sizeToRem(500);
  }
  .oseInternet-section-two-tile-wrapper {
    margin-bottom: sizeToRem(80);
    @include media-breakpoint-down(xs) {
      margin-bottom: sizeToRem(20);
    }
  }
  .oseInternet-section-two-tile {
    position: relative;
    font-size: sizeToRem(18);
    font-weight: 600;
    height: 100%;
    min-height: sizeToRem(270);
    padding: sizeToRem(60) sizeToRem(20);
    border: 1px solid $muted-lighter;
    border-radius: sizeToRem(20);
    @include media-breakpoint-down(xs) {
      border: none;
      min-height: unset;
    }
    .oseInternet-section-two-icon {
      position: absolute;
      top: sizeToRem(-40);
      left: sizeToRem(20);
      width: sizeToRem(80);
      height: sizeToRem(80);
      background-color: white;
    }
    img {
      width: 100%;
    }
  }
}

//SECTION THREE
.oseInternet-section-three-wrapper {
  padding-top: sizeToRem(154);
  overflow: hidden;
  @include media-breakpoint-down(md) {
    padding-top: 23vw;
  }
}
.oseInternet-section-three {
  background-color: $light;
  .content-wrapper {
    position: relative;
    .oseInternet-section-three-decor {
      position: absolute;
      top: sizeToRem(-154);
      left: 50%;
      transform: translateX(-50%);
      line-height: 1;
      color: $light;
      font-weight: bold;
      font-family: $font-fam-headers;
      @include media-breakpoint-down(md) {
        top: -19vw;
      }
      span {
        display: inline-block;
        font-size: sizeToRem(180);
        line-height: 1;
        @include media-breakpoint-down(md) {
          font-size: 23vw;
        }
      }
    }
  }

  .oseIntenet-section-three-inner {
    position: relative;
    padding-top: sizeToRem(150);
    padding-bottom: sizeToRem(250);
    @include media-breakpoint-down(sm) {
      padding-bottom: sizeToRem(100);
    }
  }
  .oseInternet-section-three-title,
  .oseInternet-section-three-subtitle,
  .oseInternet-section-three-desc {
    position: relative;
    z-index: 2;
  }

  .oseInternet-section-three-title {
    margin-bottom: sizeToRem(80);
    color: $primary;
  }

  .oseInternet-section-three-subtitle {
    max-width: sizeToRem(340);
    margin-bottom: sizeToRem(30);
    word-wrap: break-word;
  }

  .oseInternet-section-three-desc {
    max-width: sizeToRem(470);
  }

  .oseInternet-section-three-icon {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    img {
      height: sizeToRem(200);
      max-width: 100%;
      @include media-breakpoint-down(sm) {
        margin-top: sizeToRem(50);
      }
    }
  }

  .oseInternet-section-three-circle {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: white;
      width: sizeToRem(600);
      height: sizeToRem(600);
      border-radius: 50%;
    }
  }
}

.oseInternet-section-three-en {
  .content-wrapper {
    position: relative;
    .oseInternet-section-three-decor {
      span {
        font-size: sizeToRem(128);

        @include media-breakpoint-down(md) {
          font-size: 16vw;
        }
      }
    }
  }
}

//SECTION FOUR
.oseInternet-section-four {
  overflow: hidden;
  .oseIntenet-section-four-inner {
    position: relative;
    padding-top: sizeToRem(150);
    padding-bottom: sizeToRem(250);
    @include media-breakpoint-down(sm) {
      padding-bottom: sizeToRem(100);
    }
  }
  .oseInternet-section-four-title,
  .oseInternet-section-four-subtitle,
  .oseInternet-section-four-desc {
    position: relative;
    z-index: 2;
  }

  .oseInternet-section-four-content-wrapper {
    margin: 0;
    width: 100%;
    @include media-breakpoint-up(md) {
      width: initial;
      margin: 0 0 0 auto;
    }
    @include media-breakpoint-up(lg) {
      width: initial;
      margin: 0 sizeToRem(100) 0 auto;
    }
  }

  .oseInternet-section-four-title {
    margin-bottom: sizeToRem(80);
    color: $primary;
    @include media-breakpoint-down(sm) {
      margin-bottom: sizeToRem(34);
    }
  }

  .oseInternet-section-four-subtitle {
    max-width: sizeToRem(262);
    font-size: sizeToRem(26);
    margin-bottom: sizeToRem(30);
    word-wrap: break-word;
    line-height: sizeToRem(36);
    @include media-breakpoint-up(md) {
      font-size: sizeToRem(36);
      max-width: sizeToRem(390);
    }
  }

  .oseInternet-section-four-desc {
    max-width: sizeToRem(340);
    @include media-breakpoint-up(md) {
      max-width: sizeToRem(408);
    }
  }

  .oseInternet-section-four-circle {
    position: relative;
    display: flex;
    margin-bottom: sizeToRem(32);
    min-height: sizeToRem(370);
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: sizeToRem(-50);
      transform: translate(0, -50%);
      background-color: $light;
      width: sizeToRem(370);
      height: sizeToRem(370);
      border-radius: 50%;
      @include media-breakpoint-up(md) {
        top: 0;
        transform: translate(10%, 0);
        width: sizeToRem(600);
        height: sizeToRem(600);
      }
      @include media-breakpoint-up(lg) {
        right: 0;
        transform: translate(0, 0);
      }
    }
  }

  .oseInternet-section-four-icon-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    &:not(:last-of-type) {
      margin-bottom: sizeToRem(70);
    }
    p {
      display: block;
      font-family: $font-fam-headers;
      font-size: sizeToRem(18);
      text-align: center;
      font-weight: 700;
      max-width: sizeToRem(182);
    }
  }

  .oseInternet-section-four-icon {
    width: sizeToRem(170);
    height: sizeToRem(170);
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $light;
    border-radius: 100%;
    margin-bottom: sizeToRem(20);

    img {
      max-height: sizeToRem(85);
    }
  }
}

.oseInternet-section-four-en {
  .oseInternet-section-four-circle::after {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}

// SECTION FIVE
.oseInternet-section-five {
  background-color: $light;
  .oseIntenet-section-five-inner {
    position: relative;
    padding-top: sizeToRem(150);
    padding-bottom: sizeToRem(250);
    @include media-breakpoint-down(sm) {
      padding-bottom: sizeToRem(100);
    }
  }

  .oseInternet-section-five-title,
  .oseInternet-section-five-subtitle,
  .oseInternet-section-five-desc,
  .oseInternet-section-five-cta {
    position: relative;
    z-index: 2;
  }

  .oseInternet-section-five-title {
    margin-bottom: sizeToRem(80);
    color: $primary;
  }

  .oseInternet-section-five-subtitle {
    max-width: sizeToRem(340);
    margin-bottom: sizeToRem(30);
    word-wrap: break-word;
  }

  .oseInternet-section-five-desc {
    max-width: sizeToRem(362);
    margin-bottom: sizeToRem(80);
    p {
      margin-bottom: sizeToRem(30);
    }
    @include media-breakpoint-up(md) {
      max-width: sizeToRem(470);
      margin-bottom: 0;
    }
  }

  .oseInternet-section-five-cta {
    width: 100%;
    max-width: sizeToRem(470);
    margin: sizeToRem(50) auto 0;
    display: block;
    @include media-breakpoint-up(md) {
      margin: 0;
      max-width: sizeToRem(192);
    }
  }

  .oseInternet-section-five-circle {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: 30%;
      left: 30%;
      transform: translate(-50%, -50%);
      background-color: white;
      width: sizeToRem(370);
      height: sizeToRem(370);
      border-radius: 50%;
      @include media-breakpoint-up(md) {
        width: sizeToRem(600);
        height: sizeToRem(600);
        top: 50%;
        left: 50%;
      }
    }
  }

  .oseInternet-section-five-card-wrapper .home-pulsing-circle {
    border-color: $primary;
    top: sizeToRem(-50);
    right: 0;
    left: initial;
    width: sizeToRem(150);
    height: sizeToRem(150);
    border-width: sizeToRem(3);
    opacity: 0.1;
    @include media-breakpoint-down(md) {
      display: block !important;
      animation: none;
    }
  }

  .oseInternet-section-five-card-wrapper .home-pulsing-circles-wrapper {
    opacity: 0.1;
    bottom: sizeToRem(-100);
    left: sizeToRem(-100);
    right: initial;
    .inner-circle {
      border-width: 1px;
      border-color: $primary;
      width: sizeToRem(215);
      height: sizeToRem(215);
      &:after {
        border-width: 1px;
        width: sizeToRem(200);
        height: sizeToRem(200);
      }
      &:before {
        border-width: 1px;
        bottom: sizeToRem(57);
        width: sizeToRem(86);
        height: sizeToRem(86);
      }
    }
    @include media-breakpoint-down(md) {
      display: block !important;
      animation: none;
      bottom: 0;
    }
  }

  .oseInternet-section-five-card-wrapper {
    position: relative;
    max-width: sizeToRem(470);
    margin: 0 auto;
    @include media-breakpoint-up(md) {
      margin: sizeToRem(120) 0 auto;
    }
    @include media-breakpoint-up(lg) {
      margin: 0 sizeToRem(50) 0 auto;
    }
  }

  .oseInternet-section-five-card-container {
    position: relative;
    z-index: 2;
    border-radius: sizeToRem(20);
    border: none;
    box-shadow: 0 sizeToRem(10) sizeToRem(30) rgba($primary, 0.15);
  }

  .oseInternet-section-five-card-body {
    padding: sizeToRem(48) sizeToRem(24);
    .card-title {
      font-size: sizeToRem(26);
      font-family: $font-fam-headers;
      letter-spacing: sizeToRem(0.47);
      max-width: sizeToRem(256);
      margin: 0 auto;
      text-align: center;
      p {
        margin: 0;
        font-weight: 500;
      }
      p:first-of-type {
        font-weight: 700;
        margin-bottom: sizeToRem(4);
      }
    }
    .card-text {
      font-size: sizeToRem(56);
      font-weight: 700;
      font-family: $font-fam-headers;
      text-align: center;
      color: $primary;
    }
    .list-group-item {
      padding-left: 0;
      padding-right: 0;
      &:first-of-type {
        border-width: 1px 0 1px !important;
      }
    }
  }

  .oseInternet-section-five-card-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      max-width: sizeToRem(269);
    }
    span:last-of-type {
      font-weight: 700;
    }
    svg {
      width: sizeToRem(15);
      color: $primary;
      margin: 0 sizeToRem(26);
    }
  }
}

.oseInternet-section-five-en {
  overflow: hidden;

  .oseInternet-section-five-title {
    margin-bottom: sizeToRem(50);

    @include media-breakpoint-down(sm) {
      margin-bottom: 0;
    }
  }
  .oseInternet-section-five-circle {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .oseInternet-section-five-tabs-wrapper {
    z-index: 9;
    position: relative;
  }

  .oseInternet-section-five-tabs {
    border-bottom: 0;
    display: flex;
    //gap: sizeToRem(30);
    z-index: 100;
  }

  .oseInternet-section-five-tabs-container {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @include media-breakpoint-down(xs) {
      flex-wrap: wrap;
      row-gap: 10px;
    }
  }

  .oseInternet-section-five-tab-content {
    display: flex;
    width: 100%;
  }

  .oseInternet-section-five-tab {
    background-color: $white;
    position: relative;
    z-index: 10;
    border-radius: 50%;
    width: sizeToRem(100);
    height: sizeToRem(100);
    display: flex;
    align-items: center;
    justify-content: center;
    border: sizeToRem(2) solid #2737f5;
    transition: all 300ms ease-in-out;

    &.active.no-after {
      background-color: rgb(10 27 223 / 8%);
    }
    &:not(.no-after):after {
      opacity: 0;
      content: '';
      transition: opacity 200ms ease-in-out;
    }
    &.active:not(.no-after):after {
      transition: opacity 200ms ease-in-out;
      opacity: 1;
      height: sizeToRem(15);
      width: sizeToRem(18);
      position: absolute;
      content: '';
      background: white;
      border: sizeToRem(2) solid #2737f5;
      border-top-width: 0;
      border-bottom-width: 0;
      bottom: sizeToRem(-14);
      z-index: 999;
    }

    @include media-breakpoint-down(xs) {
      &.active:not(.no-after):after {
        opacity: 0;
        content: '';
        transition: opacity 200ms ease-in-out;
      }
    }
  }

  .oseInternet-section-five-tab-pane {
    border: sizeToRem(2) solid #2737f5;
    padding: sizeToRem(30);
    border-radius: sizeToRem(15);
    position: relative;
    transform: translateY(10px);
    background-color: $white;
    z-index: 5;
    width: 100%;
  }

  .oseInternet-section-five-tab-pane.fade {
    transition: opacity 300ms ease-in;
  }
}

.ose-internet-banner-en {
  display: block;
  height: 100% !important;

  .ose-internet-banner-en-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .oseTopSection-btn-primary {
      margin: 0;
    }

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }

  .ose-internet-banner-en-title {
    font-size: sizeToRem(56);
    font-weight: 700;
    color: $light;
  }
}

.oseInternet-section-two-en {
  .oseInternet-section-two-title {
    max-width: 100%;
  }
}

//SECTION SIX
.oseInternet-section-six-decor {
  color: $primary;
  font-size: sizeToRem(18);
  font-weight: 500;
}

.oseInternet-section-six-wrapper {
  padding: sizeToRem(200) 0;
  @include media-breakpoint-down(md) {
    padding: sizeToRem(100) 0;
  }
  .oseInternet-section-six-col {
    &:not(:last-child) {
      @include media-breakpoint-down(md) {
        margin-bottom: sizeToRem(50);
      }
    }
  }
  .oseInternet-section-six-subtitle {
    max-width: sizeToRem(360);
    margin-bottom: sizeToRem(30);
  }
  .oseInternet-section-six-desc {
    max-width: sizeToRem(380);
    @include media-breakpoint-down(md) {
      max-width: sizeToRem(500);
    }
    ul {
      margin: 0;
      padding-left: sizeToRem(16);
      li {
        margin-bottom: sizeToRem(16);
      }
    }
  }
}
