.dynamic-wrapper {
  a {
    color: $primary;
    word-wrap: break-word;
    font-weight: 600;
  }
  h2 {
    font-size: sizeToRem(18);
    margin-top: sizeToRem(30);
    margin-bottom: sizeToRem(16);
    color: $primary;
  }
  img.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  img.alignright {
    display: block;
    margin-left: auto;
  }
  img.alignleft {
    display: block;
    margin-right: auto;
  }
}
