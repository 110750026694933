.navigation-burger-btn {
  border: none;
  background-color: transparent;
  display: none;
  padding: 0.5rem sizeToRem(15);
  @include media-breakpoint-down(lg) {
    display: block;
  }
}

//humburger animations
.navigation-burger {
  position: relative;
  display: inline-block;
  width: 1.25rem;
  height: 0.75rem;
  &.active {
    .navigation-burger-line {
      transform: translate3d(0, 0.3125rem, 0) rotate(45deg);
      &::before {
        transform: rotate(-45deg) translate3d(0.17857rem, -0.1875rem, 0);
        opacity: 0;
      }
      &::after {
        transform: translate3d(0, -0.625rem, 0) rotate(-90deg);
      }
    }
  }
  .navigation-burger-line {
    position: absolute;
    width: 1.25rem;
    height: 0.125rem;
    transition: transform 0.15s ease;
    border-radius: 0.25rem;
    background-color: $dark;
    &::after,
    &::before {
      content: '';
      position: absolute;
      width: 1.25rem;
      height: 0.125rem;
      left: 0;
      transition: transform 0.15s ease;
      border-radius: 0.25rem;
      background-color: $dark;
    }
    &::after {
      bottom: -0.3125rem;
      top: 0.625rem;
    }
    &::before {
      transition: transform 0.15s ease;
      top: 0.3125rem;
    }
  }
}
