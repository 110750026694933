/* NEWS LIST BLUE SECTION */

.newslist-headline {
  background-color: $secondary;
  color: #fff;
  padding: 3rem $page-padding-sm;
  margin-bottom: 2.5rem;
  overflow: hidden;
  @include media-breakpoint-up(md) {
    padding-left: $page-padding-md;
    padding-right: $page-padding-md;
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: 9.5rem;
    padding-left: $page-padding-lg;
    padding-right: $page-padding-lg;
  }
  @include media-breakpoint-up(xl) {
    padding-top: 7.5rem;
    padding-bottom: 10rem;
    padding-left: calc((100% - #{$content-max-width}) / 2 + 15px);
    padding-right: 0;
  }
  a {
    color: #fff;
    display: block;
    &:hover {
      color: #fff;
    }
  }
}

.newslist-headline-title {
  margin-bottom: 6.25rem;
  @include media-breakpoint-down(sm) {
    margin-bottom: 2rem;
  }
  h1 {
    margin-bottom: 0;
    @include media-breakpoint-down(sm) {
      line-height: 1.75;
      font-size: 2.25rem;
    }
  }
}

.newslist-headline-image {
  @extend .lazyImgOseMain;
  @include media-breakpoint-down(md) {
    img {
      border-radius: $border-radius-img;
    }
  }
  @include media-breakpoint-down(sm) {
    padding-top: 63%;
  }
}

/* NEWS LIST ARTICLES */

.newslist-articles-header {
  margin-bottom: 6rem;
  @include media-breakpoint-down(sm) {
    margin-bottom: 4rem;
  }
  h2 {
    margin-bottom: 0;
    line-height: 1.75;
    @include media-breakpoint-down(sm) {
      font-size: 2.25rem;
    }
  }
}

.newslist-articles-list {
  @include media-breakpoint-up(md) {
    border-bottom: 1px solid $border-secondary;
  }
}

.newslist-articles-item {
  @extend .tile-link-hover;
  display: flex;
  margin-bottom: 2rem;
  border-bottom: 1px solid $border-secondary;
  flex-direction: column;
  color: initial;
  @include media-breakpoint-up(md) {
    margin-bottom: 4rem;
    border-bottom: none;
  }
}

.newslist-articles-image-wrapper {
  margin-bottom: 1rem;
}
.newslist-articles-desc-wrapper {
  padding-top: 1rem;
  padding-bottom: 1rem;
  @include media-breakpoint-down(sm) {
    padding: 0;
  }
  h3 {
    word-wrap: break-word;
  }
}

.newslist-articles-image {
  @extend .lazyImgOseMain;
  img {
    border-radius: $border-radius-img;
  }
}

.news-pagination {
  margin: 30px 0;
}
