$footer-height: sizeToRem(67);

.footer-wrapper {
  background-color: $secondary;
  min-width: sizeToRem(264);
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  .nav-item {
    border: none;
  }
}

.footer-links {
  min-height: sizeToRem(431);
  @include media-breakpoint-up(xl) {
    min-height: sizeToRem(251);
  }
}

.footer-link {
  padding: 0 0 sizeToRem(6) 0 !important;
  display: inline-block;
  &:focus,
  &:hover {
    text-decoration: underline;
  }
  @include outline($muted-lighter, 1);
}

.footer-top-section {
  padding: sizeToRem(40) 0;
  color: white;
  @include media-breakpoint-down(lg) {
    padding-bottom: 0;
  }
  a {
    color: white;
  }
}

.footer-bottom-section {
  display: flex;
  align-items: center;
  min-height: $footer-height;
  border-top: 1px solid $light;
  background-color: white;
  padding: sizeToRem(10) 0;
  &.ose {
    .footer-logo-wrapper {
      @include media-breakpoint-down(lg) {
        margin-top: sizeToRem(16);
      }
    }
  }
  .footer-bottom-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    @include media-breakpoint-down(lg) {
      flex-wrap: wrap;
    }
  }
  .footer-logo img {
    min-height: sizeToRem(46);
  }
}

.footer-logo {
  display: inline-block;
  width: sizeToRem(100);

  img {
    max-width: 100%;
    min-height: sizeToRem(36);
  }
  &.ose {
    height: sizeToRem(40);
    @include media-breakpoint-down(lg) {
      width: sizeToRem(140);
      height: sizeToRem(56);
    }
  }
  &.kprm {
    width: sizeToRem(54);
  }
  &.mojeose {
    width: sizeToRem(80);
  }
  &.cyber {
    width: sizeToRem(100);
  }
}

.footer-logo-wrapper {
  display: flex;
  align-items: center;
  min-height: sizeToRem(36);
  @include media-breakpoint-down(lg) {
    width: 100%;
    justify-content: space-between;
  }
  .footer-logo {
    margin-left: sizeToRem(20);
    @include media-breakpoint-down(lg) {
      margin-left: 0;
    }
  }
}

//FOOTER ACCORDION STYLES

.footer-accordion {
  color: white;
  padding: sizeToRem(10) 0 sizeToRem(40);
  a {
    color: white;
  }
  .footer-accordion-body,
  .footer-accordion-toggle,
  .card {
    padding: sizeToRem(1);
    background-color: transparent;
  }
  .card {
    border: none;
  }
  .footer-accordion-toggle {
    position: relative;
    padding: sizeToRem(15) sizeToRem(40) sizeToRem(15) 0;
    color: white;
    text-align: left;
    border: none;
    border-bottom: sizeToRem(2) solid rgba(#fff, 0.5);
    @include outline($muted-lighter, 1);
    h4 {
      margin: 0;
    }
    svg {
      position: absolute;
      width: sizeToRem(30);
      height: sizeToRem(30);
      top: 50%;
      right: sizeToRem(14);
      transform: translateY(-50%) rotate(-90deg);
      transition: 0.3s ease;
    }
    &.open {
      svg {
        transform: translateY(-50%) rotate(90deg);
      }
    }
  }
}
