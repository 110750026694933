.school-filter-validation-tooltip {
  border-color: $danger;
  & > .arrow::before {
    border-top-color: $danger;
  }
}

.school-list-filter-title {
  font-size: sizeToRem(18);
}

.school-list-filter {
  h5 {
    align-self: flex-start;
  }
  .mb-no-counter {
    margin-bottom: 2rem;
  }
}

.school-list-filter-wrapper {
  padding: sizeToRem(30) sizeToRem(15);
  background: $light;
  border: 1px solid $secondary;
  border-radius: $border-radius;
}

.school-list-mleg {
  .school-list-filter-wrapper {
    background: $mleg-light;
    @include media-breakpoint-down(md) {
      border: none;
    }
  }
}

.menergyrecommend-section-five-form-step-two-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-radius: sizeToRem(10);
  color: $secondary !important;
  .form-control {
    border-radius: $border-radius;
    border: 1px solid $secondary;
    color: $secondary !important;
  }
  .input-helper-text {
    font-weight: 400;
    font-family: $font-fam-txt;
    text-transform: none;
  }

  input.is-invalid {
    background-image: none;
    padding: 0.375rem 0.55rem;
    &:focus {
      border-color: $danger !important;
    }
  }
  ::placeholder {
    color: $secondary !important;
  }
  .with-prepend {
    .form-control {
      order: 2;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
    .input-group-prepend {
      order: 1; // order of prepend is reversed to be able to style border-color on focus with sibling selector
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
      border: 1px solid $secondary;
      border-right: 0;
      background: #fff;
      .input-group-text {
        padding: 0 sizeToRem(16) 0 sizeToRem(16);
        background: none;
        border: none;
        @include media-breakpoint-down(md) {
          padding: 0 sizeToRem(12) 0 sizeToRem(12);
        }
      }
      img {
        height: 1rem;
        width: 1rem;
        margin: 0 auto;
      }
    }
  }
}

.menergyrecommend-section-five-form-step-two-list {
  .form-control:focus,
  .form-control.focus-visible {
    @include outline($form-active, 0.25);
    border-color: $form-active !important;
    & ~ .input-group-prepend {
      border-color: $form-active;
    }
    &.is-invalid {
      @include outline($form-error, 0.25);
    }
    &:not([data-focus-visible-added]) {
      border-color: $secondary !important;
    }
  }
}

.school-list-name-search .form-control {
  border-left-color: transparent;
}
