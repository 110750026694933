$modal-no-network-width: sizeToRem(1170);
$modal-no-network-text-width: sizeToRem(880);
$modal-no-network-icon-width: sizeToRem(450);
$modal-no-network-icon-height: sizeToRem(120);

.no-network-backdrop {
  &.show {
    opacity: 0.7;
  }
}

.no-network-modal {
  .no-network-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .no-network-close-btn {
      margin: sizeToRem(12);
    }
  }

  .no-network-icon {
    flex: 1;
    margin: sizeToRem(80) sizeToRem(20);
    max-width: $modal-no-network-icon-width;
    max-height: $modal-no-network-icon-height;
    @include media-breakpoint-up(md) {
      margin: sizeToRem(80) sizeToRem(70);
    }
  }

  h4,
  p {
    font-family: $font-fam-headers;
    line-height: $line-height-headers;
    font-weight: bold;
    color: $secondary;
  }

  h4 {
    font-size: sizeToRem(24);
    @include media-breakpoint-up(md) {
      font-size: sizeToRem(36);
    }
  }

  p {
    font-size: sizeToRem(16);
    @include media-breakpoint-up(md) {
      font-size: sizeToRem(18);
      max-width: $modal-no-network-text-width;
    }
  }

  //BOOTSTRAP STYLES OVERRIDE

  .modal-dialog {
    width: $modal-no-network-width;
    max-width: calc(100vw - #{sizeToRem(16)});
  }

  .modal-content {
    border: 0;
  }

  .modal-body {
    padding: 0 sizeToRem(20) sizeToRem(20);
    @include media-breakpoint-up(md) {
      padding: 0 sizeToRem(70) sizeToRem(40);
    }
  }
}
