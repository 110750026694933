// helper for content-wrapper classes in layout.scss

@mixin content-wrapper-base($sm-padding) {
  width: 100%;
  max-width: $content-max-width; //1170px + padding 15px
  margin: 0 auto;
  padding: 0 $sm-padding;
  @include media-breakpoint-up(md) {
    padding: 0 $page-padding-md;
  }
  @include media-breakpoint-up(lg) {
    padding: 0 $page-padding-lg;
  }
}

// helper for outline styling with focus-visible package

@mixin outline($color, $opacity, $size: $default-outline-size) {
  outline: none !important;

  &:focus {
    box-shadow: 0 0 0 $size rgba($color, $opacity) !important;
  }

  [data-js-focus-visible]
    &:focus:not([data-focus-visible-added]):not(.services-ose-tab-button--checked):not(.services-ose__plus-tab-button--checked):not(.navigation-btn-en) {
    box-shadow: none !important;
  }
}

// use with lazysizes to lock proper space for loading image

@mixin lazyImg($paddingTop, $width: 100%) {
  width: $width;
  padding-top: $paddingTop;
  position: relative;
  img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
