.faq-questions {
  .faq-questions-header {
    padding: sizeToRem(100) 0 sizeToRem(60);
    word-break: break-word;
    &:first-child {
      padding-top: 0;
    }
    & + .faq-scroll-item .faq-item {
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
      .faq-item-question {
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
      }
    }
  }
}

.faq-scroll-item {
  border-radius: 0;
}

.faq-item {
  border-color: $dark;
  overflow: visible !important;
  border-radius: 0;
  border-bottom: none;
  &.last {
    border-bottom: 1px solid $dark !important;
    border-bottom-left-radius: $border-radius !important;
    border-bottom-right-radius: $border-radius !important;
    .faq-item-question {
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
  }
  .faq-item-question {
    position: relative;
    border: none;
    text-align: left;
    font-weight: 500;
    background-color: $light;
    &:hover,
    &:focus {
      background-color: $light-hover;
    }
    padding: sizeToRem(24) sizeToRem(46) sizeToRem(24) sizeToRem(24);
    h4 {
      margin: 0;
    }
    .faq-item-arrow {
      position: absolute;
      width: sizeToRem(30);
      height: sizeToRem(30);
      top: 50%;
      right: sizeToRem(14);
      transform: translateY(-50%);
      transition: 0.3s ease;
      &.open {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }
  .faq-item-answer a {
    color: $primary;
    font-weight: 600;
  }
}
