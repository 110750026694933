.ose-campaign-second-section {
  padding-top: sizeToRem(160);
  padding-bottom: sizeToRem(180);
  @include media-breakpoint-down(sm) {
    padding-bottom: sizeToRem(100);
  }
  .ose-campaign-title {
    margin-bottom: sizeToRem(40);
  }
  .ose-campaign-desc {
    margin-bottom: sizeToRem(80);
    @include media-breakpoint-down(sm) {
      margin-bottom: sizeToRem(52);
    }
  }
}

.ose-campaign-third-section {
  padding-top: sizeToRem(180);
  padding-bottom: sizeToRem(40);
  .ose-campaign-title,
  .ose-campaign-desc {
    max-width: sizeToRem(458);
    @include media-breakpoint-down(sm) {
      max-width: initial;
    }
  }
  .ose-campaign-title {
    margin-bottom: sizeToRem(30);
  }
  .ose-campaign-desc {
    margin-bottom: sizeToRem(40);
  }
  .ose-campaign-button {
    min-width: sizeToRem(192);
    @include media-breakpoint-down(sm) {
      margin-bottom: sizeToRem(80);
    }
  }
  .ose-campaign-circle-image {
    width: 100%;
    img {
      width: 100%;
      border-radius: 50%;
      box-shadow: 0px 0px 30px rgba($mochrona-primary, 0.08);
      margin-bottom: sizeToRem(160);
    }
  }
  .ose-campaign-video1,
  .ose-campaign-video2 {
    margin-bottom: sizeToRem(160);
    @include media-breakpoint-down(sm) {
      margin-bottom: sizeToRem(80);
    }
  }
}
