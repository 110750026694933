.campaign-qa-row {
  margin-bottom: sizeToRem(80);
}
.campaign-qa-title {
  margin-bottom: sizeToRem(30);
  @include media-breakpoint-down(sm) {
    font-size: sizeToRem(26);
  }
}
.campaign-qa-video {
  border-radius: sizeToRem(25);
  overflow: hidden;
  @include media-breakpoint-down(md) {
    margin-bottom: sizeToRem(30);
  }
}
.campaign-qa-podcast iframe {
  width: 100%;
  max-width: sizeToRem(768);
  display: block;
  @include media-breakpoint-down(md) {
    margin: 0 auto sizeToRem(30) auto;
  }
}
