.form-mleg {
  .form-header-wrapper {
    background-color: $mleg-light;
  }
  .form-section-wrapper {
    background-color: $mleg-light;
  }
}

.register-mleg-maintainer {
  @include media-breakpoint-down(sm) {
    position: relative;
    margin-bottom: sizeToRem(60);
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 1px solid $secondary;
      border-radius: $border-radius;
    }
  }
}

.register-mleg-maintainer-number {
  position: relative;
  top: sizeToRem(-15);
  left: sizeToRem(20);
  background-color: $mleg-light;
  padding: 0 sizeToRem(8);
  z-index: 2;
  font-weight: bold;
  margin-bottom: 0;
}

.register-mleg-maintainer-delete {
  min-height: sizeToRem(48);

  .custom-close-btn {
    @include media-breakpoint-down(sm) {
      border: 1px solid $danger;
      border-radius: $border-radius;
      width: sizeToRem(50);
      height: sizeToRem(50);
      margin-bottom: sizeToRem(24);
      &.disabled {
        border-color: $muted;
      }
    }
  }
}

.register-mleg-consent-accordion {
  .card-header {
    background-color: rgba($mleg-accent, 0.3);
  }
}

.register-mleg-attachments-constraints {
  font-weight: bold;
  color: $mleg-primary;
  margin-top: sizeToRem(32);
}

.register-mleg-file-download {
  cursor: pointer;
  color: $primary;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
}

// mLeg Drag 'n' Drop

.register-mleg-dropzone-files {
  max-width: sizeToRem(580);
  ul {
    padding: 0;
  }
}

.register-mleg-dropzone-file-list {
  list-style: none;

  li {
    display: flex;
    align-items: center;
    margin-bottom: sizeToRem(11);
    border-top: 1px solid $border-primary;
    padding-top: sizeToRem(11);

    &:first-child {
      border: 0;
      padding-top: 0;
    }
  }
}

.register-mleg-dropzone-file-title {
  margin-right: 1rem;
}

.register-mleg-dropzone {
  position: relative;
  height: sizeToRem(170);
  background-color: #fff;
  border: 1px dashed $secondary;
  border-radius: sizeToRem(10);
  background-image: url('../images/icons/upload.svg');
  background-repeat: no-repeat;
  background-position: center sizeToRem(16);
  background-size: 20%;
  cursor: pointer;
  margin-bottom: sizeToRem(32);

  @include media-breakpoint-up(md) {
    margin-bottom: 0;
  }

  &:hover {
    background-color: $mleg-accent-opacity;
  }

  &.disabled {
    opacity: 0.5;
    cursor: unset;

    &:hover {
      background-color: #fff;
    }
  }

  &.error {
    border: 2px solid $form-error;
  }

  .register-mleg-dropzone-instruction {
    position: absolute;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    bottom: sizeToRem(10);
    font-size: sizeToRem(15);
  }
}

.register-mleg-dropzone-remove-file {
  margin-left: auto;
}

.register-mleg-dropzone-error {
  color: $form-error;
  font-weight: bold;
  display: flex;
  align-items: center;

  img {
    width: sizeToRem(16);
    height: sizeToRem(16);
    margin-right: sizeToRem(16);
  }
}
