$mobile-menu-width: sizeToRem(360);
$mobile-menu-transition-length: 0.3s;

//DISABLE SCROLL FOR MOBILE MENU
body.menu-open {
  overflow: hidden;
}

nav {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: sizeToRem(160);
    background-color: white;
    z-index: 997;
    opacity: 0;
    visibility: hidden;
    transition: $navigation-dropdown-transition-time opacity ease,
      0s transform ease-in-out $navigation-dropdown-transition-time, 0s visibility $navigation-dropdown-transition-time;
  }

  &.show {
    &::after {
      opacity: 1;
      visibility: visible;
      transform: translateY(100%);
      transition: 0s visibility, 0s transform, $navigation-dropdown-transition-time opacity ease;
    }
  }
}

.navigation {
  background-color: white;
  position: relative;
  height: $nav-height-lg;
  display: flex;
  align-items: center;
  z-index: 998;
  @include media-breakpoint-down(lg) {
    height: $nav-height-sm;
    position: fixed;
    top: 0;
    left: 0;
  }
}

.navigation-content {
  display: flex;
  align-items: center;
  flex-grow: 1;
  overflow: auto;
  padding: sizeToRem(4) 0;
  min-height: sizeToRem(52);
  @include media-breakpoint-down(lg) {
    position: fixed;
    width: $mobile-menu-width;
    bottom: 0;
    top: $nav-height-sm;
    background-color: white;
    left: -#{$mobile-menu-width};
    min-height: initial;
    flex-direction: column;
    visibility: hidden;
    padding: 0;
    transition: visibility 0s $mobile-menu-transition-length, transform $mobile-menu-transition-length ease;
    overflow: auto;
    &.active {
      visibility: visible;
      transform: translateX(100%);
      transition: visibility 0s, transform $mobile-menu-transition-length ease;
    }
  }
  @include media-breakpoint-down(sm) {
    width: 100%;
    left: -100%;
  }
}

.navigation-content-wrapper {
  padding: 0 sizeToRem(12);
  @include media-breakpoint-down(lg) {
    padding-left: 0;
  }
  @include media-breakpoint-down(md) {
    padding-left: sizeToRem(12);
  }
  @include media-breakpoint-down(sm) {
    padding-left: 0;
  }
}

.navigation-links {
  margin: 0 auto;
  list-style: none;
  display: flex;
  @include media-breakpoint-down(lg) {
    flex-direction: column;
    width: 100%;
    order: 2;
  }
}

.navigation-logo {
  margin-right: 0;
  width: sizeToRem(69);
  height: sizeToRem(60);
  @include media-breakpoint-down(lg) {
    margin-right: auto;
  }
}

.navigation-mobile-dropdown-btn {
  background-color: transparent;
  border: none;
}

.navigation-mobile-header {
  display: none;
  @include media-breakpoint-down(lg) {
    display: block;
    padding: sizeToRem(20) sizeToRem(15);
    margin: 0;
  }
}

.nav-item {
  color: $dark;
  &.nav-dropdown-item {
    background-color: $light;
  }
  @include media-breakpoint-down(lg) {
    font-size: sizeToRem(18);
    line-height: 1.5;
    border-top: 1px solid $light;
    .nav-link {
      padding: sizeToRem(12) sizeToRem(15);
    }
  }
}

.nav-link-btn {
  background-color: transparent;
  border: none;
  &:hover {
    color: $primary;
  }
}

.nav-link-mobile-arrow {
  padding-right: sizeToRem(43) !important;
  position: relative;
  border: none;
  width: 100%;
  background-color: transparent;
  text-align: left;
  &:focus,
  &:hover {
    color: $primary;
  }
  img {
    position: absolute;
    right: sizeToRem(20);
    top: sizeToRem(19);
    transition: 0.3s ease;
  }
  &[aria-expanded='true'] {
    img {
      transform-origin: center;
      transform: rotate(90deg);
    }
  }
}

.nav-link-mobile-dropdown {
  display: flex;
  align-items: center;
  background-color: $light;
  .nav-dropdown-icon {
    margin-right: sizeToRem(16);
  }
}

//NAVIGATION DROP

.navigation-drop {
  position: fixed;
  height: 100%;
  width: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.7);
  transition: width 0s ease $mobile-menu-transition-length, opacity $mobile-menu-transition-length ease;
  cursor: pointer;
  z-index: 997;
  &.active {
    width: 100%;
    opacity: 1;
    transition: width 0s, opacity $mobile-menu-transition-length ease 0.1s;
  }
}

//NAVIGATION OSE BTN

.navigation-btn {
  background-color: $primary;
  color: $white;
  font-weight: 400;
  text-decoration: none;
  padding: sizeToRem(10) sizeToRem(16);
  border-radius: $border-radius;
  transition: 0.2s ease;
  min-width: sizeToRem(130);
  font-size: sizeToRem(16);
  svg {
    fill: $white;
    margin-right: sizeToRem(8);
  }
  &:hover,
  &:focus {
    text-decoration: none;
    color: $white;
    background-color: $primary-darker;
    svg {
      fill: $white;
    }
  }
}

button.navigation-btn-en {
  box-shadow: 0 sizeToRem(2) sizeToRem(10) 0 #0000001a;
  background-color: $white;
  color: $dark;
  border: none;
  border-radius: 50%;
  font-size: sizeToRem(16);
  font-weight: 500;
  padding: sizeToRem(5) sizeToRem(9);

  &:focus {
    box-shadow: 0 sizeToRem(2) sizeToRem(10) 0 #0000001a !important;
  }
}

.navigation-btn-en-link {
  margin-left: sizeToRem(12);
}
