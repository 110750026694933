.form-control {
  height: 3rem;
  padding: 0.375rem 0.55rem;
}
.form-section {
  margin-bottom: sizeToRem(120);
  font-size: sizeToRem(18);
  h4 {
    font-family: $font-fam-headers;
    font-weight: bold;
    margin-bottom: sizeToRem(20);
  }
}

.form-header-container {
  margin-bottom: sizeToRem(56);
  @include media-breakpoint-up(md) {
    margin-bottom: sizeToRem(80);
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: sizeToRem(130);
  }
}

.form-header-wrapper {
  h1 {
    padding: sizeToRem(80) 0;
    @include media-breakpoint-down(sm) {
      padding: sizeToRem(38) 0;
    }
  }
}

.form-header {
  position: relative;
}

.form-header-steps {
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: sizeToRem(8);
  bottom: sizeToRem(-8);
  left: 0;
  padding: 0 sizeToRem(15);
  @include media-breakpoint-up(md) {
    padding: 0 $page-padding-md;
  }
  @include media-breakpoint-up(lg) {
    padding: 0 $page-padding-lg;
  }

  .form-step {
    background-color: rgba($muted-lighter, 0.5);
    padding: 0;
    &:not(:last-child) {
      margin-right: sizeToRem(30);
    }
    &.mleg {
      &.active {
        background-color: $mleg-primary;
      }
    }

    &.ose {
      &.active {
        background-color: $primary;
      }
    }
  }
}

$max-form-steps: 6;
@for $i from 1 to $max-form-steps {
  .form-header-steps .form-step {
    &.steps-#{$i} {
      width: calc(100% / #{$i});
    }
  }
}

.form-section-header {
  margin-bottom: 4rem;
  p {
    margin-bottom: 0;
  }
  h2 {
    margin-bottom: 0;
    &.with-desc {
      margin-bottom: sizeToRem(36);
    }
    //@include media-breakpoint-down(sm) {
    //  font-size: sizeToRem(30);
    //}
  }
}

.form-section-wrapper {
  padding: $page-padding-sm;
  border-radius: $border-radius;
  border: 1px solid transparent;
  @include media-breakpoint-up(md) {
    padding: $page-padding-md;
    border-color: $secondary;
  }
  &.mb-larger {
    margin-bottom: sizeToRem(80);
  }
  &.mb-smaller {
    margin-bottom: sizeToRem(30);
  }
}

.form-navigation-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: sizeToRem(90);

  &.justify-content-end {
    justify-content: flex-end;
  }

  .form-navigation-button {
    min-width: sizeToRem(170);
    @include media-breakpoint-down(xs) {
      min-width: sizeToRem(130);
      padding: sizeToRem(10) sizeToRem(8);
    }
  }
}

.form-school-details-data {
  display: block;
}

.form-success-header-container {
  h2 {
    max-width: sizeToRem(400);
  }
  p {
    max-width: sizeToRem(485);
  }
  &.with-error {
    h2,
    p {
      max-width: sizeToRem(550);
    }
  }
  a {
    color: $primary;
    font-weight: 700;
    font-family: $font-fam-headers;
  }
}

.form-success-icon-container {
  display: flex;
  align-items: center;

  svg {
    width: sizeToRem(240);
    height: sizeToRem(240);
  }
  @include media-breakpoint-up(md) {
    justify-content: center;
  }
  @include media-breakpoint-up(lg) {
    justify-content: flex-start;
  }
}
