.page-section-banner-wrapper {
  position: relative;
}

.page-section-banner-image {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.page-section-banner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(67deg, $secondary 0%, $primary 100%);
  opacity: 0.8;
  z-index: 1;
  pointer-events: none;
}

.page-section-banner-container {
  position: relative;
  z-index: 2;
  padding: sizeToRem(50) sizeToRem(15) sizeToRem(125);
  margin: 0;
  color: white;
  @include media-breakpoint-up(md) {
    margin: 0 auto;
    padding: sizeToRem(77) sizeToRem(15) sizeToRem(115);
  }
}

.page-section-banner-logo {
  width: sizeToRem(110);
  margin-bottom: sizeToRem(30);
}

.page-section-banner-header h2 {
  max-width: sizeToRem(435);
  font-size: sizeToRem(36);
  line-height: sizeToRem(48);
  margin-bottom: sizeToRem(30);
  @include media-breakpoint-up(lg) {
    font-size: sizeToRem(56);
    line-height: sizeToRem(68);
  }
}

.page-section-banner-description {
  font-family: $font-fam-txt;
  max-width: sizeToRem(470);
  font-size: sizeToRem(18);
  line-height: sizeToRem(28);
  margin: 0 0 sizeToRem(60);
}

.page-section-banner-btn-wrapper {
  display: flex;
  justify-content: flex-start;
}

.page-section-banner-cta {
  width: 100%;
  min-width: sizeToRem(192);
  background-color: white;
  border-color: white;
  color: $primary;
  &:hover,
  &:focus {
    color: white;
    background-color: $primary;
    border-color: $primary;
  }
  @include outline($muted_lighter, 1);
  @include media-breakpoint-up(sm) {
    width: initial;
  }
}
