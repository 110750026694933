.formedia {
  a {
    color: $primary;
    font-weight: 600;
    word-break: break-word;
  }
  .formedia-section-one {
    margin-bottom: sizeToRem(60);
  }
  h2 {
    font-size: sizeToRem(36);
    margin-bottom: sizeToRem(30);
    @include media-breakpoint-down(sm) {
      font-size: sizeToRem(24);
    }
  }
  .formedia-download-links {
    margin-bottom: sizeToRem(60);
    list-style: none;
    padding: 0;
  }
}
