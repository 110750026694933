.navigation-dropdown {
  .navigation-dropdown-element {
    position: absolute;
    bottom: 0;
    left: 0;
    height: $navigation-dropdown-height;
    width: 100%;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    transition: $navigation-dropdown-transition-time opacity ease,
      0s transform ease-in-out $navigation-dropdown-transition-time, 0s visibility $navigation-dropdown-transition-time;

    &.show {
      opacity: 1;
      visibility: visible;
      transform: translateY(100%);
      transition: 0s visibility, 0s transform, $navigation-dropdown-transition-time opacity ease;
    }

    &.showFast {
      transition: 0s visibility, 0s transform, 0s opacity ease;
    }
  }
}

//DROPDOWN SWIPER STYLES

.navigation-dropdown-element {
  .swiper-container {
    height: 100%;
  }
  .swiper-slide {
    padding: sizeToRem(20);
  }
  .swiper-button-disabled {
    display: none;
  }
}

//DROPDOWN ITEM STYLES

.nav-dropdown-item {
  height: 100%;
  padding: 0 sizeToRem(10);
  .nav-dropdown-btn {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 1.3;
    border-radius: sizeToRem(10);
    background-color: transparent;
    border: none;
    height: 100%;
    transition: 0.3s ease;
    transform: perspective(0.0625rem) scale(1);
    &:hover {
      background-color: $light;
      transform: perspective(0.0625rem) scale(1.064);
    }
  }
  .nav-dropdown-icon {
    width: 100%;
    height: sizeToRem(30);
    margin-top: sizeToRem(18);
    margin-bottom: sizeToRem(12);
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.nav-dropdown-icon {
  display: inline-block;
  width: sizeToRem(20);
  height: sizeToRem(20);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  &.media-icon {
    background-image: url('../images/icons/dropdownIcons/media-icon.svg');
  }
  &.computer-icon {
    background-image: url('../images/icons/dropdownIcons/computer-icon.svg');
  }
  &.eu-icon {
    background-image: url('../images/icons/dropdownIcons/eu-icon.svg');
  }
  &.pl-icon {
    background-image: url('../images/icons/dropdownIcons/pl-icon.svg');
  }
  &.faq-icon {
    background-image: url('../images/icons/dropdownIcons/faq-icon.svg');
  }
  &.email-icon {
    background-image: url('../images/icons/dropdownIcons/email-icon.svg');
  }
  &.file-icon {
    background-image: url('../images/icons/dropdownIcons/file-icon.svg');
  }
  &.globe-icon {
    background-image: url('../images/icons/dropdownIcons/globe-icon.svg');
  }
  &.people-icon {
    background-image: url('../images/icons/dropdownIcons/people-icon.svg');
  }
  &.profile-icon {
    background-image: url('../images/icons/dropdownIcons/profile-icon.svg');
  }
  &.school-icon {
    background-image: url('../images/icons/dropdownIcons/school-icon.svg');
  }
  &.star-icon {
    background-image: url('../images/icons/dropdownIcons/star-icon.svg');
  }
  &.students-icon {
    background-image: url('../images/icons/dropdownIcons/students-icon.svg');
  }
  &.cup-icon {
    background-image: url('../images/icons/dropdownIcons/cup-icon.svg');
  }
  &.education-icon {
    background-image: url('../images/icons/dropdownIcons/education-icon.svg');
  }
  &.mapp-icon {
    background-image: url('../images/icons/dropdownIcons/mapp-icon.svg');
  }
  &.menergy-icon {
    background-image: url('../images/icons/dropdownIcons/menergy-icon.svg');
  }
  &.osehero-icon {
    background-image: url('../images/icons/dropdownIcons/osehero-icon.svg');
  }
  &.osekongres-icon {
    background-image: url('../images/icons/dropdownIcons/osekongres-icon.svg');
  }
  &.campaign-icon {
    background-image: url('../images/icons/dropdownIcons/campaign-icon.svg');
  }
  &.pkc-icon {
    background-image: url('../images/icons/dropdownIcons/pkc-icon.svg');
  }
  &.bws-icon {
    background-image: url('../images/icons/dropdownIcons/bws-icon.svg');
  }
}
