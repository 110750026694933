.home-section-hero {
  background-color: $light;
  padding-bottom: sizeToRem(80);
  z-index: 2;
  position: relative;

  .home-section-hero-presentation-container {
    position: relative;
  }

  .home-section-hero-presentation-background-container {
    height: 100vh;
    width: 100%;
    clip-path: circle(100% at center center);
    z-index: 2;
    position: relative;
    @include media-breakpoint-up(md) {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .home-section-hero-presentation-background {
    background-image: url('../images/home-osehero-bg.png');

    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100%;
    height: 100%;

    @include media-breakpoint-down(sm) {
      background-position: 97% 50%;
    }

    @include media-breakpoint-down(lg) {
      background-position: 95% 50%;
    }
  }

  .home-section-hero-presentation-header {
    max-width: initial;
    width: 100%;
    margin: 0;
    @include media-breakpoint-up(md) {
      max-width: sizeToRem(567);
      margin: 0 auto 0 0;
      p {
        max-width: sizeToRem(282);
      }
      h2 {
        max-width: sizeToRem(270);
      }
    }
    @include media-breakpoint-up(lg) {
      p,
      h2 {
        max-width: sizeToRem(500);
      }
    }
    @include media-breakpoint-up(xl) {
      p,
      h2 {
        max-width: initial;
      }
    }
  }
  .home-section-hero-presentation-header {
    p {
      margin-bottom: sizeToRem(30);
    }
    @include media-breakpoint-up(md) {
      transform: translate3d(0, 100vh, 0);
      opacity: 0;
    }
  }

  .home-section-hero-presentation-header .home-section-btn-wrapper {
    flex-direction: column;
    .secondary {
      order: 2;
      width: 100%;
      margin: 0 auto;
    }
    .link {
      order: 1;
      max-width: sizeToRem(192);
      margin-bottom: sizeToRem(32);
    }
    @include media-breakpoint-up(md) {
      flex-direction: row;
      .secondary {
        order: 1;
        width: sizeToRem(240);
        margin: 0 sizeToRem(40) 0 0;
      }
      .link {
        order: 2;
        margin-bottom: 0;
        max-width: initial;
      }
    }
  }

  .home-section-hero-content {
    position: relative;
    display: flex;
    margin-top: sizeToRem(-40);
    z-index: 1;
    @include media-breakpoint-up(md) {
      height: 100vh;
      margin-top: 0;
      align-items: center;
      justify-content: flex-end;
    }
  }

  .home-section-hero-top-pointer,
  .home-section-hero-bottom-pointer {
    position: absolute;
    svg {
      width: 100%;
      max-width: sizeToRem(300);
      path {
        opacity: 0;
      }
    }
  }

  .home-section-hero-top-pointer {
    top: sizeToRem(-80);
    left: 40%;
    width: 55%;
    transform: rotate(15deg);
  }

  .home-section-hero-bottom-pointer {
    bottom: sizeToRem(56);
    right: sizeToRem(160);
    transform: rotate(270deg);
  }

  .home-section-hero-image {
    justify-content: center;
    margin-bottom: sizeToRem(68);

    @include media-breakpoint-up(md) {
      margin: 0;
      position: sticky;
      top: sizeToRem(72);
    }
    @include media-breakpoint-up(xl) {
      top: 0;
    }
  }

  .home-section-hero-image {
    position: relative;
    display: flex;
    img {
      z-index: 2;
      position: relative;
      width: 100%;
      height: 100%;
      max-width: sizeToRem(300);
      @include media-breakpoint-up(lg) {
        max-width: initial;
      }
    }
  }

  .home-section-hero-image {
    justify-content: center;
    margin-bottom: sizeToRem(68);

    @include media-breakpoint-up(md) {
      margin: 0;
      position: sticky;
      top: sizeToRem(72);
    }
  }

  .home-section-hero-advantages {
    @include media-breakpoint-down(sm) {
      margin-top: sizeToRem(120);
    }
  }

  .home-section-hero-advantages-pros-wrapper {
    margin-top: sizeToRem(60);
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(md) {
      margin-top: sizeToRem(64);
    }
  }
  .home-section-hero-advantages-pros-header {
    margin-bottom: sizeToRem(66);
    font-weight: 700;
    font-size: sizeToRem(36);
  }
  .home-section-hero-advantages-pros {
    display: flex;
    h3 {
      font-size: sizeToRem(18);
      line-height: sizeToRem(30);
      font-weight: 400;
      margin: 0;
    }
  }

  .home-section-hero-advantages-pros-icon {
    width: sizeToRem(70);
    height: sizeToRem(70);
    min-width: sizeToRem(70);
    margin: 0 sizeToRem(32) 0 0;
  }

  .home-section-hero-advantages-pros-icon.hide {
    width: sizeToRem(70);
    height: sizeToRem(70);
    min-width: sizeToRem(70);
    margin: 0 sizeToRem(32) 0 0;
    color: transparent;
    background-color: transparent;
  }

  .home-section-hero-advantages-props-hr {
    border: sizeToRem(1) solid #2737f51a;
    margin-top: sizeToRem(35);
    margin-bottom: sizeToRem(35);
    position: relative;
  }

  .home-section-hero-advantages-content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
