.form-ose {
  .form-header-wrapper {
    background-color: $light;
  }
  .form-section-wrapper {
    background-color: $light;
  }
}

.register-ose-personel-wrapper {
  border-radius: $border_radius;
  margin-bottom: sizeToRem(60);

  .register-ose-person-wrapper {
    &:first-child {
      border-top: 1px solid $secondary;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;

      .register-ose-person-header {
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
      }
    }
  }

  .register-ose-person-wrapper {
    &:last-child {
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;

      .register-ose-person-header {
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: sizeToRem(100);
  }
}

.register-ose-person-header {
  width: 100%;
  background-color: $light;
  padding: sizeToRem(16) sizeToRem(16) sizeToRem(16) sizeToRem(20);
  min-height: sizeToRem(80);
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;

  &:hover,
  &:focus {
    background-color: $light-hover;
  }

  @include media-breakpoint-up(lg) {
    padding: sizeToRem(20) sizeToRem(28);
  }

  &.disabled {
    background-color: $muted;
    cursor: default;

    h4 {
      color: $light;
    }
  }

  h4 {
    margin-bottom: 0;
    margin-left: sizeToRem(20);
    text-align: left;
    flex-grow: 1;
  }
}

.register-ose-person-chevron {
  width: sizeToRem(30);
  transition: transform 0.7s ease;
  flex-shrink: 0;

  &.is-open {
    transform: rotate(180deg);
  }

  &.disabled {
    display: none;
  }
}

.register-ose-person-icon {
  width: sizeToRem(40);
  height: sizeToRem(40);

  &.disabled {
    filter: brightness(0) invert(1);
  }
}

.register-ose-person-body {
  padding: sizeToRem(20);

  @include media-breakpoint-up(lg) {
    padding: sizeToRem(20) sizeToRem(28);
  }
}

.form-section-wrapper.register-ose-add-transfer {
  padding: 0;
  background-color: #fff;
  .register-ose-add-transfer-head,
  .register-ose-add-transfer-body {
    padding: $page-padding-sm;
    @include media-breakpoint-up(md) {
      padding: $page-padding-md;
    }
  }
  .register-ose-add-transfer-head {
    background-color: $light;
    border-radius: $border-radius;
  }

  .form-group {
    margin-bottom: 0.75rem;
  }
}

.register-ose-person-wrapper {
  border-color: $dark;
  overflow: visible !important;
  border-top: none;
  border-radius: 0;
}
