.contact {
  .form-section-wrapper {
    background-color: $light;
  }
}

.contact-info {
  h3 {
    margin-bottom: sizeToRem(32);

    @include media-breakpoint-down(sm) {
      font-size: sizeToRem(26);
      margin-bottom: sizeToRem(10);
    }
  }

  h4 {
    font-family: $font_fam_headers;
    color: $primary;
    font-size: sizeToRem(26);
    margin-bottom: sizeToRem(40);
  }

  h5 {
    font-size: sizeToRem(18);
    font-family: $font_fam_headers;
  }

  p {
    font-size: sizeToRem(18);
    line-height: 1.35;
    margin-bottom: 0;
  }

  a {
    display: block;
    color: $primary;
    font-weight: bold;
    line-height: 1.55;
    text-decoration: none;
    margin-bottom: sizeToRem(24);

    &:hover {
      color: $primary;
      text-decoration: underline;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 0;
    }
  }
}

// FORM

.contact-form-title {
  margin-bottom: sizeToRem(50);
  @include media-breakpoint-down(sm) {
    font-size: sizeToRem(26);
    margin: sizeToRem(60) 0 sizeToRem(20);
  }
}

.contact-form {
  border: 1px solid $secondary;
  border-radius: sizeToRem(5);
  padding: $page-padding-md;
  margin-bottom: sizeToRem(32);

  @include media-breakpoint-down(sm) {
    padding: sizeToRem(18) sizeToRem(8);
  }
}

.contact-form-btn {
  width: sizeToRem(192);
  @include media-breakpoint-down(sm) {
    display: block;
    margin: 0 auto sizeToRem(24) auto;
  }
}

.contact-form-consent-accordion {
  .card-header {
    background-color: $primary-opacity;
  }
}

.contact-form-required-info {
  font-size: sizeToRem(18);
}
