//SECOND SECTION

.itszkolarecommend-section-two {
  padding: sizeToRem(150) 0;
  @include media-breakpoint-down(sm) {
    padding: sizeToRem(80) 0;
  }
  .itszkolarecommend-section-two-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include media-breakpoint-down(sm) {
      align-items: center;
    }
    .itszkolarecommend-section-two-image {
      img {
        max-width: 100%;
      }
    }
    .itszkolarecommend-section-two-content {
      h2 {
        margin-bottom: sizeToRem(30);
        @include media-breakpoint-down(md) {
          font-size: sizeToRem(36);
        }
      }
    }
  }
}

//THIRD SECTION

.itszkolarecommend-section-three {
  overflow: hidden;
  padding-bottom: sizeToRem(150);
  @include media-breakpoint-down(sm) {
    padding-bottom: sizeToRem(80);
  }
  .itszkolarecommend-section-three-col {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    @include media-breakpoint-down(sm) {
      align-items: center;
    }
    .itszkolarecommend-section-three-image {
      width: sizeToRem(1300);
      padding-top: sizeToRem(100);
      @include media-breakpoint-down(lg) {
        width: sizeToRem(1000);
      }
      @include media-breakpoint-down(md) {
        padding-top: sizeToRem(240);
        width: sizeToRem(600);
      }
      @include media-breakpoint-down(sm) {
        padding-top: sizeToRem(0);
        width: 100%;
        img {
          max-width: 100%;
        }
      }
    }
    .itszkolarecommend-section-three-content {
      h2 {
        margin-bottom: sizeToRem(30);
        @include media-breakpoint-down(md) {
          font-size: sizeToRem(36);
          margin-top: sizeToRem(50);
        }
      }
      .itszkolarecommend-section-three-desc {
        margin-bottom: sizeToRem(50);
      }
    }
  }
}

// FORTH SECTION

.itszkolarecommend-section-four {
  position: relative;
  height: sizeToRem(559);
  padding: sizeToRem(60) 0 sizeToRem(120);
}
.itszkolarecommend-section-four-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1;
  img {
    object-fit: cover;
    height: 100%;
  }
}
.itszkolarecommend-section-four-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 2;
  background-color: rgba($secondary, 0.7);
}
.itszkolarecommend-section-four-title {
  margin-bottom: sizeToRem(40);
  @include media-breakpoint-up(md) {
    margin: 0;
  }
}
.itszkolarecommend-section-four-content-wrapper {
  position: relative;
  z-index: 3;
  color: white;
}
.itszkolarecommend-section-four-stat {
  align-self: center;
  h3 {
    font-size: sizeToRem(18);
    font-weight: 500;
    margin-bottom: sizeToRem(16);
  }
  p {
    font-size: sizeToRem(56);
    line-height: sizeToRem(73);
    font-family: $font-fam-headers;
    font-weight: 700;
    margin: 0;
  }
  @include media-breakpoint-up(sm) {
    p {
      font-size: sizeToRem(80);
      line-height: sizeToRem(97);
    }
  }
}
.itszkolarecommend-section-four-stats-row {
  @include media-breakpoint-up(md) {
    justify-content: space-between;
  }
}
.itszkolarecommend-section-four-stats-col {
  display: flex;
  &:nth-of-type(2) {
    @media screen and (min-width: 360px) {
      margin-left: sizeToRem(34);
    }
    @include media-breakpoint-up(sm) {
      margin-left: sizeToRem(140);
    }
    @include media-breakpoint-up(md) {
      margin-left: 0;
    }
  }
}
.itszkolarecommend-section-four-circle-wrapper {
  display: flex;
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: sizeToRem(320);
  height: sizeToRem(320);
  margin-top: sizeToRem(48);
  @media screen and (min-width: 360px) {
    max-width: sizeToRem(360);
    height: sizeToRem(360);
  }
  @include media-breakpoint-up(md) {
    margin: 0;
  }
  @include media-breakpoint-up(lg) {
    min-width: initial;
    max-width: sizeToRem(430);
    height: sizeToRem(430);
  }
}
.itszkolarecommend-section-four-circle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(122deg, $its-primary 0%, $its-secondary 100%);
  position: absolute;
  padding: sizeToRem(15);
  top: sizeToRem(20);
  right: 0;
  h3 {
    text-align: center;
  }
  p {
    font-size: sizeToRem(72);
    line-height: sizeToRem(93);
  }
  @include media-breakpoint-up(sm) {
    p {
      font-size: sizeToRem(96);
      line-height: sizeToRem(117);
    }
  }
  @include media-breakpoint-up(md) {
    top: sizeToRem(100);
  }
  @include media-breakpoint-up(lg) {
    right: sizeToRem(15);
    top: sizeToRem(40);
  }
}

// FIFTH SECTION

.itszkolarecommend-section-five {
  padding: sizeToRem(240) 0 sizeToRem(150);
  @include media-breakpoint-up(md) {
    padding: sizeToRem(150) 0;
  }
}
.itszkolarecommend-section-five-pretitle {
  text-transform: uppercase;
  color: $primary;
  font-family: $font-fam-headers;
  font-size: sizeToRem(18);
  font-weight: 500;
  margin-bottom: sizeToRem(12);
}
.itszkolarecommend-section-five-title {
  max-width: sizeToRem(473);
  margin-bottom: sizeToRem(30);
}
.itszkolarecommend-section-five-description {
  max-width: sizeToRem(496);
  margin-bottom: sizeToRem(112);
}
.itszkolarecommend-section-five-subtitle {
  max-width: sizeToRem(184);
  font-size: sizeToRem(26);
  margin-bottom: sizeToRem(112);
}
.itszkolarecommend-section-five-category-tile-wrapper {
  margin-bottom: sizeToRem(80);
  &:last-of-type {
    margin: 0;
  }
  @include media-breakpoint-up(lg) {
    margin: 0;
  }
}
.itszkolarecommend-section-five-category-tile {
  img {
    max-width: sizeToRem(80);
    margin-bottom: sizeToRem(16);
  }
  h4 {
    max-width: sizeToRem(226);
  }
  p {
    margin: 0;
    max-width: sizeToRem(380);
  }
}
